import { CommonuxTag } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface TagProps extends JSX.CommonuxTag {
  className?: string;
  id?: string;
  children?: React.ReactNode;
  deleteButton?: false;
}

const Tag = (props: PropsWithChildren<TagProps>) => {
  return <CommonuxTag {...props}>{props?.children}</CommonuxTag>;
};

export default Tag;
