import { CommonuxTextArea } from '@abb-motion-ux/commonux-web-components-react';
import FileAttachment from './FileAttachment';
import { FeedbackInput } from '../models/FeedbackModel';
import { useEffect, useState } from 'react';
import { CommonuxTextAreaCustomEvent } from '@abb-motion-ux/commonux-web-components';
interface ISuggestionFeedback {
  setFeedback: React.Dispatch<React.SetStateAction<FeedbackInput | undefined>>;
}

const SuggestionFeedback = ({ setFeedback }: ISuggestionFeedback) => {
  const [suggestions, setSuggestions] = useState<string>('');
  const [attachments, setAttachments] = useState<File[]>([]);

  useEffect(() => {
    setFeedback(undefined);
  }, []);

  const handleAttachmentsChange = (newAttachments: File[]) => {
    setAttachments(newAttachments);
    setFeedback({
      suggestions: suggestions,
      attachments: newAttachments,
    });
  };

  const handleChallengeChange = (event: CommonuxTextAreaCustomEvent<any>) => {
    const newComments = event.target.value;
    setSuggestions(newComments);
    setFeedback({
      suggestions: newComments,
      attachments,
    });
  };

  return (
    <div>
      <div className="feedback-modal-text" slot="content">
        <div className="feedback-modal-question-section">
          <div className="feedback-question-container">
            <div className="">
              <CommonuxTextArea
                labelText="Do you have any suggestions for new features or improvements?"
                placeholder="Leave us your comments"
                minHeight="160px"
                onInputInput={handleChallengeChange}
                inputLimit={1000}
              />
            </div>
          </div>
          <FileAttachment setAttachments={handleAttachmentsChange} />
          <div className="attachment-text-container">
            <div>
              <b>Note:</b> We might contact you in the future to gather further
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionFeedback;
