import './../styles/layout.scss';
import { CommonuxTextArea } from '@abb-motion-ux/commonux-web-components-react';
import FileAttachment from './FileAttachment';
import { FeedbackInput } from '../models/FeedbackModel';
import { CommonuxTextAreaCustomEvent } from '@abb-motion-ux/commonux-web-components';
import { useEffect, useState } from 'react';

interface IReportIssueFeedback {
  setFeedback: React.Dispatch<React.SetStateAction<FeedbackInput | undefined>>;
}

const ReportIssueFeedback = ({ setFeedback }: IReportIssueFeedback) => {
  const [challenges, setChallenges] = useState<string>('');
  const [problems, setProblems] = useState<string>('');
  const [attachments, setAttachments] = useState<File[]>([]);

  useEffect(() => {
    setFeedback(undefined);
  }, []);

  const handleAttachmentsChange = (newAttachments: File[]) => {
    setAttachments(newAttachments);
    updateFeedback(challenges, problems, newAttachments);
  };

  const updateFeedback = (
    challenges: string,
    problems: string,
    attachments: File[],
  ) => {
    setFeedback({
      challenges: challenges,
      detailDescription: problems,
      attachments,
    });
  };
  const handleChallengeChange = (event: CommonuxTextAreaCustomEvent<any>) => {
    const newComments = event.target.value;
    setChallenges(newComments);
    updateFeedback(newComments, problems, attachments);
  };

  const handleProblemChange = (event: CommonuxTextAreaCustomEvent<any>) => {
    const newComments = event.target.value;
    setProblems(newComments);
    updateFeedback(challenges, newComments, attachments);
  };

  return (
    <div>
      <div className="feedback-modal-text" slot="content">
        <div className="feedback-modal-question-section">
          <div className="feedback-question-container">
            <div className="">
              <CommonuxTextArea
                labelText="What challenges have you faced while using the tool?"
                placeholder="Leave us your comments"
                onInputInput={handleChallengeChange}
                inputLimit={500}
              />
            </div>
          </div>
          <div className="feedback-question-container">
            <div className="">
              <CommonuxTextArea
                labelText="Describe the problem in detail"
                placeholder="Leave us your comments"
                onInputInput={handleProblemChange}
                inputLimit={500}
              />
            </div>
          </div>
          <FileAttachment setAttachments={handleAttachmentsChange} />
          <div className="attachment-text-container">
            <div>
              <b>Note:</b> We might contact you in the future to gather further
              details or resolve the issue.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportIssueFeedback;
