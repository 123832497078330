import { CommonuxMenu } from '@abb-motion-ux/commonux-web-components-react';

interface CopyPlusCodeDropDownsProps {
  copyPlusCodeRef: any;
  children: React.ReactNode;
  menuheader: string;
  mouseLeaveHandler: () => {};
}

const CopyPlusCodeDropDowns = (props: CopyPlusCodeDropDownsProps) => {
  return (
    <CommonuxMenu
      ref={props.copyPlusCodeRef}
      closeMenuOnItemClicked
      closeOnBackdropClick
      size="large"
      data-testid="copy--delete-plus-code-dropdown"
      heading={props.menuheader}
      className="copy-delete-plus-code-dropdown"
      onMouseLeave={props.mouseLeaveHandler}
    >
      {props.children}
    </CommonuxMenu>
  );
};

export default CopyPlusCodeDropDowns;
