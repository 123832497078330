import { useDispatch, useSelector } from 'react-redux';
import { CurrentProjectDetails, ProjectType } from '../models/homepageModel';
import {
  createProject,
  setDeleteModalOpen,
} from '../store/actions/homeActions';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import Button from '../../../components/Form/Button';
import Icon from '../../../components/Icon';
import { useTranslate } from '../../../i18translate/Hooks';
import { useState } from 'react';
import { I18 } from '../../../languages/I18';
import CommonLoader from '../../../components/Loader';
import RecentProjectMenu from './RecentProjectMenu';
import { useSession } from '../../../utils/Auth';
import { useDeleteProjectMutation } from '../../Workspace/Dimensioning/services/APIDimensioningService';
import notificationManager from '../../../utils/NotificationManager';
import ProjectsGridCard from '../../../components/ProjectsGridCard';
import { ProjectData } from '../models/homepageModel';
import { CommonuxTooltip } from '@abb-motion-ux/commonux-web-components-react';
import { RootState } from '../../../store/rootReducer';
import Cookies from 'js-cookie';
import DeleteProjectModal from './DeleteProjectModal';

export interface RecentProjectsProps {
  isLoading: boolean;
  handleOnNewProjectClick?: () => void;
  projectData: ProjectData;
  onSuccessOfDeleteProject: () => void;
  isButtonDisabled: boolean;
}
export const convertDateTime = (dateTime: string) => {
  if (dateTime[dateTime.length - 1] !== 'Z') {
    const [dateString, timeString] = dateTime.split('T');
    const [day, month, year] = dateString.split('-');
    const [hour, minute, second] = timeString.split(':');

    return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}Z`);
  } else return new Date(dateTime);
};

const RecentProjects = ({
  isLoading,
  handleOnNewProjectClick,
  projectData,
  onSuccessOfDeleteProject,
  isButtonDisabled,
}: RecentProjectsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const userSession = useSession();
  const [deleteProject] = useDeleteProjectMutation();
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);

  const projectTypeSelected = useSelector(
    (state: RootState) => state.home.projectTypeSelected,
  );
  const deleteProjectId = useSelector(
    (state: RootState) => state.home.deleteProjectId,
  );

  const deleteModalOpen = useSelector(
    (state: RootState) => state.home.deleteModalOpen,
  );

  const handlePlcRedirect = () => {
    if (process.env.REACT_APP_PLC_CKNAME) {
      const date = new Date();
      Cookies.set(process.env.REACT_APP_PLC_CKNAME, date.getTime().toString(), {
        expires: 1,
        path: '/',
        domain: '.goselect.motion.abb.com',
      });
      Cookies.set('isUserLoggedIn', `${Boolean(userSession?.session?.user)}`, {
        expires: 1,
        path: '/',
        domain: '.goselect.motion.abb.com',
      });
    }
  };

  const handleProjectClick = (
    projectData: CurrentProjectDetails,
    workspace?: number,
  ) => {
    sessionStorage.setItem('id', projectData.id);
    sessionStorage.setItem('name', projectData.name);
    if (projectData?.projectType === ProjectType.PLCHMI) {
      if (workspace === 2 || workspace === undefined) {
        handlePlcRedirect();
        window.location.href = process.env.REACT_APP_PLC_REDIRECT
          ? `${process.env.REACT_APP_PLC_REDIRECT}?id=${projectData?.id}`
          : '';
      }

      if (workspace === 1) {
        navigate('/workspace');
      }
    } else {
      if (workspace === undefined) {
        dispatch(
          createProject({
            projectId: projectData.id,
            projectName: projectData.name,
            projectType: projectTypeSelected,
          }),
        );
        navigate('/workspace');
      } else if (workspace === 1) {
        navigate('/workspace');
      } else if (workspace === 2) {
        handlePlcRedirect();
        window.location.href = process.env.REACT_APP_PLC_REDIRECT
          ? `${process.env.REACT_APP_PLC_REDIRECT}?id=${projectData?.id}`
          : '';
      }
    }
  };

  const handleViewAllClick = () => {
    navigate('/projects');
  };

  const order = (a: any, b: any) => {
    return a.modifiedDate > b.modifiedDate
      ? -1
      : a.modifiedDate < b.modifiedDate
        ? 1
        : 0;
  };

  const allProjects = projectData && projectData.projects?.slice().sort(order);

  //delete of project

  const deleteSelectedProject = async (project: CurrentProjectDetails) => {
    if (project.id) {
      setDeleteProjectLoading(true);
      localStorage.setItem('projectname', project.name);
      await deleteProject({
        id: project.id,
        userToken: userSession.session?.user.access_token,
      })
        .then((deleteApiResponse) => {
          setDeleteProjectLoading(false);
          if ('data' in deleteApiResponse) {
            notificationManager.info(
              `${project.name} project has been deleted`,
            );
            onSuccessOfDeleteProject();
          } else {
            notificationManager.error('Unable to delete the project');
          }
          dispatch(setDeleteModalOpen(false));
        })
        .catch((deleteApiError) => {
          setDeleteProjectLoading(false);
          notificationManager.error('Unable to delete the project');
          dispatch(setDeleteModalOpen(false));
        });
    }
  };

  const handleNewProjectClick = () => {
    handleOnNewProjectClick?.();
  };

  return (
    <div className="page-projects-container">
      <div className="projects-container" data-rf="dashboard-header-desc">
        {!isLoading && projectData?.projects?.length > 0 && (
          <span className="recent-header" data-rf="dashboard-header-title">
            Recent Projects
          </span>
        )}

        <div className="projects-list-container">
          {isLoading ? (
            <CommonLoader
              size="xlarge"
              indicatorType={'circular'}
              progressType={'indeterminate'}
              progressValue={50}
              type={'primary-black'}
            />
          ) : allProjects?.length > 0 ? (
            allProjects?.map((project: CurrentProjectDetails, idx: number) => {
              if (idx < 4) {
                return (
                  <ProjectsGridCard
                    createdDate={project?.createdDate}
                    idx={idx}
                    modifiedDate={project?.modifiedDate}
                    projectId={project.id}
                    projectName={project.name}
                    key={idx}
                    openProject={() => handleProjectClick(project)}
                    data-testid="project-grid-container"
                  >
                    <RecentProjectMenu
                      openProjectButtonHandler={(workspace) =>
                        handleProjectClick(project, workspace)
                      }
                      projectId={project.id}
                      deleteProjectLoading={deleteProjectLoading}
                      projectType={project?.projectType}
                    />
                  </ProjectsGridCard>
                );
              } else {
                return null;
              }
            })
          ) : (
            <div className="recent-project-desc-block">
              <span className="block-header" data-rf="dashboard-header-title">
                {t(I18.recent_project_block_header)}
              </span>
              <span className="block-desc" data-rf="dashboard-header-desc">
                {t(I18.recent_project_block_description)}
              </span>
            </div>
          )}
          {!isLoading && projectData?.projects?.length > 4 && (
            <Card
              className="all-projects-card"
              size="default"
              type="default"
              onCardClick={handleViewAllClick}
              data-rf="all-projects-card"
            >
              <div className="view-all-projects" id="header" slot="contents">
                View all projects
              </div>
            </Card>
          )}
        </div>
      </div>
      <div>
        <CommonuxTooltip
          alignment="start"
          arrowPosition="center"
          placement="right"
          size="small"
          triggerEvent="hover"
          removeTooltip={!isButtonDisabled || isLoading}
        >
          <Button
            type="primary-red"
            size="xlarge"
            onClick={handleNewProjectClick}
            data-rf="new-project-button"
            data-testid="new-project-button"
            disabled={isButtonDisabled || isLoading}
            slot="tooltip-parent"
          >
            <Icon slot="icon" size="small" name="plus" theme="light" />
            {t(I18.home_dashboard_new_project_button)}
          </Button>
          <div className="project-tooltip-container" slot="content-section">
            <div className="project-tooltip-header">Storage limit exceeds</div>
            <div className="project-tooltip-label">
              Please delete an existing
              <br />
              project to create a new one.
            </div>
          </div>
        </CommonuxTooltip>
      </div>
      <DeleteProjectModal
        closeDeleteModal={() => dispatch(setDeleteModalOpen(false))}
        showModal={deleteModalOpen}
        projectName={
          allProjects?.filter((project) => project.id === deleteProjectId)?.[0]
            ?.name
        }
        deleteButtonHandler={() =>
          deleteSelectedProject(
            allProjects?.filter(
              (project) => project.id === deleteProjectId,
            )?.[0],
          )
        }
        deleteProjectLoading={deleteProjectLoading}
      />
    </div>
  );
};

export default RecentProjects;
