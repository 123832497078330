import { ApexOptions } from 'apexcharts';
import Button from '../../../../components/Form/Button';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import { generateKey } from '../../../../utils/GeneralUtils';
import {
  AddDutyCycleModalDriveProps,
  DutyCycleDriveLoadCalcData,
  LoadDataDriveSelection,
} from '../models/DutyCycleModels';
import { useEffect, useRef, useState } from 'react';
import ReactApexCharts from 'react-apexcharts';
import { calculateCoordinates } from '../Utils/DutyCycleCalculateCoordinates';
import { dutyCyclegraphOptions } from '../../../../utils/GraphDefaultData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { FWP, c30kPerPI } from '../../../../utils/Constants';
import {
  getMaxPowerItem,
  getNCurrent,
} from '../models/DutyCycleCalculationsUtil';
import { saveDutyCycleValuesDrive } from '../../store/WorkspaceReducer';

const DutyCycleModalDrive = ({
  modalOpen,
  onClose,
  onApplyClick,
  baseLoadData,
}: AddDutyCycleModalDriveProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [currentValues, setCurrentValues] = useState<number[]>([]);
  const [timeValues, setTimeValues] = useState<number[]>([]);

  const [seriesData, setSeriesData] = useState([[0, 0]]);
  const [hasMultipleOverload, setHasMultipleOverload] = useState(false);
  const [rows, setRows] = useState<LoadDataDriveSelection[]>([]);
  const [rms, setRms] = useState(0);
  const dutyCycleDriveCalcRef = useRef<DutyCycleDriveLoadCalcData>({
    imc: 0,
    imCalc: 0,
    imf: 0,
    isdc: 0,
    isq: 0,
    isqc: 0,
    root1: 0,
    root2: 0,
    testPoints: 0,
    testSpeed: 0,
    x: 0,
  });

  const dutyCycleMotorValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dutyCycleMotorValues,
  );

  const dutyCycleDriveValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dutyCycleDriveValues,
  );
  const hasMotorDutyCycleApplyClicked = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.hasMotorDutyCycleApplyClicked,
  );
  const hasDimensionDataFetched = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.hasDimensionDataFetched,
  );
  const dutyCycleMotorRmsValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.rmsValues,
  );

  const [hasGraphDataChanged, setHasGraphDataChanged] =
    useState(!!dutyCycleMotorValues);
  const dimensionMotorResult = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.selectMotorResult,
  );

  const motorLoadData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.motorLoadData,
  );

  useEffect(() => {
    const currentData = [];
    currentData[0] = baseLoadData?.[0]?.current;
    setCurrentValues(currentData);

    const timeData = [];
    timeData[0] = baseLoadData?.[0]?.time;
    timeData[1] = baseLoadData?.[1]?.time;
    setTimeValues(timeData);

    setSeriesData([
      [0, baseLoadData?.[0]?.current],
      [baseLoadData?.[0]?.time, baseLoadData?.[0]?.current],
      [baseLoadData?.[0]?.time, baseLoadData?.[1]?.current],
      [
        baseLoadData?.[0]?.time + baseLoadData?.[1]?.time,
        baseLoadData?.[1]?.current,
      ],
      [
        baseLoadData?.[0]?.time + baseLoadData?.[1]?.time,
        baseLoadData?.[0]?.current,
      ],
    ]);

    if (
      dimensionMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
        ?.motorUnit?.results
    ) {
      let fwpSpeed =
        parseFloat(
          dimensionMotorResult?.dimension?.dimensionResult
            ?.supplyDriveLoads?.[0]?.motorUnit?.results?.[8]?.value, //Speed  from motor result
        ) * FWP;
      let resultTorque = parseFloat(
        dimensionMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.motorUnit?.results?.[11]?.value ?? '0',
      );
      let tMax = parseFloat(
        dimensionMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.motorUnit?.results?.[12]?.value ?? '0',
      );
      let cosfi = parseFloat(
        dimensionMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.motorUnit?.results?.[13]?.value ?? '0',
      );
      let sinfi = Math.sqrt(1 - Math.pow(cosfi, 2));
      let resultCurrent = parseFloat(
        dimensionMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.motorUnit?.results?.[10]?.value ?? '0', //current value from motor result
      );
      let nCurrent = getNCurrent(
        motorLoadData,
        dutyCycleMotorRmsValues,
        dimensionMotorResult,
      );

      if (
        dimensionMotorResult?.dimension?.dimensionInput?.selectionInputs?.[0]
          ?.motorType === '1'
      ) {
        let resultFrequency = parseFloat(
          dimensionMotorResult?.dimension?.dimensionResult
            ?.supplyDriveLoads?.[0]?.motorUnit?.results?.[5]?.value ??
            baseLoadData?.[0]?.minF,
        );
        let resultSpeed = parseFloat(
          dimensionMotorResult?.dimension?.dimensionResult
            ?.supplyDriveLoads?.[0]?.motorUnit?.results?.[8]?.value ??
            motorLoadData?.nMin,
        );
        let rowData: LoadDataDriveSelection[] = [];
        const currentData = [...currentValues];
        const timeData = [...timeValues];
        if (dutyCycleMotorValues)
          dutyCycleMotorValues?.forEach((row, index) => {
            let doLoop = true;
            let result = getMaxPowerItem(
              row,
              nCurrent,
              doLoop,
              motorLoadData,
              dutyCycleMotorRmsValues,
            );
            dutyCycleDriveCalcRef.current.testPoints = result.result;
            nCurrent = result.nCurrent;
            let pBase =
              parseFloat(motorLoadData.power) *
              (parseFloat(dutyCycleMotorRmsValues.RMS10) / 100);
            let tBase = (c30kPerPI * pBase) / parseFloat(motorLoadData.nBase);
            let tLoad = tBase;
            let tLoadMax =
              (tLoad * parseFloat(result.motorloadData.olBaseInput)) / 100;

            for (
              let i = 0;
              i <= dutyCycleDriveCalcRef.current.testPoints;
              i++
            ) {
              if (nCurrent[i] >= row.minSpeed && nCurrent[i] <= row.maxSpeed) {
                dutyCycleDriveCalcRef.current.testSpeed = nCurrent[i];
                if (dutyCycleDriveCalcRef.current.testSpeed < fwpSpeed) {
                  dutyCycleDriveCalcRef.current.x = tLoadMax / resultTorque;
                  dutyCycleDriveCalcRef.current.root1 = Math.pow(tMax, 2) - 1;
                  dutyCycleDriveCalcRef.current.root2 =
                    Math.pow(tMax, 2) -
                    Math.pow(dutyCycleDriveCalcRef.current.x, 2);

                  dutyCycleDriveCalcRef.current.isdc =
                    sinfi +
                    cosfi *
                      (Math.sqrt(dutyCycleDriveCalcRef.current.root1) -
                        Math.sqrt(dutyCycleDriveCalcRef.current.root2));
                  dutyCycleDriveCalcRef.current.isqc =
                    dutyCycleDriveCalcRef.current.x * cosfi;
                  dutyCycleDriveCalcRef.current.imc =
                    resultCurrent *
                    Math.sqrt(
                      Math.pow(dutyCycleDriveCalcRef.current.isdc, 2) +
                        Math.pow(dutyCycleDriveCalcRef.current.isqc, 2),
                    );
                  dutyCycleDriveCalcRef.current.imCalc =
                    dutyCycleDriveCalcRef.current.imc;
                  dutyCycleDriveCalcRef.current.isq =
                    resultCurrent * dutyCycleDriveCalcRef.current.isqc;
                } else {
                  let x =
                    (tLoadMax * dutyCycleDriveCalcRef.current.testSpeed) /
                    (resultTorque * fwpSpeed);

                  let root1 = Math.pow(tMax, 2) - 1;
                  let root2 =
                    Math.pow(
                      (tMax * fwpSpeed) /
                        dutyCycleDriveCalcRef.current.testSpeed,
                      2,
                    ) - Math.pow(x, 2);

                  let isdf =
                    (fwpSpeed / dutyCycleDriveCalcRef.current.testSpeed) *
                      (sinfi + cosfi * Math.sqrt(root1)) -
                    cosfi * Math.sqrt(root2);

                  let isqf = x * cosfi;
                  dutyCycleDriveCalcRef.current.imf =
                    resultCurrent *
                    Math.sqrt(Math.pow(isdf, 2) + Math.pow(isqf, 2));

                  dutyCycleDriveCalcRef.current.isq = resultCurrent * isqf;
                }
              }
            }

            let motorSqCurrent =
              dutyCycleDriveCalcRef.current.isq *
              parseFloat(
                dimensionMotorResult?.dimension?.dimensionInput
                  ?.selectionInputs?.[0]?.motorsCount ?? '0',
              );

            let overloadCosfi =
              motorSqCurrent / dutyCycleDriveCalcRef.current.imf;

            rowData = [
              ...rowData,
              {
                rowName:
                  index === 0
                    ? t(I18.motor_drive_selection_modal_table_base_load_title)
                    : t(I18.motor_drive_selection_modal_table_over_load_title) +
                      ' ' +
                      index,
                time: row.time,
                minF: parseFloat(
                  ((row.minSpeed / resultSpeed) * resultFrequency).toFixed(1),
                ),
                maxF: parseFloat(
                  ((row.maxSpeed / resultSpeed) * resultFrequency).toFixed(1),
                ),
                current: parseFloat(
                  dutyCycleDriveCalcRef.current.imf.toFixed(1),
                ),
                cosfi: parseFloat(overloadCosfi.toFixed(2)),
              },
            ];

            timeData[index] = row.time;
            setTimeValues(timeData);

            currentData[index] = parseFloat(
              dutyCycleDriveCalcRef.current.imf.toFixed(1),
            );
            setCurrentValues(currentData);
          });
        setHasGraphDataChanged(true);
        setRows(rowData);
      } else {
        if (dutyCycleMotorValues?.length > 0) {
          let rowData: LoadDataDriveSelection[] = [];
          dutyCycleMotorValues?.forEach((row, index) => {
            rowData = [
              ...rowData,
              {
                rowName:
                  index === 0
                    ? t(I18.motor_drive_selection_modal_table_base_load_title)
                    : t(I18.motor_drive_selection_modal_table_over_load_title) +
                      ' ' +
                      index,
                time: row?.time,
                minF: baseLoadData?.[0]?.minF,
                maxF: baseLoadData?.[0]?.maxF,
                current:
                  dutyCycleDriveValues?.[index]?.current ??
                  baseLoadData?.[0]?.current,
                cosfi: baseLoadData?.[0]?.cosfi,
              },
            ];
          });
          setRows(rowData);
        } else {
          setRows([
            {
              rowName: t(I18.motor_drive_selection_modal_table_base_load_title),
              time: baseLoadData?.[0]?.time,
              minF: baseLoadData?.[0]?.minF,
              maxF: baseLoadData?.[0]?.maxF,
              current: baseLoadData?.[0]?.current,
              cosfi: baseLoadData?.[0]?.cosfi,
            },
            {
              rowName:
                t(I18.motor_drive_selection_modal_table_over_load_title) + ' 1',
              time: baseLoadData?.[1]?.time,
              minF: baseLoadData?.[1]?.minF,
              maxF: baseLoadData?.[1]?.maxF,
              current: baseLoadData?.[1]?.current,
              cosfi: baseLoadData?.[1]?.cosfi,
            },
          ]);
        }
      }
    } else {
      if (dutyCycleMotorValues?.length > 0) {
        let rowData: LoadDataDriveSelection[] = [];
        dutyCycleMotorValues?.forEach((row, index) => {
          rowData = [
            ...rowData,
            {
              rowName:
                index === 0
                  ? t(I18.motor_drive_selection_modal_table_base_load_title)
                  : t(I18.motor_drive_selection_modal_table_over_load_title) +
                    ' ' +
                    index,
              time: row?.time,
              minF: baseLoadData?.[0]?.minF,
              maxF: baseLoadData?.[0]?.maxF,
              current:
                dutyCycleDriveValues?.[index]?.current ??
                baseLoadData?.[0]?.current,
              cosfi: baseLoadData?.[0]?.cosfi,
            },
          ];
        });
        setRows(rowData);
      } else {
        setRows([
          {
            rowName: t(I18.motor_drive_selection_modal_table_base_load_title),
            time: baseLoadData?.[0]?.time,
            minF: baseLoadData?.[0]?.minF,
            maxF: baseLoadData?.[0]?.maxF,
            current: baseLoadData?.[0]?.current,
            cosfi: baseLoadData?.[0]?.cosfi,
          },
          {
            rowName:
              t(I18.motor_drive_selection_modal_table_over_load_title) + ' 1',
            time: baseLoadData?.[1]?.time,
            minF: baseLoadData?.[1]?.minF,
            maxF: baseLoadData?.[1]?.maxF,
            current: baseLoadData?.[1]?.current,
            cosfi: baseLoadData?.[1]?.cosfi,
          },
        ]);
      }
    }
    dispatch(saveDutyCycleValuesDrive(rows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseLoadData, t]);

  const optionsRef = useRef<ApexOptions | null>(dutyCyclegraphOptions);
  useEffect(() => {
    if (hasGraphDataChanged) {
      optionsRef.current = {
        annotations: {
          xaxis: [
            { x: 200, borderWidth: 1 },
            { x: 400, borderWidth: 1 },
            { x: 600, borderWidth: 1 },
            { x: 800, borderWidth: 1 },
            { x: 1000, borderWidth: 1 },
            { x: 1200, borderWidth: 1 },
            { x: 1400, borderWidth: 1 },
            { x: 1600, borderWidth: 1 },
            { x: 1800, borderWidth: 1 },
          ],
        },
        chart: {
          type: 'line',
          height: 283,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: false,
          },
        },
        xaxis: {
          min: 0,
          max: 1800,
          tickAmount: 9,
          title: {
            text: 'Time [s]',
            style: {
              fontSize: '$font-size-s',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          min: 0,
          max: Math.round(Math.max(...currentValues) + 20),
          tickAmount: 4,
        },
        stroke: {
          width: 2,
          colors: ['#323CF5'],
        },
      };
      setHasGraphDataChanged(false);
    }
  }, [
    baseLoadData,
    timeValues,
    currentValues,
    seriesData,
    hasGraphDataChanged,
  ]);

  const handleAddOverloadClick = () => {
    //max overload row count is 10
    if (rows.length < 11) {
      const newRow: LoadDataDriveSelection = {
        rowName:
          t(I18.motor_drive_selection_modal_table_over_load_title) +
          ' ' +
          rows.length,
        time: 0,
        minF: 0,
        maxF: 0,
        current: 0,
        cosfi: 0,
      };
      setRows([...rows, newRow]);
    }
  };

  const handleRowDeleteClick = (rowNumber: number) => {
    rows.splice(rowNumber, 1);
    if (rowNumber < rows.length) {
      rows?.forEach((i, index) => {
        if (index >= rowNumber) {
          i.rowName = 'Overload ' + index?.toString();
        }
      });
    }
    setHasGraphDataChanged(true);

    // row 0 & row 1 cannot be deleted. If Row 2 is being deleted, then we do not have multiple overload.
    if (rowNumber < 3) {
      setHasMultipleOverload(false);
    }
  };

  useEffect(() => {
    let time = 0;
    let rmsSum = 0;
    let totalRMS = 0;
    rows?.forEach((item) => {
      time = time + item.time;
      rmsSum += Math.pow(item.current, 2) * item.time;
    });
    totalRMS = parseFloat(Math.sqrt(rmsSum / time).toFixed(1));
    setRms(totalRMS);
  }, [rows]);

  useEffect(() => {
    if (
      hasGraphDataChanged &&
      currentValues?.length > 0 &&
      timeValues?.length > 0
    ) {
      let newCoordinates: number[][] = [];

      newCoordinates = calculateCoordinates(currentValues, timeValues, rows);
      setSeriesData(newCoordinates);
      setHasGraphDataChanged(false);
    }
  }, [hasGraphDataChanged, rows, currentValues, timeValues]);

  const handleApplyClick = () => {
    dispatch(saveDutyCycleValuesDrive(rows));
    onApplyClick(hasMultipleOverload);
  };

  const handleInputChange = (
    value: string,
    rowIndex: number,
    fieldName: keyof LoadDataDriveSelection,
  ) => {
    const inputValue = parseFloat(value);
    const updatedRows = [...rows];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      [fieldName]: inputValue,
    };

    if (fieldName === 'current') {
      const currentData = [...currentValues];
      currentData[rowIndex] = inputValue;
      setCurrentValues(currentData);
      setHasGraphDataChanged(true);

      if (rowIndex > 1) {
        if (inputValue > 0) {
          setHasMultipleOverload(true);
        } else {
          setHasMultipleOverload(false);
        }
      }
    }

    if (fieldName === 'time') {
      const timeData = [...timeValues];
      timeData[rowIndex] = inputValue;
      setTimeValues(timeData);
      setHasGraphDataChanged(true);
    }

    setRows(updatedRows);
  };

  return (
    <div className="modal-container">
      <Modal
        open={modalOpen}
        onModalClose={onClose}
        modalTitle={t(I18.motor_drive_selection_modal_add_duty_cycle_title)}
        showCloseButton={true}
        type="default"
        showHederSeparator="true"
        backdropClick={false}
        className="add-duty-cycle-modal"
      >
        <div
          slot="content"
          id="add-duty-cycle-modal-content"
          className="add-duty-cycle-modal-content"
        >
          <div className="rms-power-data-container">
            <div className="rms-data-container">
              <div className="rms-container">
                <div className="rms-data-labels">
                  {t(I18.motor_drive_selection_modal_rms_label)}
                </div>
                <div className="rms-data-value">{rms + ' A'}</div>
              </div>
            </div>
          </div>
          <div className="load-points-container">
            <div className="load-points-title">
              {t(I18.motor_drive_selection_modal_load_points_title)}
            </div>
            <div className="load-points-table-container">
              <table className="load-points-table">
                <thead>
                  <tr
                    className="load-points-table-header-container"
                    key={generateKey('load-points-header')}
                  >
                    <td className="load-points-cell description-title">
                      {t(
                        I18.motor_drive_selection_modal_table_description_title,
                      )}
                    </td>
                    <td className="load-points-cell time-title">
                      {t(I18.motor_drive_selection_modal_table_time_title)}
                    </td>
                    <td className="load-points-cell">
                      {t(I18.drive_duty_cycle_modal_table_min_f_title)}
                    </td>
                    <td className="load-points-cell">
                      {t(I18.drive_duty_cycle_modal_table_max_f_title)}
                    </td>
                    <td className="load-points-cell">
                      {t(I18.drive_duty_cycle_modal_table_current_title)}
                    </td>
                    <td className="load-points-cell">
                      {t(I18.drive_duty_cycle_modal_table_cosfi_title)}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((i, index) => {
                    return (
                      <div key={i.rowName + index}>
                        <tr
                          className="load-points-table-row"
                          key={generateKey(i.rowName)}
                        >
                          <td className="load-points-cell description-column">
                            {i.rowName}
                          </td>
                          <td className="load-point-input-cell">
                            <div
                              className="load-points-value-column"
                              key={generateKey(i.rowName)}
                            >
                              <div className="load-points-table-input-container">
                                <input
                                  className="load-points-table-input no-arrow"
                                  type="number"
                                  onBlur={(event) =>
                                    handleInputChange(
                                      event.target.value,
                                      index,
                                      'time',
                                    )
                                  }
                                  required={true}
                                  defaultValue={i.time}
                                />
                              </div>
                              <div className="load-points-table-unit-container">
                                {'S'}
                              </div>
                            </div>
                          </td>
                          <td className="load-point-input-cell">
                            <div className="load-points-value-column">
                              <div>
                                <input
                                  className="load-points-table-input no-arrow"
                                  type="number"
                                  onBlur={(event) =>
                                    handleInputChange(
                                      event.target.value,
                                      index,
                                      'minF',
                                    )
                                  }
                                  required={true}
                                  defaultValue={i.minF}
                                />
                              </div>
                              <div className="load-points-table-unit-container">
                                {'Hz'}
                              </div>
                            </div>
                          </td>
                          <td className="load-point-input-cell">
                            <div className="load-points-value-column">
                              <div>
                                <input
                                  className="load-points-table-input no-arrow"
                                  type="number"
                                  onBlur={(event) =>
                                    handleInputChange(
                                      event.target.value,
                                      index,
                                      'maxF',
                                    )
                                  }
                                  required={true}
                                  defaultValue={i.maxF}
                                />
                              </div>
                              <div className="load-points-table-unit-container">
                                {'Hz'}
                              </div>
                            </div>
                          </td>
                          <td className="load-point-input-cell">
                            <div className="load-points-value-column">
                              <div>
                                <input
                                  className="load-points-table-input no-arrow"
                                  type="number"
                                  onBlur={(event) =>
                                    handleInputChange(
                                      event.target.value,
                                      index,
                                      'current',
                                    )
                                  }
                                  required={true}
                                  defaultValue={i.current}
                                />
                              </div>
                              <div className="load-points-table-unit-container">
                                {'A'}
                              </div>
                            </div>
                          </td>
                          <td className="load-point-input-cell">
                            <div className="load-points-value-column">
                              <div className="load-points-table-input-container">
                                <input
                                  className="load-points-table-input no-arrow"
                                  type="number"
                                  required={true}
                                  defaultValue={i.cosfi}
                                  onBlur={(event) =>
                                    handleInputChange(
                                      event.target.value,
                                      index,
                                      'cosfi',
                                    )
                                  }
                                />
                              </div>
                              <div className="load-points-table-unit-container">
                                {''}
                              </div>
                            </div>
                          </td>
                          {index > 1 && (
                            <td className="dutycycle-delete-button-container">
                              <button
                                onClick={() => handleRowDeleteClick(index)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.key === ' ') {
                                    e.preventDefault();
                                    handleRowDeleteClick(index);
                                  }
                                }}
                                className="dutycycle-delete-button"
                              >
                                <Icon name="trash" />
                              </button>
                            </td>
                          )}
                        </tr>
                      </div>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="add-overload-button-container">
              <Button
                className="add-overload-button"
                type="secondary"
                onClick={handleAddOverloadClick}
                disabled={
                  rows.length === 11 ||
                  (hasMotorDutyCycleApplyClicked &&
                    rows.length === dutyCycleMotorValues.length) ||
                  (hasDimensionDataFetched &&
                    rows.length === dutyCycleMotorValues.length)
                }
              >
                {
                  <Icon
                    slot="icon"
                    size="small"
                    name="plus"
                    disabled={
                      rows.length === 11 ||
                      (hasMotorDutyCycleApplyClicked &&
                        rows.length === dutyCycleMotorValues.length) ||
                      (hasDimensionDataFetched &&
                        rows.length === dutyCycleMotorValues.length)
                    }
                  />
                }
                {t(I18.motor_drive_selection_modal_add_over_load_button)}
              </Button>
            </div>
          </div>
          <div className="load-graph-container">
            <div className="load-graph-title">
              {t(I18.motor_drive_selection_modal_current_graph_title)}
            </div>
            {optionsRef.current && (
              <div className="add-duty-cycle-graph-container">
                <ReactApexCharts
                  options={optionsRef.current || {}}
                  series={[
                    {
                      name: t(I18.drive_duty_cycle_graph_y_axis_title),
                      data: seriesData,
                    },
                  ]}
                  type={'line'}
                  height={optionsRef.current?.chart?.height ?? 100}
                />
              </div>
            )}
          </div>
        </div>
        <div className="add-duty-cycle-modal-footer" slot="footer">
          <Button
            type="secondary"
            size="small"
            onClick={onClose}
            className="create-project-cancel-button"
          >
            {t(I18.motor_drive_selection_modal_cancel_button)}
          </Button>
          <Button type="primary-black" size="small" onClick={handleApplyClick}>
            {t(I18.motor_drive_selection_modal_apply_button)}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DutyCycleModalDrive;
