import Button from '../../components/Form/Button';
import Icon from '../../components/Icon';
import './../styles/layout.scss';
import Modal from '../../components/Modal';
import { useEffect, useState } from 'react';
import GeneralFeedback from './GeneralFeedback';
import ReportIssueFeedback from './ReportIssueFeedback';
import SuggestionFeedback from './SuggestionFeedback';
import GenericModal from './GiveFeedback';
import notificationManager from '../../utils/NotificationManager';
import { FeedbackInput } from '../models/FeedbackModel';
import { useAddFeedbackMutation } from '../../pages/Home/services/APICommonService';
import { useSession } from '../../utils/Auth';
import { GetCurrentCountry } from '../../utils/GeneralUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

export const CurrentUserRoute = () => {
  const workspace = useSelector(
    (state: RootState) =>
      state.workspace?.applicationDetails?.[state.workspace?.currentAppRank],
  );

  const route = window?.location?.pathname?.split('/')?.pop();

  if (route === 'workspace' && workspace?.activeTab) {
    return workspace?.activeTab === 'selection'
      ? workspace?.activeComponent
      : workspace?.activeTab;
  } else {
    return route === '' ? 'home' : route;
  }
};

const Feedback = () => {
  const [open, setOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<FeedbackInput>();
  const { session } = useSession();
  const [addFeedback] = useAddFeedbackMutation();

  const userRoute = CurrentUserRoute();

  useEffect(() => {
    if (!open) {
      setFeedbackType(null);
    }
  }, [open]);

  const handleFeedbackType = (type: number | null) => {
    switch (type) {
      case null: {
        return <GenericModal setFeedbackType={setFeedbackType} />;
      }
      case 1: {
        return <GeneralFeedback setFeedback={setFeedback} />;
      }
      case 2: {
        return <ReportIssueFeedback setFeedback={setFeedback} />;
      }
      case 3: {
        return <SuggestionFeedback setFeedback={setFeedback} />;
      }
    }
  };

  const modalTitle = (type: number | null) => {
    switch (type) {
      case null: {
        return 'Give feedback';
      }
      case 1: {
        return 'General feedback';
      }
      case 2: {
        return 'Report an issue';
      }
      case 3: {
        return 'Feature suggestion';
      }
    }
  };

  const isSubmitDisabled = () => {
    if (!feedback) return true;
    switch (feedbackType) {
      case 1:
        return !!(
          feedback.mr === '0' ||
          feedback.eou === '0' ||
          feedback.satisfied === '0'
        );
      case 2:
        return !(feedback.detailDescription && feedback.challenges);
      case 3:
        return !feedback.suggestions;
      default:
        return true;
    }
  };

  const handleSubmit = () => {
    if (feedback) {
      setOpen(false);
      setFeedbackType(null);

      let payload: FeedbackInput = {
        ...feedback,
        application: 'GoSelect',
        pageName: userRoute,
        email: session?.user?.profile.email ?? 'a@test.com',
        country: GetCurrentCountry().country,
      };

      addFeedback(payload)
        .then((response) => {
          if ('data' in response)
            notificationManager.success(
              'Feedback submitted! Thanks for contacting us! We’ll get back to you soon.',
              { type: 'default' },
            );
          else if (
            'error' in response &&
            'status' in response.error &&
            'data' in response.error
          ) {
            if (response.error.status === 422 || response.error.status === 413)
              notificationManager.error(String(response.error.data));
          } else {
            notificationManager.error(
              'Failed to submit feedback. Please try again later.',
            );
          }
        })
        .catch((e) => {
          console.log(e);
          notificationManager.error(
            'Failed to submit feedback. Please try again later.',
          );
        }); // Call the addFeedback API
    }
  };

  return (
    <div>
      <div className="feedback-button">
        <Button
          type="secondary"
          size="medium"
          onClick={() => setOpen(true)}
          data-testid="give-feedback-button"
        >
          <Icon slot="icon" size="small" name="chat" />
          Give feedback
        </Button>
      </div>
      <Modal
        open={open}
        onModalClose={() => setOpen(false)}
        modalTitle={modalTitle(feedbackType)}
        showBackButton={feedbackType && feedbackType !== 4 ? true : false}
        showCloseButton={true}
        onBack={() => setFeedbackType(null)}
        type="default"
        backdropClick={false}
        data-testid="give-feedback-modal"
      >
        <div slot="content">{handleFeedbackType(feedbackType)}</div>
        <div className="report-graph-modal-footer" slot="footer">
          {feedbackType && feedbackType !== 4 && (
            <>
              <div className="info-cancel-button-container">
                <Button
                  type="discreet-black"
                  size="small"
                  onClick={() => setFeedbackType(null)}
                  className="info-cancel-button"
                  data-rf="confirmation-modal-cancel-button"
                >
                  Back
                </Button>
              </div>
              <div className="open-button-container">
                <Button
                  type="primary-black"
                  size="small"
                  onClick={handleSubmit}
                  className="info-button"
                  data-rf="confirmation-modal-open-button"
                  disabled={isSubmitDisabled()}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Feedback;
