import { CommonuxTooltip } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface TooltipProps extends JSX.CommonuxTooltip {
  size: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'none';
  alignment?: 'start' | 'end' | 'center';
  placement?: 'left' | 'right' | 'bottom' | 'top';
  arrowPosition?: 'top' | 'center' | 'bottom' | 'left' | 'right';
  className?: string;
}

const Tooltip = (props: PropsWithChildren<TooltipProps>) => {
  return (
    <div className="loader-container">
      <CommonuxTooltip
        alignment={props.alignment}
        arrowPosition={props.arrowPosition}
        onTooltipHover={function noRefCheck() {}}
        placement={props.placement}
        triggerEvent="hover"
        {...props}
      >
        {props?.children}
      </CommonuxTooltip>
    </div>
  );
};

export default Tooltip;
