import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { generateKey } from '../../../utils/GeneralUtils';
import BOMPlusCodes from './BOMPlusCodes';

const BOMDetails = () => {
  let i = 0;

  const applicationDetails = useSelector(
    (state: RootState) => state.workspace.applicationDetails,
  );

  const reportData = useSelector((state: RootState) => {
    return state.workspace;
  });

  return (
    <div className="bom-container">
      <div className="segment-container">
        <div id="pdf-section">
          {reportData.reportComments !== '' && (
            <div className="user-comments">
              <div>
                <div className="user-comments-title">User comments</div>

                <div className="user-comments-content">
                  {reportData.reportComments}
                </div>
              </div>
            </div>
          )}

          <div id="bom-details" className="segment-header">
            1. Bill of Material
          </div>
          <div className="segment-contents-container">
            <div className="segment-title">
              <div>#</div>
              <div>Application name</div>
              <div>Motor Type designation </div>
              <div>Product id</div>
              <div>Motor quantity</div>
              <div>Drive Long type code </div>
              <div>Base Product iD</div>
              <div>Drive quantity</div>
            </div>
            {applicationDetails.map((application, idx) => {
              const dimensionDetails = application?.dimensionDetails;
              if (
                application.dimensionDetails.productTypeCode?.driveTypeCode ||
                application.dimensionDetails.productTypeCode
                  ?.motorTypeDesignation
              ) {
                i++;
                return (
                  <div
                    key={generateKey(application.id)}
                    className="segment-content"
                  >
                    <div>{i}</div>
                    <div>{application.name}</div>
                    <div>
                      {!!dimensionDetails.productTypeCode.motorTypeDesignation
                        ? dimensionDetails.productTypeCode.motorTypeDesignation
                        : '-'}
                    </div>
                    <div>
                      {dimensionDetails.productTypeCode.productCode ?? '-'}
                    </div>

                    <div>
                      {application.dimensionDetails.productTypeCode
                        ?.motorTypeDesignation
                        ? dimensionDetails?.dimensionInputData
                          ?.selectionInputs?.[0].motorsCount
                        : '-'}
                    </div>
                    <div>
                      {!!dimensionDetails.productTypeCode.driveTypeCode
                        ? dimensionDetails.productTypeCode.driveTypeCode
                        : '-'}
                    </div>
                    <div>
                      {dimensionDetails.selectDriveResult.dimension
                        ?.catalogueDataDrive?.productAttributes?.ProductId
                        ?.values?.[0].text ?? '-'}
                    </div>

                    <div>
                      {application.dimensionDetails.productTypeCode
                        ?.driveTypeCode
                        ? dimensionDetails?.dimensionInputData
                          ?.selectionInputs?.[0].drivesCount
                        : '-'}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <BOMPlusCodes />
    </div>
  );
};

export default BOMDetails;
