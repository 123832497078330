import React from 'react';
import Card from '../../../components/Card';
import { timeAgo } from '../../../utils/GeneralUtils';
import { ArticleDetails } from '../models/homepageModel';

export interface LearningTabsProps {
  articlesData: ArticleDetails[] | undefined;
}

const LearningTabs = ({ articlesData }: LearningTabsProps) => {
  return (
    <div className="reading-container" data-rf="reading-documents-container">
      <div className="reading-contents" data-rf="reading-documents">
        {articlesData?.map((article, idx: number) => {
          if (articlesData?.length >= 1) {
            return (
              <Card
                key={(article.id, idx)}
                className="option-card"
                size="default"
                data-testid="learning-card"
                onCardClick={() => article?.link && window.open(article?.link)}
                type="discreet"
              >
                <div
                  className="content-image-container"
                  id="header"
                  slot="header"
                >
                  <img
                    className="content-image"
                    src={article.imageMedia?.url}
                    alt={article.imageMedia?.fileName}
                  />
                </div>
                <div slot="contents" id="content">
                  <div>
                    <div className="support-card-type">{article.type}</div>
                    <div className="support-card-header">
                      {article.entryTitle}
                    </div>
                    <div className="support-card-updated">
                      {timeAgo.format(new Date(article?.updateDate))}
                    </div>
                    <div className="support-card-desc">
                      {article.description}
                    </div>
                  </div>
                </div>
              </Card>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default LearningTabs;
