import { useState } from 'react';
import Icon from '../../../components/Icon';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useTranslate } from '../../../i18translate/Hooks';
import { RecentProjectMenuProps } from './types';
import driveAndMotorIcon from './../../../../src/assets/images/drive-motor-small.svg';
import plcHmiIcon from './../../../../src/assets/images/cpu-io.svg';
import Tooltip from '../../../components/Tooltip';
import { ProjectType } from '../models/homepageModel';
import { useDispatch } from 'react-redux';
import {
  setDeleteModalOpen,
  setDeleteProjectId,
} from '../store/actions/homeActions';

const RecentProjectMenu = ({
  openProjectButtonHandler,
  projectId,
  projectType,
}: RecentProjectMenuProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const { t } = useTranslate();
  const dispatch = useDispatch();

  const ref = useOutsideClick(() => {
    setShowMenu(false);
  });

  return (
    <>
      <div
        slot="contents"
        className="recent-project-menu-container"
        data-testid="project-menu-container"
      >
        <>
          <div className="recent-project-header">
            <div className="project-type-container">
              <button
                className="open-workspace-button"
                onClick={() => {
                  openProjectButtonHandler(ProjectType.DriveMotor);
                }}
              >
                <Tooltip
                  size="small"
                  alignment="center"
                  placement="top"
                  arrowPosition="bottom"
                  triggerEvent="hover"
                  className="workspace-switch-tooltip"
                >
                  <div slot="tooltip-parent" className="tooltip-icon">
                    <img
                      src={
                        projectType === ProjectType.DriveMotor
                          ? driveAndMotorIcon
                          : projectType === ProjectType.PLCHMI
                            ? plcHmiIcon
                            : driveAndMotorIcon
                      }
                    />
                  </div>
                  <div slot="content-section">
                    {projectType === ProjectType.DriveMotor
                      ? 'Open Drive & Motor Editor'
                      : projectType === ProjectType.PLCHMI
                        ? 'Open PLC & HMI Editor'
                        : 'Open Drive & Motor Editor'}
                  </div>
                </Tooltip>
              </button>
              {projectType === 0 && (
                <button
                  className="open-workspace-button"
                  onClick={() => {
                    openProjectButtonHandler(ProjectType.PLCHMI);
                  }}
                >
                  <div>
                    <Tooltip
                      size="small"
                      alignment="center"
                      placement="top"
                      arrowPosition="bottom"
                      triggerEvent="hover"
                      className="workspace-switch-tooltip"
                    >
                      <div slot="tooltip-parent" className="tooltip-icon">
                        <img src={plcHmiIcon} />
                      </div>

                      <div slot="content-section">
                        {'Open PLC & HMI Editor'}
                      </div>
                    </Tooltip>
                  </div>
                </button>
              )}
            </div>
            <div className="project-menu">
              <button
                className={` kebab-icon-container ${showMenu ? 'active' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(true);
                }}
                data-testid="kebab-icon"
                data-rf="project-kebab-icon"
              >
                <Icon name="kebab" size="small" slot="icon" />
              </button>
            </div>
          </div>
          {showMenu ? (
            <div className="project-menu-container" ref={ref}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(false);
                  openProjectButtonHandler(ProjectType.PLCHMI);
                }}
                data-testid="open-plc-workspace-button"
                data-rf="open-plc-workspace-button"
              >
                <img src={plcHmiIcon} />
                <label>{'Open PLC & HMI editor'}</label>
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(false);
                  openProjectButtonHandler(ProjectType.DriveMotor);
                }}
                data-testid="open-drive-motor-workspace-button"
                data-rf="open-drive-motor-workspace-button"
              >
                <img src={driveAndMotorIcon} />
                <label>{'Open Drive & Motor editor'}</label>
              </button>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(false);
                  dispatch(setDeleteProjectId(projectId));
                  dispatch(setDeleteModalOpen(true));
                }}
                data-testid="delete-text-button"
                data-rf="delete-text-button"
              >
                <Icon name="trash" size="medium" />
                <label>{t(`delete_button`)}</label>
              </button>
            </div>
          ) : (
            ''
          )}
        </>
      </div>
    </>
  );
};

export default RecentProjectMenu;
