import AbbHeader from '../../components/AbbHeader';
import BrandPlate from '../../components/BrandPlate';
import Button from '../../components/Form/Button';
import Icon from '../../components/Icon';
import { useTranslate } from '../../i18translate/Hooks';
import { I18 } from '../../languages/I18';
import { useDispatch, useSelector } from 'react-redux';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { Link } from 'react-router-dom';
import {
  CommonuxBreadcrumb,
  CommonuxBreadcrumbs,
  CommonuxLoadingIndicator,
  CommonuxMenu,
  CommonuxMenuItem,
} from '@abb-motion-ux/commonux-web-components-react';
import { useEffect, useRef, useState } from 'react';
import { login, logout, useSession } from '../../utils/Auth';
import {
  setSideBarState,
  setProjectUpdateOrSave,
} from '../../pages/Workspace/store/WorkspaceReducer';
import { useUpdateProjectMutation } from '../../pages/Home/services/APIMainService';
import ConfirmationModal from '../../pages/Home/Components/ConfirmationModal';
import OutsideClickHandler from '../../components/OutsideClickHandler';
import driveSystem from '../../assets/images/drive-system-16.svg';
import cpuIo from '../../assets/images/cpu-io.svg';
import ButtonGroupCommonux from '../../components/ButtonGroup';
import ButtonGroupItemCommonux from '../../components/ButtonGroupItem';
import { ProjectType } from '../../pages/Home/models/homepageModel';
import { RootState } from '../../store/rootReducer';
import { setProjectTypeSelected } from '../../pages/Home/store/actions/homeActions';
import Cookies from 'js-cookie';
import { menuOpenEventhandler } from '../../components/Form/Menu';
import Feedback from './Feedback';
import Tooltip from '../../components/Tooltip';

interface CookieBannerProps {
  onCookieBannerClick: () => void;
}

function Header(props: CookieBannerProps) {
  const { t } = useTranslate();
  const { isDesktop, isMobile } = useDeviceDetect();
  const legalRef = useRef(null);
  const supportRef = useRef(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState<
    boolean | undefined | null
  >(undefined);
  const { session: { user } = {}, loading } = useSession();
  const initials = [
    user?.profile?.given_name?.[0],
    user?.profile?.family_name?.[0],
  ].filter((initial) => initial !== undefined);
  const projectTypeSelected = useSelector(
    (state: RootState) => state.home.projectTypeSelected,
  );

  const dispatch = useDispatch();
  const projectName = sessionStorage.getItem('name');

  const handleSidebarButtonClick = () => {
    dispatch(setSideBarState(true));
  };

  const handleLogoutClick = () => {
    window.sessionStorage.removeItem('id');
    window.sessionStorage.removeItem('name');

    Cookies.set('isUserLoggedIn', `false`, {
      expires: 1,
      path: '/',
      domain: '.goselect.motion.abb.com',
    });

    if (
      process.env.REACT_APP_OIDC_CLIENT_ROOT &&
      process.env.REACT_APP_OIDC_CLIENT_ROOT.indexOf('localhost') > -1
    ) {
      logout();
    } else {
      window.location.href = process.env.REACT_APP_PLC_REDIRECT + '/plclogout';
    }
  };

  const [updateProject, results] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });

  const handleLoginButtonClick = () => {
    login();
    setShowDeleteModal(false);
  };

  const handleOnWorkspaceSwitch = (type: string) => {
    dispatch(setProjectTypeSelected(type.toString()));
    if (type === ProjectType.PLCHMI.toString()) {
      if (process.env.REACT_APP_PLC_CKNAME) {
        const date = new Date();
        Cookies.set(
          process.env.REACT_APP_PLC_CKNAME,
          date.getTime().toString(),
          {
            expires: 1,
            path: '/',
            domain: '.goselect.motion.abb.com',
          },
        );
        Cookies.set('isUserLoggedIn', `${Boolean(user)}`, {
          expires: 1,
          path: '/',
          domain: '.goselect.motion.abb.com',
        });
      }
      const url = process.env.REACT_APP_PLC_REDIRECT
        ? `${process.env.REACT_APP_PLC_REDIRECT}?id=${sessionStorage.getItem('id')}`
        : '';
      window.location.href = url;
    }
  };

  useEffect(() => {
    if (!results.isLoading && results.isSuccess) {
      setIsUpdateSuccessful(true);
      setProjectUpdateOrSave(true);
    } else if (!results.isLoading && results.isError) {
      setIsUpdateSuccessful(false);
      setProjectUpdateOrSave(false);
    } else if (results.isLoading && !results.isUninitialized) {
      setIsUpdateSuccessful(null);
      setProjectUpdateOrSave(null);
    }
  }, [results]);

  const isWorkspacePage = window.location.href.includes('workspace');

  return (
    <AbbHeader
      size={!isDesktop ? 'large' : 'medium'}
      className="header"
      data-rf="header-container"
      navigationAlignment="align-center"
      data-testid="header-container"
    >
      <div slot="brandplate">
        {!isWorkspacePage || isDesktop ? (
          <BrandPlate
            className="header-brandplate"
            hideLogo={!isDesktop}
            productname={t(I18.app_productname)}
            type="brand-color"
            data-rf="header-brandplate"
            size="small"
            data-testid="header-brandplate"
            caption={
              window.location.pathname === '/workspace'
                ? t(I18.app_header_caption)
                : ''
            }
          />
        ) : (
          <Button
            type="primary-black"
            size="large"
            block={false}
            data-rf="header-sidebar-button"
            data-testid="header-sidebar-button"
            onBtnClick={() => handleSidebarButtonClick()}
            className="sd"
          >
            <Icon slot="icon" size="small" name="tree-view" theme="light" />
          </Button>
        )}
      </div>
      <div
        slot="primary-navigation"
        className="primary-nav-container"
        data-rf="header-breadcrumbs-container"
        data-testid="header-breadcrumbs-container"
      >
        {isWorkspacePage && (
          <CommonuxBreadcrumbs type="regular">
            <CommonuxBreadcrumb
              data-rf="breadcrumb-home-button"
              data-testid="breadcrumb-home-button"
            >
              <Link to={'/'}>
                <Icon name="home" size="medium" className="navigation-icon" />
              </Link>
            </CommonuxBreadcrumb>

            <CommonuxBreadcrumb
              data-rf="breadcrumb-project-button"
              data-testid="breadcrumb-project-button"
            >
              {projectName}
            </CommonuxBreadcrumb>
            {/* <CommonuxBreadcrumb
              className="breadcrumb-last-item"
              data-rf="breadcrumb-revision-button"
            >
              New Revision*
            </CommonuxBreadcrumb> */}
            <div className="workspace-switch-button-container">
              <ButtonGroupCommonux
                selectedSegmentedControlId={projectTypeSelected}
              >
                <Tooltip
                  size="small"
                  alignment="center"
                  placement="bottom"
                  arrowPosition="bottom"
                  triggerEvent="hover"
                  className="workspace-switch-tooltip"
                >
                  <div slot="tooltip-parent">
                    <ButtonGroupItemCommonux
                      segmentedControlItemId={ProjectType.DriveMotor.toString()}
                      data-rf="DriveMotor-button"
                      className="drive-motor-button"
                    >
                      <div slot="icon">
                        <img src={driveSystem} />
                      </div>
                    </ButtonGroupItemCommonux>
                  </div>
                  <div slot="content-section">{'Drive & Motor'}</div>
                </Tooltip>
                <Tooltip
                  size="small"
                  alignment="center"
                  placement="bottom"
                  arrowPosition="bottom"
                  triggerEvent="hover"
                  className="workspace-switch-tooltip"
                >
                  <div slot="tooltip-parent">
                    <ButtonGroupItemCommonux
                      segmentedControlItemId={ProjectType.PLCHMI.toString()}
                      onSegmentedControlItemClick={() =>
                        handleOnWorkspaceSwitch(ProjectType.PLCHMI.toString())
                      }
                      data-rf="PLCHMI-button"
                    >
                      <div slot="icon">
                        <img src={cpuIo} />
                      </div>
                    </ButtonGroupItemCommonux>
                  </div>
                  <div slot="content-section">{'PLC & HMI'}</div>
                </Tooltip>
              </ButtonGroupCommonux>
            </div>

            <div hidden={user && !loading}>
              <Button
                type="primary-black"
                size="xsmall"
                className="project-status-container"
                onBtnClick={() => setShowDeleteModal(true)}
              >
                <Icon
                  className="project-status-icon"
                  size="small"
                  name="cloud"
                  slot="icon"
                  theme="light"
                />
                Save
              </Button>
            </div>
            <div hidden={!user && !loading}>
              <Button
                type="discreet-black"
                size="xsmall"
                disabled
                className="project-status-container"
              >
                <span hidden={isUpdateSuccessful === null} slot="icon">
                  <Icon
                    className="project-status-icon"
                    size="small"
                    name="cloud"
                    disabled
                  />
                </span>

                <span
                  hidden={
                    isUpdateSuccessful === true ||
                    isUpdateSuccessful === false ||
                    isUpdateSuccessful === undefined
                  }
                  slot="icon"
                >
                  <CommonuxLoadingIndicator
                    indicator-type="circular"
                    progress-value="70"
                    type="primary-black"
                    size="xsmall"
                    progress-type="indeterminate"
                    show-value="true"
                  />
                </span>
                <span
                  hidden={
                    isUpdateSuccessful === false || isUpdateSuccessful === null
                  }
                >
                  Saved
                </span>
                <span
                  hidden={
                    isUpdateSuccessful === true ||
                    isUpdateSuccessful === false ||
                    isUpdateSuccessful === undefined
                  }
                >
                  Saving
                </span>
                <span
                  hidden={
                    isUpdateSuccessful === true ||
                    isUpdateSuccessful === null ||
                    isUpdateSuccessful === undefined
                  }
                >
                  Not saved
                </span>
              </Button>
            </div>
          </CommonuxBreadcrumbs> // To do: Integrate revision name
        )}
      </div>
      <div
        slot="global-controls"
        className={`globalControls-container ${
          user && 'globalControls-container-authorization'
        }`}
      >
        {!loading && user && <Feedback />}
        {!isWorkspacePage && (
          <div
            className="lang-selection"
            data-rf="header-language-selector"
            data-testid="header-language-selector"
          >
            <Icon slot="icon" size="small" name="globe" />
            <span>EN</span>
          </div>
        )}
        <div hidden={true}>
          <Button
            id={'support-button'}
            type="primary-black"
            size="medium"
            data-rf="header-support-button"
            data-testid="header-support-button"
          >
            <Icon slot="icon" size="small" name="help-circle-1" theme="light" />
            {t(I18.app_support)}
          </Button>
        </div>

        <div
          className={`user-profile ${user ? '' : 'unauthorized'}`}
          onBlur={() => menuOpenEventhandler(supportRef.current, true)}
          data-testid="user-profile"
        >
          {user ? (
            <Button
              type="secondary"
              size={'medium'}
              block={false}
              disabled={false}
              onBtnClick={() => menuOpenEventhandler(supportRef.current)}
              data-rf="header-login-button"
              data-testid="header-login-button"
              className="user-profile-authorized"
            >
              <div data-testid="avatar" className={'avatar'}>
                {initials.join('')}
              </div>
            </Button>
          ) : (
            <span>
              <Button type="tertiary" size="medium" onBtnClick={login}>
                <Icon
                  slot="icon"
                  size="small"
                  name="user"
                  data-rf="login-button"
                  data-testid="login-button"
                />
                <span className="login-text" hidden={isMobile}>
                  {t(I18.app_header_login)}
                </span>
              </Button>
            </span>
          )}
          <CommonuxMenu
            ref={supportRef}
            closeMenuOnItemClicked
            closeOnBackdropClick
            size="large"
            data-testid="login-menu-container"
          >
            <CommonuxMenuItem
              label="Logout"
              size="small"
              onMouseDown={handleLogoutClick}
              data-testid="logout-button-menu-item"
            >
              <Icon slot="menu-icon" size="small" name="log-out" theme="dark" />
            </CommonuxMenuItem>
          </CommonuxMenu>
        </div>
        <div className={`user-profile ${user ? '' : 'legal-menu-container'}`}>
          <OutsideClickHandler
            onClickOutside={() => menuOpenEventhandler(legalRef.current, true)}
          >
            <Button
              id={'legal-button'}
              type="discreet-black"
              size="medium"
              data-rf="header-support-button"
              data-testid="header-support-button"
              onClick={() => menuOpenEventhandler(legalRef.current)}
            >
              <Icon slot="icon" size="small" name="menu" theme="dark" />
            </Button>

            <CommonuxMenu
              ref={legalRef}
              closeMenuOnItemClicked
              closeOnBackdropClick
              className="legal-menu"
              size="large"
            >
              <CommonuxMenuItem
                label={t(I18.footer_link_provider_information)}
                size="large"
                onClick={() => {
                  window.open(
                    'https://new.abb.com/provider-information',
                    '_blank',
                    'noopener',
                  );
                }}
                onBlur={() => {}}
              ></CommonuxMenuItem>
              <CommonuxMenuItem
                label={t(I18.footer_link_acceptable_use_policy)}
                size="large"
                onClick={() => {
                  window.open(
                    'https://library.e.abb.com/public/09158efb31874fd2a7739e4f1e63d1cb/ABB%20Acceptable%20Use%20Policy%20March%202018.pdf?x-sign=J7ipRSrP215EEzLV41EbTFXfodoPSGbxSipCvi2QU/6wZ1AvEPrjv6xt6Mr2L/Tz',
                    '_blank',
                    'noopener',
                  );
                }}
              ></CommonuxMenuItem>
              <CommonuxMenuItem
                label={t(I18.footer_link_privacy_notice)}
                size="large"
                onClick={() => {
                  window.open(
                    'https://new.abb.com/privacy-policy',
                    '_blank',
                    'noopener',
                  );
                }}
              ></CommonuxMenuItem>
              <CommonuxMenuItem
                label={t(I18.footer_link_change_cookie_setting)}
                size="large"
                onClick={() => {
                  props.onCookieBannerClick();
                }}
              ></CommonuxMenuItem>
              <CommonuxMenuItem
                label={t(I18.footer_link_legal)}
                size="large"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_OIDC_CLIENT_ROOT + '/info/legal',
                    '_blank',
                    'noopener',
                  );
                }}
              ></CommonuxMenuItem>
              <CommonuxMenuItem
                label={t(I18.footer_link_version)}
                size="large"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_OIDC_CLIENT_ROOT + '/info/versions',
                    '_blank',
                    'noopener',
                  );
                }}
                onBlur={() => {}}
              ></CommonuxMenuItem>
            </CommonuxMenu>
          </OutsideClickHandler>
        </div>
      </div>
      <div hidden={!showDeleteModal}>
        <ConfirmationModal
          modalTitle="Login to GoSelect"
          modalContentText="Login is required to save your project in GoSelect."
          modalOpen={true}
          buttonText="Continue to Login"
          onClick={handleLoginButtonClick}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          data_testid="confirmation-modal"
        />
      </div>
    </AbbHeader>
  );
}

export default Header;
