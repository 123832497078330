import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import SeachBar from '../../../../components/SearchBar';
import {
  DataGrid,
  DataGridCell,
  DataGridHeader,
  DataGridRow,
} from '../../../../components/DataGrid';
import Checkbox from '../../../../components/Form/Checkbox';
import Button from '../../../../components/Form/Button';
import { useTranslate } from '../../../../i18translate/Hooks';
import Icon from '../../../../components/Icon';
import Tooltip from '../../../../components/Tooltip';



interface IReviewPlusCodeModal {
  onClose: () => void;
  modalOpen: boolean;
  ApplyButtonHanlder: (drives: any) => void;
  modalTitle: string,
  selectedDrives: any,
  selectedCopyOptionType: string,
  backButtonHandler: () => void
}

const ReviewPlusCodesModal = ({ backButtonHandler, selectedCopyOptionType, selectedDrives, onClose, modalOpen, ApplyButtonHanlder, modalTitle }: IReviewPlusCodeModal) => {
  const { t } = useTranslate();

  const [selectDrive, setSelectDrive] = useState<{ driveTypeCode: string; checked: boolean; driveName: string | undefined; }[]>([])
  const [showAllCheckboxValues, setShowAllCheckboxValues] = useState(false);
  const [enableCopyButton, setEnableCopyButton] = useState(false);


  useEffect(() => {
    if (selectedCopyOptionType === 'All drives') {
      const drives = selectedDrives.map((drive: { checked: any }) => { return { ...drive, checked: true } })
      setSelectDrive(drives)
      setShowAllCheckboxValues(true)
    }
  }, [])

  const handleCheckboxValues = (driveTypeCode: string) => {
    const selectedDrive = selectDrive.map(drive => {
      if (drive.driveTypeCode === driveTypeCode) {
        drive.checked = !drive.checked;
      }
      return drive;
    })
    setSelectDrive(selectedDrive)
    const checkedDrive = selectDrive.filter(drive => drive.checked)
    checkedDrive.length === selectDrive.length ? setShowAllCheckboxValues(true) : setShowAllCheckboxValues(false);

  };

  const setAllCheckBox = (e: {
    target: { checked: boolean };
  }) => {
    console.log('e', e)
    const { checked } = e.target;

    const selectedDrive = selectDrive.map(drive => {
      drive.checked = checked;
      return drive;
    })
    setSelectDrive(selectedDrive)
    setShowAllCheckboxValues(checked)
  };

  useEffect(() => {
    //this component will also render when we are calling the selected option flow.

    if (selectedCopyOptionType !== 'All drives') {
      if (selectedDrives.length > 0) {
        setSelectDrive(selectedDrives)
        selectedDrives.filter((drive: { checked: boolean; }) => drive.checked).length === selectedDrives.length ? setShowAllCheckboxValues(true) : setShowAllCheckboxValues(false)

      }
    }


    if (selectDrive.length > 0) {
      selectDrive.some(drive => drive.checked) ? setEnableCopyButton(true) : setEnableCopyButton(false)
    }

  }, [selectDrive, selectedDrives])

  console.log('selectedCopyOptionType', selectedCopyOptionType, "selectDrive", selectDrive)
  return (
    <Modal
      onModalClose={onClose}
      open={modalOpen}
      backdropClick={false}
      modalTitle={modalTitle}
      showCloseButton={true}
      type="discreet"
      showHederSeparator="true"
      className="copy-and-delete-plus-codes-modal"
      showBackButton={true}

    >
      <div className="select-drive-modal-content" slot="content">


        <DataGrid
          divider="row"
          size="medium"
          type="primary-black"
          scrollable={false}
        >
          <DataGridRow is-header>
            <DataGridHeader colWidth={32} colResizable={false}>
              <div slot="checkbox-container">
                <Checkbox size="small" type="primary-black" checked={showAllCheckboxValues}
                  onCheckboxChange={(e) => { setAllCheckBox(e) }}
                />
              </div>
            </DataGridHeader>
            <DataGridHeader colResizable={false} colWidth={172}>
              <div>APPLICATION NAME</div>

            </DataGridHeader>
            <DataGridHeader
              label="DRIVE TYPE"
              colResizable={false}
              colWidth={'100%'}
            />

          </DataGridRow>
          <div className="grid-body">
            {selectDrive.map((drive: any) => (
              <DataGridRow

                className="data-grid"
                key={drive.driveTypeCode}
              >
                <DataGridCell showTooltip={false}>
                  <div slot="checkbox-container">
                    <Checkbox
                      onCheckboxChange={() =>
                        handleCheckboxValues(drive?.driveTypeCode)
                      }
                      size="small" type="primary-black" checked={drive.checked} />
                  </div>
                </DataGridCell>
                <DataGridCell showTooltip={false} noOfLinesForTruncation={2}>
                  {drive.driveName}
                </DataGridCell>
                <DataGridCell showTooltip={false}>
                  <div className='review-drive-type-status'>
                    <div className='review-drive-type-code'>
                      {drive.driveTypeCode}
                    </div>
                    {drive.isValid ? <Icon name="check-mark-circle-1" size='small' theme='dark' className='is-success' /> :

                      <div>
                        <Tooltip
                          alignment="center"
                          arrowPosition="center"
                          onTooltipHover={function noRefCheck() { }}
                          placement="left"
                          size="small"
                          triggerEvent="hover"
                        >
                          <Icon slot="tooltip-parent" className='not-is-success' name="information-circle-1" size="small" />
                          <div slot="content-section">
                            {drive?.error}
                          </div>
                        </Tooltip>

                      </div>

                    }
                  </div>
                </DataGridCell>

              </DataGridRow>
            ))}

          </div>
        </DataGrid>
      </div>
      <div slot="footer" className="copy-delete-plus-code-footer">
        <Button type="discreet-black" size="small" onClick={() => onClose()}>
          {t('cancel_button')}
        </Button>

        <Button type="primary-black" size="small" onClick={() => ApplyButtonHanlder(selectDrive)}
          disabled={!enableCopyButton}>
          Apply
        </Button>

      </div>
    </Modal>

  );
};

export default ReviewPlusCodesModal;
