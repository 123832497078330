import { ProjectActionTypes, ProjectsState } from '../../models/homepageModel';
import { ActionTypes } from '../actions/actionTypes';

const initialState: ProjectsState = {
  projectData: {
    projectId: '',
    projectName: '',
    projectType: '',
    createdDate: '',
    modifiedDate: '',
    destinationCountry: '',
    countryName: '',
  },
  newProjectData: {
    projectName: '',
    countryName: '',
    destinationCountry: '',
    standard: '',
    unitSystem: '',
    description: '',
    projectType: '',
  },
  projectTypeSelected: '1',
  deleteModalOpen: false,
  deleteProjectLoading: false,
  deleteProjectId: '',
};

const projectId = sessionStorage.getItem('id');
const projectName = sessionStorage.getItem('name');

const homepagereducer = (
  state = initialState,
  action: ProjectActionTypes,
): ProjectsState => {
  switch (action.type) {
    case ActionTypes.CREATE_PROJECT:
      return {
        ...state,
        projectData: {
          ...action.payload,
          projectId: action?.payload.projectId ?? projectId,
          projectName: action?.payload.projectName ?? projectName,
          createdDate:
            action?.payload?.createdDate ?? state?.projectData?.createdDate,
          modifiedDate:
            action?.payload?.modifiedDate ?? state?.projectData?.modifiedDate,
        },
      };
    case ActionTypes.ADD_PROJECT_DETAILS:
      return {
        ...state,
        newProjectData: action.payload,
      };
    case ActionTypes.PROJECT_TYPE_SELECTED:
      return { ...state, projectTypeSelected: action.payload };
    case ActionTypes.DELETE_MODAL_OPEN:
      return { ...state, deleteModalOpen: action.payload };
    case ActionTypes.DELETE_PROJECT_LOADING:
      return { ...state, deleteProjectLoading: action.payload };
    case ActionTypes.DELETE_PROJECT_ID:
      return { ...state, deleteProjectId: action.payload };
    default:
      return state;
  }
};

export default homepagereducer;
