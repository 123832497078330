import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import {
  ICClassEnum,
  IMClassEnum,
  LoadTypeReportEnum,
} from '../../Dimensioning/models/DriveMotorEnums';
import { ApplicationDetails } from '../../models/WorkspaceModels';

const imageUrlToBase64 = async (url: RequestInfo | URL) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export const ProjectTechnicalDataGeneration = async (
  applicationDetails: ApplicationDetails[],
) => {
  //intialization of excel
  let projectName = window.sessionStorage.getItem('name');
  const workbook = new Excel.Workbook();

  const worksheet = workbook.addWorksheet('technical', {
    properties: { tabColor: { argb: 'FFC000' } },
  });
  worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.font = { size: 12, name: 'Arial' }; // Set the desired font size here
    });
  });

  let ApplicationsArray: any;
  const firstTable: any = {
    'Project Technical Data': '',
    'Customer :': '',
    '': '',
    'project name :': '',
    'Customer ref :': '',
    'Location :': 'none',
    'ABB ref. no': '*',
    'Date :': `${
      new Date().getDate() +
      '/' +
      (new Date().getMonth() + 1) +
      '/' +
      new Date().getFullYear()
    }`,
    'Handled by :': '',
    'File name :': '',
    'Case :': '1',
    'Revision :': 'A',
  };

  const secondTable = {
    row1: [
      'Item',
      'Application Name',
      'Motors',
      'Load',
      '',
      'Speed range',
      '',
      'P',

      'Overload',
      '',
      'Motor',
      '',
      'nominal values',
      '',
      '',
      '',
      '',
      '',
      '',
      'construction',
      '',

      '',
      '',
      'Motor calculation result',

      '',
      '',
      'Drive',

      'Frequency converter',
      '',
      '',
      '',
      'Inu calculation',
      '',
    ],
    row2: [
      'No.',
      '',
      'per',
      'type',
      'min',
      'base',
      'max',
      '[nbase]',
      'n[base]',
      '[nmax]',
      'type',
      'Un',
      'fn',
      'Pn',
      'nn',
      'In',
      'cos',
      'eff',
      'Tmax',
      'IM',

      'IC',
      'Temp.rise',
      'Tcont',
      'Tmax',
      'Icont',
      'Imax',
      "q'ty",
      '',
      'In',
      'Imax',
      'Icont',
      'Imax',
      '',
    ],
    row3: [
      '',
      '',
      'drive',
      '',
      '[rpm]',
      '[rpm]',
      '[rmp]',
      '[kW]',
      '[%]',
      '[%]',
      '',
      '[V]',
      '[Hz]',
      '[kW]',
      '[rmp]',
      '[A]',
      'phi',
      '[%]',
      '/Tn',
      '',

      '',
      'Insul.',
      'margin',
      'margin',
      '[A]',
      '[A]',
      '',
      'Type designation',
      '[A]',
      '[A]',
      'margin',
      'margin',
      '',
    ],
  };

  if (applicationDetails && applicationDetails.length > 0) {
    ApplicationsArray = applicationDetails
      ?.filter(
        (filteredApplication) =>
          Object.keys(filteredApplication.dimensionDetails.productTypeCode)
            .length > 0,
      )
      .map((application, index) => {
        return {
          'item no': index + 1,
          'Application name': application?.name,
          'Motor per drive':
            application?.dimensionDetails?.selectMotorResult?.dimension
              ?.dimensionInput?.selectionInputs?.[0]?.motorsCount ?? '-', //no of motors
          'Load type': `${
            LoadTypeReportEnum[
              application?.dimensionDetails?.dimensionInputData
                ?.selectionInputs?.[0]
                ?.loadType as keyof typeof LoadTypeReportEnum
            ] ?? '-'
          }`,
          'Speed range': {
            //user input values at motor selection
            'min[rpm]':
              application?.dimensionDetails?.dimensionInputData
                ?.selectionInputs?.[0]?.nMin ?? '-',
            'base[rpm]':
              application?.dimensionDetails?.dimensionInputData
                ?.selectionInputs?.[0]?.nBase ?? '-',
            'max[rpm]':
              application?.dimensionDetails?.dimensionInputData
                ?.selectionInputs?.[0]?.nMax ?? '-',
          },
          'P [nbase][kW]':
            application?.dimensionDetails?.dimensionInputData
              ?.selectionInputs?.[0]?.power ?? '-', // user entered power
          Overload: {
            // user entered over load %
            '[nbase][%]':
              application?.dimensionDetails?.hasMultipleOverload ||
              application.dimensionDetails.motorLoadData?.overLoadType === '1'
                ? 'custom'
                : application?.dimensionDetails?.dimensionInputData
                      ?.selectionInputs?.[0]?.olBaseInput
                  ? application?.dimensionDetails?.dimensionInputData
                      ?.selectionInputs?.[0]?.olBaseInput
                  : '-',
            '[nmax][%]':
              application?.dimensionDetails?.hasMultipleOverload ||
              application.dimensionDetails.motorLoadData?.overLoadType === '1'
                ? 'custom'
                : application?.dimensionDetails?.dimensionInputData
                      ?.selectionInputs?.[0]?.olMaxInput
                  ? application?.dimensionDetails?.dimensionInputData
                      ?.selectionInputs?.[0]?.olMaxInput
                  : '-',
          },
          'motor type':
            application?.dimensionDetails?.productTypeCode
              ?.motorTypeDesignation ?? '-', //motor-typecode
          'nominal values': {
            // mapped from catalogue data (table in UI)
            'un[V]':
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Voltage ',
              )?.value || '-',
            'fn[Hz]':
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Frequency ',
              )?.value || '-',
            'Pn[kW]':
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Power ',
              )?.value || '-',
            'nn[rpm]':
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Speed ',
              )?.value || '-',
            'In[A]':
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Current ',
              )?.value || '-',
            'cos phi':
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Power factor',
              )?.value || '-',
            'eff [%]':
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Efficiency ',
              )?.value || '-',
            'Tmax/Tn':
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Tmax/Tn',
              )?.value || '-',
          },
          construction: {
            IM:
              IMClassEnum[
                application?.dimensionDetails?.selectMotorResult?.dimension
                  ?.dimensionInput?.selectionInputs?.[0]
                  ?.imClass as keyof typeof IMClassEnum
              ] ?? '-',
            // motor selection page (IM class dropdown)
            code: '',
            Ic:
              ICClassEnum[
                application?.dimensionDetails?.selectMotorResult?.dimension
                  ?.dimensionInput?.selectionInputs?.[0]
                  ?.icClass as keyof typeof ICClassEnum
              ] ?? '-', //IC class
          },
          'motor calculation result': {
            'Temp.rise/Insul.': `${
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'Temperature rise class',
              )?.value ?? 'B'
            }/ ${
              application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads[0]?.motorUnit?.results?.find(
                ({ name }) => name === 'insulation',
              )?.value ?? 'F'
            } `, //temp rise in
            'Tcont margin':
              application?.dimensionDetails?.selectMotorResult?.dimension
                ?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
                ?.miscellaneousUnit?.selectionData?.[1]?.row[3] ?? '-',
            //both margin from selection data.
            'Tmax margin':
              application?.dimensionDetails?.selectMotorResult?.dimension
                ?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
                ?.miscellaneousUnit?.selectionData?.[8]?.row[3] ?? '-',
            'Icont [A]':
              application?.dimensionDetails?.selectDriveResult?.dimension
                ?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
                ?.miscellaneousUnit?.selectionData?.[1]?.row?.[1] ?? '-', //  both from drive selection results
            'Imax [A]':
              application?.dimensionDetails?.selectDriveResult?.dimension
                ?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
                ?.miscellaneousUnit?.selectionData?.[2]?.row?.[1] ?? '-',
          },
          "Drive q'ty":
            application?.dimensionDetails?.selectDriveResult?.dimension
              ?.dimensionInput?.selectionInputs?.[0]?.drivesCount ?? '-', // no of drvies
          'Frequency converter': {
            //(rename to drive for the key)
            'type designation':
              application?.dimensionDetails?.productTypeCode?.driveTypeCode
                ?.length > 0
                ? application?.dimensionDetails?.productTypeCode?.driveTypeCode
                : '-', //drive type code
            'In [A]':
              application?.dimensionDetails?.selectDriveResult?.dimension
                ?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
                ?.miscellaneousUnit?.selectionData?.[1]?.row?.[2] ?? '-',
            'Imax[A]':
              application?.dimensionDetails?.selectDriveResult?.dimension
                ?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
                ?.miscellaneousUnit?.selectionData?.[2]?.row?.[2] ?? '-',
          },
          'Inu calculation': {
            'Icon margin':
              application?.dimensionDetails?.selectDriveResult?.dimension
                ?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
                ?.miscellaneousUnit?.selectionData?.[1]?.row?.[3] ?? '-',
            'Imax margin':
              application?.dimensionDetails?.selectDriveResult?.dimension
                ?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
                ?.miscellaneousUnit?.selectionData?.[2]?.row?.[3] ?? '-',
          },
        };
      });

    let myBase64Image: any;
    await imageUrlToBase64(
      `${process.env.REACT_APP_OIDC_CLIENT_ROOT}/abblogoblack.png`,
    ).then((res) => {
      myBase64Image = res;
      const imageId2 = workbook.addImage({
        base64: myBase64Image,
        extension: 'png',
      });
      //to adjust the styles of the image
      worksheet.addImage(imageId2, {
        tl: { col: 0, row: 0 }, // Top-left corner position (column, row)
        ext: { width: 125, height: 50 },
      });
    });

    const keys = Object.keys(firstTable);
    const firstHalf = keys.slice(0, 6);
    const secondHalf = keys.slice(6);

    const largestOptionsLength = applicationDetails?.reduce(
      (maxLength, application) => {
        const currentLength =
          application?.drivePlusCodeObject?.options?.length || 0;
        return Math.max(maxLength, currentLength);
      },
      0,
    );
    const maxLength = Math.max(firstHalf.length, secondHalf.length);
    worksheet.addRow([]);
    worksheet.addRow([]); // to get the gap between the image as image is taken as background
    for (let i = 0; i < maxLength; i++) {
      const row = [
        firstHalf[i] || '',
        '',
        firstTable[firstHalf[i]] || '',
        '',
        '',
        '',
        secondHalf[i] || '',
        '',
        firstTable[secondHalf[i]] || '',
        '',
        '',
        '',
        '',
      ];
      worksheet.addRow(row).font = { size: 10, name: 'Arial' };
    }

    // Define border styles
    const thinBorder = { style: 'thin' };

    const borders: any = {
      A: { top: thinBorder, left: thinBorder, bottom: thinBorder },
      BtoE: { top: thinBorder, bottom: thinBorder },
      F: { top: thinBorder, bottom: thinBorder, right: thinBorder },
      A5toA8: { left: thinBorder },
      A9: { left: thinBorder, bottom: thinBorder },
      F5toF8: { right: thinBorder },
      F9: { right: thinBorder },
      Bottom: { bottom: thinBorder },
      GtoMTop: { top: thinBorder },
      MRight: { right: thinBorder },
    };

    // Apply borders using loops
    ['A4', 'B4', 'C4', 'D4', 'E4', 'F4'].forEach((cell) => {
      const borderStyle = cell.startsWith('A')
        ? borders.A
        : cell === 'F4'
          ? borders.F
          : borders.BtoE;
      worksheet.getCell(cell).border = borderStyle;
    });

    for (let row = 5; row <= 8; row++) {
      worksheet.getCell(`A${row}`).border = borders.A5toA8;
      worksheet.getCell(`F${row}`).border = borders.F5toF8;
    }

    // Adjust borders for A9 and F9
    worksheet.getCell('A9').border = borders.A9;
    worksheet.getCell('F9').border = borders.F9;

    for (let col = 2; col <= 5; col++) {
      worksheet.getCell(`B9`).border = borders.Bottom;
      worksheet.getCell(`C9`).border = borders.Bottom;
      worksheet.getCell(`D9`).border = borders.Bottom;
      worksheet.getCell(`E9`).border = borders.Bottom;
    }

    for (let col = 7; col <= 12; col++) {
      worksheet.getCell(`${String.fromCharCode(64 + col)}4`).border =
        borders.GtoMTop;
      worksheet.getCell(`${String.fromCharCode(64 + col)}9`).border =
        borders.Bottom;
    }

    ['M4', 'M5', 'M6', 'M7', 'M8', 'M9'].forEach((cell) => {
      const borderStyle =
        cell === 'M9'
          ? { ...borders.MRight, bottom: thinBorder }
          : cell === 'M4'
            ? { ...borders.MRight, top: thinBorder }
            : borders.MRight;

      worksheet.getCell(cell).border = borderStyle;
    });

    //display styles of second tables
    const alignment: any = {
      horizontal: 'center',
      vertical: 'middle',
    };
    const fontStyles = {
      size: 10,
      name: 'Arial',
    };

    const secondTableRow1 = worksheet.addRow(secondTable.row1);

    secondTableRow1.alignment = alignment;

    secondTableRow1.font = fontStyles;

    let secondTableRow2 = worksheet.addRow(secondTable.row2);
    secondTableRow2.alignment = alignment;
    secondTableRow2.font = fontStyles;

    let secondTableRow3 = worksheet.addRow(secondTable.row3);
    secondTableRow3.alignment = alignment;
    secondTableRow3.font = fontStyles;

    worksheet.getColumn('B').width = 18;
    worksheet.getColumn('D').width = 18;
    worksheet.getColumn('K').width = 18;
    worksheet.getColumn('T').width = 18;
    worksheet.getColumn('U').width = 18;

    worksheet.getColumn('AB').width = (largestOptionsLength * 18) / 1.25 + 18; //to adjust the bottom border of the table2
    ApplicationsArray.forEach((project: any, index: any) => {
      let values = worksheet.addRow([
        project['item no'],
        project['Application name'],
        project['Motor per drive'],
        project['Load type'],
        project['Speed range']['min[rpm]'],
        project['Speed range']['base[rpm]'],
        project['Speed range']['max[rpm]'],
        project['P [nbase][kW]'],
        project.Overload['[nbase][%]'],
        project.Overload['[nmax][%]'],
        project['motor type'],
        project['nominal values']['un[V]'],
        project['nominal values']['fn[Hz]'],
        project['nominal values']['Pn[kW]'],
        project['nominal values']['nn[rpm]'],
        project['nominal values']['In[A]'],
        project['nominal values']['cos phi'],
        project['nominal values']['eff [%]'],
        project['nominal values']['Tmax/Tn'],
        project.construction?.IM,

        project.construction?.Ic,
        project['motor calculation result']['Temp.rise/Insul.'],
        project['motor calculation result']['Tcont margin'],
        project['motor calculation result']['Tmax margin'],
        project['motor calculation result']['Icont [A]'],
        project['motor calculation result']['Imax [A]'],
        project["Drive q'ty"],
        project['Frequency converter']['type designation'],
        project['Frequency converter']['In [A]'],
        project['Frequency converter']['Imax[A]'],
        project['Inu calculation']['Icon margin'],
        project['Inu calculation']['Imax margin'],
      ]);

      worksheet.eachRow((row) => {
        row.eachCell((cell) => (cell.font = { size: 10, name: 'Arial' }));
      });

      worksheet.getCell('A4').font = {
        size: 12,
        bold: true,
      };

      values.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
    });

    // Loop through columns A to AG and apply the bottom border
    for (let col = 1; col <= 33; col++) {
      // Column 'A' is 1, 'B' is 2, ..., 'AG' is 33
      const cellAddress = worksheet.getCell(12, col);
      const cellAddress2 = worksheet.getCell(10, col); // Row 12
      cellAddress.border = {
        bottom: { style: 'thin' },
      };

      cellAddress2.border = {
        top: { style: 'thin' },
      };
      worksheet.getCell(12 + ApplicationsArray.length + 2, col).border = {
        bottom: { style: 'thin' },
      };
    }

    for (let i = 10; i <= 12 + ApplicationsArray.length + 2; i++) {
      worksheet.getCell(`C${i}`).border = {
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (i === 12) {
        worksheet.getCell(`C${i}`).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
      if (i === 12 + ApplicationsArray.length + 2) {
        worksheet.getCell(`C${i}`).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
    }

    for (let i = 10; i <= 12 + ApplicationsArray.length + 2; i++) {
      worksheet.getCell(`A${i}`).border = {
        left: { style: 'thin' },
      };
      if (i === 12) {
        worksheet.getCell(`A${i}`).border = {
          left: { style: 'thin' },

          bottom: { style: 'thin' },
        };
      }
      if (i === 12 + ApplicationsArray.length + 2) {
        worksheet.getCell(`A${i}`).border = {
          left: { style: 'thin' },

          bottom: { style: 'thin' },
        };
      }
    }

    for (let i = 10; i <= 12 + ApplicationsArray.length + 2; i++) {
      worksheet.getCell(`AA${i}`).border = {
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (i === 10) {
        worksheet.getCell(`AA${i}`).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          top: { style: 'thin' },
        };
      }
      if (i === 12) {
        worksheet.getCell(`AA${i}`).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
      if (i === 12 + ApplicationsArray.length + 2) {
        worksheet.getCell(`AA${i}`).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
    }

    for (let i = 10; i <= 12 + ApplicationsArray.length + 2; i++) {
      worksheet.getCell(`J${i}`).border = {
        right: { style: 'thin' },
      };
      if (i === 12) {
        worksheet.getCell(`J${i}`).border = {
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
      if (i === 12 + ApplicationsArray.length + 2) {
        worksheet.getCell(`J${i}`).border = {
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
    }

    for (let i = 10; i <= 12 + ApplicationsArray.length + 2; i++) {
      worksheet.getCell(`U${i}`).border = {
        right: { style: 'thin' },
      };
      if (i === 10) {
        worksheet.getCell(`U${i}`).border = {
          right: { style: 'thin' },
          top: { style: 'thin' },
        };
      }
      if (i === 12) {
        worksheet.getCell(`U${i}`).border = {
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
      if (i === 12 + ApplicationsArray.length + 2) {
        worksheet.getCell(`U${i}`).border = {
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
    }
    for (let i = 10; i <= 12 + ApplicationsArray.length + 2; i++) {
      worksheet.getCell(`AD${i}`).border = {
        right: { style: 'thin' },
      };
      if (i === 10) {
        worksheet.getCell(`AD${i}`).border = {
          right: { style: 'thin' },
          top: { style: 'thin' },
        };
      }
      if (i === 12) {
        worksheet.getCell(`AD${i}`).border = {
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
      if (i === 12 + ApplicationsArray.length + 2) {
        worksheet.getCell(`AD${i}`).border = {
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
    }
    for (let i = 10; i <= 12 + ApplicationsArray.length + 2; i++) {
      worksheet.getCell(`AG${i}`).border = {
        right: { style: 'thin' },
      };
      if (i === 10) {
        worksheet.getCell(`AG${i}`).border = {
          right: { style: 'thin' },
          top: { style: 'thin' },
        };
      }
      if (i === 12) {
        worksheet.getCell(`AG${i}`).border = {
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
      if (i === 12 + ApplicationsArray.length + 2) {
        worksheet.getCell(`AG${i}`).border = {
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        };
      }
    }

    worksheet.mergeCells('A4', 'B4');
    worksheet.getCell('A4').alignment = { horizontal: 'left' };

    const buffer = await workbook.xlsx.writeBuffer();

    saveAs(
      new Blob([buffer]),
      `${projectName ? projectName : 'Project'}-Technical Data.xlsx`,
    );
  }
};
