import { useState, useEffect } from 'react'
import Modal from '../../../../components/Modal'
import { DataGrid, DataGridRow, DataGridHeader, DataGridCell } from '../../../../components/DataGrid'
import Button from '../../../../components/Form/Button'
import { useTranslate } from '../../../../i18translate/Hooks'
import CommonLoader from '../../../../components/Loader'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/rootReducer'
import { generatePayload, getMatchingApplications } from '../../../../utils/GeneralUtils'
import Icon from '../../../../components/Icon'
import Tag from '../../../../components/Tag'
import Tooltip from '../../../../components/Tooltip'

interface IVerifyingPlusCodeModal {
    selectedPlusCodes: any,
    onClose: () => void,
    modalOpen: boolean,
    nextButtonhandler: (drives: any) => void,
    nextbuttondisable: boolean,
    selectedDrives: any,
    copyOptionType: string
    userInfo: { userEmail: any, userLevel: string }
    handlingBackOption: () => void;
}


const DeletePlusCodeVerificationModal = ({ copyOptionType, userInfo,
    handlingBackOption, selectedDrives, modalOpen, nextButtonhandler, nextbuttondisable, onClose, selectedPlusCodes
}: IVerifyingPlusCodeModal) => {
    const { t } = useTranslate();

    const applications = useSelector((state: RootState) => state.workspace.applicationDetails)
    const [disableApplyButton, setDisableApplyButton] = useState(true)
    const [selectDrive, setSelectDrive] = useState<{ driveTypeCode: string; checked: boolean; driveName: string | undefined; id: string, options: any, isValid: boolean, typecode: any }[]>([])

    useEffect(() => {
        if (selectDrive) { }
        if (copyOptionType !== 'All drives') {
            let modifiedDrives;

            if (selectedDrives.length > 0) {
                modifiedDrives = selectedDrives.filter((drive: { driveTypeCode: any }) => drive.driveTypeCode).map((drive: any) => ({
                    ...drive,
                    isLoading: true
                }))
            }
            setSelectDrive(modifiedDrives)
            validateDrives(modifiedDrives)
        } else {

            const ApplicationsWithselectedApplication = getMatchingApplications(applications, getTheSelectedPlusCodes())
            if (ApplicationsWithselectedApplication && ApplicationsWithselectedApplication.length > 0) {
                const modifiedApplicationDriveList: { driveTypeCode: string; checked: boolean; driveName: string | undefined; isLoading: boolean; id: string, options: any, isValid: boolean, typecode: any }[] = ApplicationsWithselectedApplication?.filter(app => app.dimensionDetails?.productTypeCode?.driveTypeCode).filter(app => app.drivePlusCodeObject?.options && app.drivePlusCodeObject.options.length > 0).map(application => ({
                    driveTypeCode: application?.dimensionDetails?.productTypeCode?.driveTypeCode,
                    checked: false,
                    driveName: application?.name,
                    isLoading: true,
                    id: application?.id,
                    options: application.drivePlusCodeObject?.options,
                    isValid: false, // Default value for isValid,
                    typecode: ''
                }));

                setSelectDrive(modifiedApplicationDriveList);
                validateDrives(modifiedApplicationDriveList)
            }

        }


    }, [selectedDrives, copyOptionType, selectedPlusCodes])

    const getTheSelectedPlusCodes = () => {
        return selectedPlusCodes?.options?.filter((option: { checked: any; }) => option.checked).map(
            (item: { checked: any }) => {

                return item;
            },
        );
    }
    //combine typecodes
    //check the pluscodes with the drivetypecode
    const validateDrives = async (drives: any) => {
        const getTheSelectedPlusCode = getTheSelectedPlusCodes()
        //selected application drive family
        const checkedItemPlusCodes = getTheSelectedPlusCode
            .map((plusCodeValue: { plusCode: any }) => plusCodeValue.plusCode)
            .join(' ');

        const updatedDrives = drives.map((drive: any) => ({ ...drive })); // Shallow copy to avoid direct mutation
        try {
            for (let i = 0; i < updatedDrives.length; i++) {
                const list = updatedDrives[i];
                //removing the pluscodes from the original drivetypecode
                const driveTypeCode = list?.driveTypeCode?.split('+').filter((e: any) => !checkedItemPlusCodes.includes(e)).join('+');
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                const requestbody = {
                    payload: JSON.stringify(generatePayload(driveTypeCode, { userEmail: userInfo.userEmail, userLevel: userInfo.userLevel })),
                    isPROD: process.env.REACT_APP_IS_PROD ?? false
                }
                const requestOptions: RequestInit = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(requestbody),
                    redirect: "follow" as RequestRedirect
                };

                try {
                    const response = await fetch(`${process.env.REACT_APP_NODE_SERVER_ROOT}/validationRequest`, requestOptions);
                    const data = await response.json();
                    if (data) {

                        setSelectDrive(prevDrives => {

                            const newDrives = prevDrives.map((prevDrive, index) => {


                                if (index === i) {
                                    return {
                                        ...prevDrive,
                                        typecode: data.confirmedProductId,
                                        isLoading: false,
                                        isValid: data.valid,
                                        error: data.error,
                                        options: data.options

                                    };
                                }
                                return prevDrive;
                            });
                            return newDrives;
                        })
                    }
                    i === updatedDrives?.length - 1 ? setDisableApplyButton(false) : setDisableApplyButton(true)
                } catch (e) {
                    console.log('something went wrong', e)
                }
            }
        }
        catch (e) {
            console.log('something went wrong', e)
        }

    };

    //if copy type options is All drives then we need to verify all the drive from the project

    return (
        <Modal
            onModalClose={() => onClose?.()}
            open={modalOpen}
            backdropClick={false}
            modalTitle={'Verifying pluscodes'}
            showCloseButton={true}
            type="discreet"
            showHederSeparator="true"
            className="copy-and-delete-plus-codes-modal"
            showBackButton={true}
        >
            <div className="select-drive-modal-content" slot="content">
                <div className="copied-results-breadcrumb-container delete-tag-container">
                    <div>
                        {selectedPlusCodes && selectedPlusCodes?.options?.filter((item: { checked: boolean }) => item.checked)?.map((plusCodes: { plusCode: string }, index: number) => (
                            <Tag
                                className="copied-results-breadcrumb"
                                deleteButton={false}
                                type="primary-black"
                                key={plusCodes?.plusCode}
                            >
                                <div slot="tag">{'+'}{plusCodes?.plusCode}</div>
                            </Tag>
                        ))}
                    </div>
                </div>
                <DataGrid
                    divider="row"
                    size="medium"
                    type="primary-black"
                    scrollable={false}
                >
                    <DataGridRow is-header>

                        <DataGridHeader colResizable={false} colWidth={172}>
                            <div>APPLICATION NAME</div>

                        </DataGridHeader>
                        <DataGridHeader
                            label="DRIVE TYPE"
                            colResizable={false}
                            colWidth={"100%"}
                        />

                    </DataGridRow>
                    <div className="grid-body">
                        {selectDrive.map((drive: any) => (
                            <DataGridRow
                                className="data-grid"
                                key={drive.driveTypeCode}
                            >
                                <DataGridCell showTooltip={false}>
                                    <div>{drive.driveName}</div>
                                </DataGridCell>
                                <DataGridCell showTooltip={false}>
                                    <div className='review-drive-type-status'>
                                        <div className='review-drive-type-code'>
                                            {drive.driveTypeCode}
                                        </div>
                                        <div className='review-drivetype-icons'>
                                            {drive.isLoading ? <CommonLoader
                                                size="xsmall"
                                                indicatorType={'circular'}
                                                progressType={'indeterminate'}
                                                progressValue={50}
                                                type={'primary-black'}
                                            /> : drive.isValid ? <Icon name="check-mark-circle-1" size='small' theme='dark' className='is-success' /> :

                                                <div>
                                                    <Tooltip
                                                        alignment="center"
                                                        arrowPosition="center"
                                                        onTooltipHover={function noRefCheck() { }}
                                                        placement="left"
                                                        size="small"
                                                        triggerEvent="hover"
                                                    >
                                                        <Icon slot="tooltip-parent" className='not-is-success' name="information-circle-1" size="small" />
                                                        <div slot="content-section">
                                                            {drive?.error}


                                                        </div>
                                                    </Tooltip>

                                                </div>

                                            }

                                        </div>

                                    </div>
                                </DataGridCell>
                            </DataGridRow>
                        ))}



                    </div>
                </DataGrid>
            </div>
            <div slot="footer" className="copy-delete-plus-code-footer">
                <Button type="discreet-black" size="small" onClick={() => onClose?.()}>
                    {t('cancel_button')}
                </Button>

                <Button type="primary-black" size="small" onClick={() => {
                    nextButtonhandler(selectDrive)
                }}
                    disabled={disableApplyButton}>
                    Next
                </Button>

            </div>
        </Modal>
    )
}

export default DeletePlusCodeVerificationModal