import Icon from './Icon';

interface CustomBreadCrumbProps {
  breadcrumbName: string;
  text: string;
  breadCrumbAction: () => void;
}

const CustomBreadCrumb = ({
  breadCrumbAction,
  breadcrumbName,
  text,
}: CustomBreadCrumbProps) => {
  return (
    <div className="projects-header-container">
      <div
        role="button"
        className="home-button"
        data-rf="all-projects-home-button"
        onClick={() => breadCrumbAction()}
      >
        <Icon
          className="home-button-icon"
          slot="icon"
          size="small"
          name="left"
          theme="dark"
        />{' '}
        {breadcrumbName}
      </div>
      <h3 className="projects-header">{text}</h3>
    </div>
  );
};

export default CustomBreadCrumb;
