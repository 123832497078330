import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

const BOMPlusCodes = () => {
  const state = useSelector(
    (state: RootState) => state.workspace.applicationDetails,
  );

  const isPlusCodesAvilable = state.some((application) => {
    return (
      application?.drivePlusCodeObject &&
      application?.drivePlusCodeObject?.options && application?.drivePlusCodeObject?.options.length > 0
    );
  });

  const uniquePlusCodes: any = {};
  const result = [];

  for (const application of state) {
    const options = application?.drivePlusCodeObject?.options || [];
    for (const option of options) {
      if (option?.plusCode && !uniquePlusCodes[option.plusCode]) {
        uniquePlusCodes[option.plusCode] = {
          plusCode: option.plusCode,
          description: option.description,
        };
      }
    }
  }

  for (const key in uniquePlusCodes) {
    result.push(uniquePlusCodes[key]);
  }

  if (isPlusCodesAvilable && result.length > 0) {
    return (
      <>
        <div id="pdf-section">
          <div id="bom-details" className="segment-header plus-code-header">
            1.1. Plus code description
          </div>
          <div className="pluscode-table">
            <div className=" plus-code-title">
              <div className="column-30">Code</div>
              <div className="column-70">Description</div>
            </div>

            {result?.map((option, index) => {
              return (
                <div className=" plus-code-content" key={index}>
                  <div className="column-30">{option?.plusCode}</div>
                  <div className="column-70">{option?.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
  return null;
};

export default BOMPlusCodes;
