import React from 'react';
import TabGroup from '../../../components/TabGroup';
import TabItem from '../../../components/TabItem';

interface IProjectTabs {
  handleTabClick: (id: number) => void;
  activeTab: number;
}

const ProjectTabs: React.FC<IProjectTabs> = ({
  activeTab,
  handleTabClick,
}) => {
  return (
    <>
      <TabGroup
        selectedTabId={activeTab.toString()}
        type="primary-black"
        variant="primary-tab"
      >
        <TabItem
          label={'All Projects'}
          onClick={() => handleTabClick(1)}
          closable={false}
          tabId={`1`}
          disabled={activeTab !== 1}
        />
        {/* to do in the coming times */}
        {/* <TabItem
          label={'Shared with me'}
          onClick={() => handleTabClick(2)}
          closable={false}
          tabId={`2`}
          disabled={activeTab !== 2}
        /> */}
      </TabGroup>
    </>
  );
};

export default ProjectTabs;
