
import React from 'react'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Form/Button';
import { useTranslate } from '../../../../i18translate/Hooks';
import Icon from '../../../../components/Icon';
import CommonLoader from '../../../../components/Loader';
interface IDeletePlusCodeConfirmationModal {
    plusCodeOption: string
    modalOpen: boolean;
    modalClose: () => void;
    deleteButtonHandler: () => void;
    deletButtonLoader: boolean;

}



const DeletePlusCodeConfirmationModal = ({
    modalClose,
    modalOpen,
    deleteButtonHandler,
    plusCodeOption,
    deletButtonLoader,
}: IDeletePlusCodeConfirmationModal) => {
    const { t } = useTranslate();
    return (
        <Modal
            onModalClose={() => {
                modalClose?.()
            }}
            open={modalOpen}
            backdropClick={false}
            modalTitle={`Delete +${plusCodeOption} ?`}
            showCloseButton={true}
            type="default"
            showHederSeparator="false"
            className="copy-and-delete-plus-codes-modal"

        >
            <Icon name='trash' size='medium' />
            <div className="copy-delete-modal-container" slot="content">
                <p>This will delete the selected pluscode. This can’t be undone.</p>
            </div>
            <div className="copy-delete-plus-code-footer" slot="footer">
                <Button type="discreet-black" size="small" onClick={modalClose}>
                    {t('cancel_button')}
                </Button>
                <Button
                    type="primary-black"
                    size="small"
                    onClick={() => deleteButtonHandler()}
                >
                    {deletButtonLoader ? <>
                        <CommonLoader
                            size="xsmall"
                            indicatorType="circular"
                            progressType="indeterminate"
                            type="primary-black"
                            progressValue={undefined}
                        />
                    </> : t('delete_button')}
                </Button>
            </div>
        </Modal>
    )
}

export default DeletePlusCodeConfirmationModal 