import './../styles/layout.scss';
import { useEffect, useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { CommonuxTextArea } from '@abb-motion-ux/commonux-web-components-react';
import FileAttachment from './FileAttachment';
import { FeedbackInput } from '../models/FeedbackModel';
import { CommonuxTextAreaCustomEvent } from '@abb-motion-ux/commonux-web-components';

interface IGeneralFeedback {
  setFeedback: React.Dispatch<React.SetStateAction<FeedbackInput | undefined>>;
}

const GeneralFeedback = ({ setFeedback }: IGeneralFeedback) => {
  const [ratings, setRatings] = useState<number[]>([0, 0, 0]);
  const [comments, setComments] = useState<string>('');
  const [attachments, setAttachments] = useState<File[]>([]);

  useEffect(() => {
    setFeedback(undefined);
  }, []);

  const ratingChanged = (index: number, newRating: number) => {
    const newRatings = [...ratings];
    newRatings[index] = newRating;
    setRatings(newRatings);
    updateFeedback(newRatings, comments, attachments);
  };

  const handleCommentsChange = (event: CommonuxTextAreaCustomEvent<any>) => {
    const newComments = event.target.value;
    setComments(newComments);
    updateFeedback(ratings, newComments, attachments);
  };

  const handleAttachmentsChange = (newAttachments: File[]) => {
    setAttachments(newAttachments);
    updateFeedback(ratings, comments, newAttachments);
  };

  const updateFeedback = (
    ratings: number[],
    comments: string,
    attachments: File[],
  ) => {
    setFeedback({
      satisfied: ratings[0].toString(),
      eou: ratings[1].toString(),
      mr: ratings[2].toString(),
      comments,
      attachments,
    });
  };

  return (
    <div>
      <div className="feedback-modal-text" slot="content">
        <div className="feedback-modal-question-section">
          <div className="feedback-question-container">
            <div className="feedback-question-text">
              How satisfied are you with the tool overall?
            </div>
            <div className="feedback-question-content">
              <Rating
                onClick={(rate) => ratingChanged(0, rate)}
                size={55}
                transition
                fillColor="#1f1f1f"
                className="feedback-stars"
              />
              <div className="feedback-question-subtext">
                <div>Very Dissatisfied</div>
                <div>Very Satisfied</div>
              </div>
            </div>
          </div>

          <div className="feedback-question-container">
            <div className="feedback-question-text">
              How easy is it to navigate and use the tool?
            </div>
            <div className="feedback-question-content">
              <Rating
                onClick={(rate) => ratingChanged(1, rate)}
                size={55}
                transition
                fillColor="#1f1f1f"
                className="feedback-stars"
              />
              <div className="feedback-question-subtext">
                <div>Very Difficult</div>
                <div>Very Easy</div>
              </div>
            </div>
          </div>

          <div className="feedback-question-container">
            <div className="feedback-question-text">
              How well does the tool meet your requirements?
            </div>
            <div className="feedback-question-content">
              <Rating
                onClick={(rate) => ratingChanged(2, rate)}
                size={55}
                transition
                fillColor="#1f1f1f"
                className="feedback-stars"
              />
              <div className="feedback-question-subtext">
                <div>Does not meet requirements</div>
                <div>Fully meets requirements</div>
              </div>
            </div>
          </div>

          <div className="feedback-question-container">
            <div className="">
              <CommonuxTextArea
                labelText="Additional comments"
                placeholder="Leave us your comments"
                onInputChange={handleCommentsChange}
                inputLimit={500}
              />
            </div>
          </div>
          <FileAttachment setAttachments={handleAttachmentsChange} />
        </div>
      </div>
    </div>
  );
};

export default GeneralFeedback;
