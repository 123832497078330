import {
  CommonuxDragDrop,
  CommonuxDragDropMeta,
  CommonuxLoadingIndicator,
} from '@abb-motion-ux/commonux-web-components-react';
import { useEffect, useRef, useState } from 'react';
import Button from '../../components/Form/Button';
import html2canvas from 'html2canvas';
import Icon from '../../components/Icon';

interface IFileAttachment {
  setAttachments: (newAttachments: File[]) => void;
}

export const FileAttachment = ({ setAttachments }: IFileAttachment) => {
  const [files, setFiles] = useState<any[]>([]);
  const ref = useRef<any>(null);
  const [isCaptureLoading, setIsCaptureLoading] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>();
  const [typeError, setTypeError] = useState<boolean>();

  useEffect(() => {
    if (files?.length > 0) setAttachments(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleScreenCapture = async () => {
    setIsCaptureLoading(true);

    const dragDrop = ref.current;
    const element = document.getElementById('workspace-container');

    if (element) {
      html2canvas(element).then((canvas) => {
        canvas.toBlob((blob) => {
          if (blob) {
            let file = new File([blob], 'Screenshot.jpg', {
              type: 'image/jpeg',
            });
            Object.assign(file, { id: Date.now().toString(), ...file });
            const arr: any[] = [];
            arr.push(file);
            if (dragDrop) {
              dragDrop?.addFiles(arr);
            }
            setFiles([...files, ...arr]);
            setIsCaptureLoading(false);
          }
        }, 'image/jpeg');
      });
    }
  };

  return (
    <div>
      <div
        className="info-cancel-button-container"
        hidden={
          files.some((file) => file.name === 'Screenshot.jpg') ||
          files.length >= 5
        }
      >
        <Button
          type="tertiary"
          size="small"
          onClick={handleScreenCapture}
          className="info-attachment-button"
          disabled={isCaptureLoading}
          data-rf="confirmation-modal-cancel-button"
        >
          <div slot="icon">
            <Icon slot="icon" size="small" name="screen" theme="dark" />
          </div>
          Capture screenshot
        </Button>

        <div className="screenshot-subtext">
          <div>
            A screenshot will help us better understand your feedback.
            (Optional)
          </div>
        </div>
      </div>

      <CommonuxDragDrop
        ref={ref}
        minHeight={files.length >= 5 ? 0 : 150}
        actionContainerId="action-btns-multiple"
        errorText={
          typeError
            ? 'Unsupported format. Select JPEG or PNG.'
            : 'Some files exceed the 3 MB limit.'
        }
        fileAccept={'image/png,image/jpeg'}
        formatInfo="Maximum 5 files (3 MB each). Supported formats: JPEG, PNG."
        type="drag-to-add-and-upload"
        onClick={(e) => {
          e?.stopPropagation();
        }}
        onFileChange={(e) => {
          setTypeError(false);
          setFileError(false);
          const validFiles: any[] = [];
          const filesToRemove: any[] = [];
          e.detail.forEach((file: any) => {
            if (validFiles.length < 5) {
              if (file.size / 1048576 <= 3) {
                if (file.type === 'image/png' || file.type === 'image/jpeg') {
                  validFiles.push(file);
                } else {
                  setTypeError(true);
                  setFileError(true);
                  filesToRemove.push(file);
                }
              } else if (file.size / 1048576 > 3) {
                setFileError(true);
                filesToRemove.push(file);
              }
            }
          });
          const dragDrop = ref.current;
          dragDrop.removeFiles(filesToRemove);
          setFiles([...validFiles]);
        }}
        mobFileList={files}
        onDelete={(e) => {
          setFiles([...e.detail.fileList]);
        }}
        isDisabled={files.length >= 5 || isCaptureLoading}
        isError={fileError && files.length < 5}
        showFormatInfo
        showIcon
        showTitle
        showUploadText={true}
        size="small"
        theme="primary-black"
        titleText="Click or drag a file here to upload"
        uploadText="uploading the file"
        variant="multiple"
      >
        <div slot="meta">
          {files?.map((file: any, index) => {
            if (index < 5) {
              return (
                <CommonuxDragDropMeta
                  id={file.id}
                  key={file.id}
                  titleText={file.name}
                  date={file.formatedDate}
                  showCaret={true}
                >
                  <div slot="extra-info-title">File type: {file.type}</div>
                  <div slot="extra-info-body">
                    Size: {Math.round(file.size / 1024)}kb
                  </div>
                  <div slot="progress-indicator">
                    <CommonuxLoadingIndicator
                      indicatorType="linear"
                      type="primary-blue"
                      size="small"
                      progressType="determinate"
                      show-value="false"
                    ></CommonuxLoadingIndicator>
                  </div>
                  <div slot="waiting-status">Waiting...</div>
                </CommonuxDragDropMeta>
              );
            } else return null;
          })}
        </div>
        <span slot="uploading-indicator">
          <CommonuxLoadingIndicator
            indicator-type="circular"
            progress-type="indeterminate"
            size="small"
            type="primary-blue"
          />
        </span>
      </CommonuxDragDrop>
    </div>
  );
};

export default FileAttachment;
