import {
  CommonuxListItem,
  CommonuxMenu,
  CommonuxMenuItem,
} from '@abb-motion-ux/commonux-web-components-react';
import Card from '../../components/Card';
import CustomBreadCrumb from '../../components/CustomBreadCrumb';
import Button from '../../components/Form/Button';
import Icon from '../../components/Icon';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { menuOpenEventhandler } from '../../components/Form/Menu';
import React, { useRef } from 'react';

const Revision = () => {
  const navigate = useNavigate();
  const revisionMenuRef = useRef(null);
  return (
    <div id="workspace-container" className="layout-container">
      <div className="revison-container">
        <CustomBreadCrumb
          breadcrumbName={'Projects'}
          text="Project Name"
          breadCrumbAction={() => navigate('/projects')}
        />
        <div className="revison-button-group">
          <Button size="large" type="tertiary">
            <Icon name="import" size="medium" /> Import
          </Button>
          <Button size="large" type="tertiary">
            <Icon name="share" size="medium" /> Share project
          </Button>
          <Button size="large" type="primary-red">
            <Icon name="plus" size="medium" theme="light" /> New revision
          </Button>
        </div>
        <div className="revision-info-container">
          <div className="revision-info">
            <div className="revision-headers  revision-info-item">
              <span>Project-info</span>
              <Button type="discreet-black" size="xsmall">
                <Icon name="edit" size="small" /> Edit
              </Button>
            </div>
            <div>
              <div className="revision-info-list">
                <div>
                  <Icon name="user" size="small" />
                  <span>Created by</span>
                </div>
                <p>Lucy Matthews</p>
              </div>
              <div className="revision-info-list">
                <div>
                  <Icon name="factory" size="small" />
                  <span>Company</span>
                </div>
                <p>ABB</p>
              </div>
              <div className="revision-info-list">
                <div>
                  <Icon name="globe" size="small" />
                  <span>Country</span>
                </div>
                <p>Finland</p>
              </div>
              <div className="revision-info-list">
                <div>
                  <Icon name="map" size="small" />
                  <span>Destination country</span>
                </div>
                <p>Finland</p>
              </div>
              <div className="revision-info-list">
                <div>
                  <Icon name="certificate-document-2" size="small" />
                  <span>Standard</span>
                </div>
                <p>IEC</p>
              </div>
              <div className="revision-info-list">
                <div>
                  <Icon name="thumbnail-view" size="small" />
                  <span>Unit system</span>
                </div>
                <p>Metric</p>
              </div>
            </div>
          </div>
          <div className="revision-lists">
            <p>4 revision in this project</p>
            <div className="list">
              <div>
                <Card className="revision-card" type="discreet" size="default">
                  <div slot="contents">
                    <div className="revision-card-text-content">
                      <h5>Revision 4</h5>
                      <p>Created 2 months ago . Last modified 1 day ago</p>
                    </div>
                    <div className="card-action">
                      <div className="revision-creator-eclipse">
                        <span>ec</span>
                      </div>
                      <Button size="small" type="discreet-black">
                        <Icon name="share" size="small" />
                      </Button>
                      <Button
                        size="small"
                        type="discreet-black"
                        onBtnClick={() =>
                          menuOpenEventhandler(revisionMenuRef.current)
                        }
                      >
                        <Icon name="menu" size="small" />
                      </Button>
                      <CommonuxMenu
                        ref={revisionMenuRef}
                        closeMenuOnItemClicked
                        closeOnBackdropClick
                        size="large"
                      >
                        <CommonuxMenuItem
                          label="Logout"
                          size="small"
                          onMouseDown={() => {}}
                        >
                          <Icon
                            slot="menu-icon"
                            size="small"
                            name="log-out"
                            theme="dark"
                          />
                        </CommonuxMenuItem>
                      </CommonuxMenu>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Revision;
