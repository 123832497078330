import { createSlice } from '@reduxjs/toolkit';
import {
  DriveSelectionData,
  DrivetypeDropdownsData,
  DrivetypeInputData,
  ErrorOrWarningDriveResponse,
} from '../Dimensioning/models/DriveSelectionModels';
import {
  LoadDataDriveSelection,
  LoadDataMotorSelection,
  RMSValues,
} from '../Dimensioning/models/DutyCycleModels';
import {
  ProductTypeCodeInfo,
  ProjectMotorType,
  DimensionOutput,
  ErrorOrWarningMotorResponse,
  MotorSelectionParamResult,
  Dimension,
  MotorLoadData,
  DropdownData,
} from '../Dimensioning/models/MotorSelectionModels';
import {
  ApplicationDriveMotorType,
  DimensionInput,
  TransformerResult,
  TransformerSelectionInput,
} from '../Dimensioning/models/TransformerSelectionModel';
import { MotorSelectionData } from '../Dimensioning/models/MotorSelectionModels';
import { v4 as uuidv4 } from 'uuid';
import {
  initialApplicationData,
  initialResultData,
  supplyLoadDropdownAt50HzData,
  transformerDefaultInputValues,
} from '../Dimensioning/models/TransformerDefaultValues';
import {
  AmbientConditionValues,
  ApplicationDetails,
  DimensionDetails,
} from '../models/WorkspaceModels';
import { driveLoadDefaultData } from '../Dimensioning/models/DriveDefaultValues';

const initialDimensionState: DimensionDetails = {
  selectMotorResult: {} as Dimension,
  selectDriveResult: {} as Dimension,
  typeIds: {} as ProjectMotorType,
  productTypeCode: {} as ProductTypeCodeInfo,
  dimensionOutputData: {} as DimensionOutput,
  dimensionInputData: {} as DimensionInput,
  motorParamsData: {} as MotorSelectionParamResult,
  dimensionDataLoading: true,
  motorLoadData: {} as MotorLoadData,
  motorFormData: {} as MotorSelectionData,
  driveLoadData: driveLoadDefaultData,
  driveFormData: {} as DriveSelectionData,
  dutyCycleMotorValues: [] as LoadDataMotorSelection[],
  dutyCycleDriveValues: [] as LoadDataDriveSelection[],
  torqueGraphCoordinates: [] as number[][],
  manualSelectionMotorData: undefined,
  manualSelectionDriveData: undefined,
  rmsValues: {} as RMSValues,
  hasFormDataChanged: false,
  driveTypeDropdownData: {} as DrivetypeDropdownsData,
  driveLoadDropdownDataSet: [],
  driveSelectionDropdownDataSet: {} as DrivetypeInputData,
  hasDimensionDataFetched: false,
  errorOrWarningMotorResponse: {
    errorMotorSelection: null,
    isError: false,
    isWarning: false,
  } as ErrorOrWarningMotorResponse,
  errorOrWarningDriveResponse: {
    errorDriveSelection: null,
    isError: false,
    isWarning: false,
  } as ErrorOrWarningDriveResponse,
  hasDriveCatalogueDataFetched: false,
  hasMotorCatalogueDataFetched: false,
  hasNavigatedToWorkspace: false,
  hasMultipleOverload: false,
  showPowerCurve: false,
  hasMotorDutyCycleApplyClicked: false,
  fetchedTransformerData: transformerDefaultInputValues,
  shouldResetDriveDropdown: false,
};

interface TabStates {
  sidebarOpen: boolean;
  isProjectTemp: boolean;
  reportName: string;
  reportComments: string;
  currentAppRank: number;
  previousAppRank: number;
  isAllApplicationsEdited: boolean;
  applicationDetails: ApplicationDetails[];
  currentTransformerId: number;
  transformerDetails: TransformerDetails;
  isProjectUpdatedOrSaved: boolean | null;
  hasAmbientConditionChanged: boolean;
  ambientConditionValues: AmbientConditionValues;
  isReportModalOpen: boolean;
  hasAnyApplicationSized: boolean;
  hasTransformerSizingErrorResponse: boolean;
  hasTransformerReselected: boolean;
  hasRetryCatalogDataSelected: boolean;
}

interface TransformerDetails {
  selectedTransformerDatas: TransformerSelectionInput;
  fetchedTransformerResult: TransformerResult;
  applicationDriveMotorType: ApplicationDriveMotorType[];
  supplyLoadDropdownOptions: DropdownData[];
}

const initialTransformerState: TransformerDetails = {
  selectedTransformerDatas: {
    transformerInput: transformerDefaultInputValues,
    selectionInputs: [initialApplicationData],
  } as TransformerSelectionInput,

  fetchedTransformerResult: {
    transformer: {
      transformerResult: [
        { typeDesignation: '-', selectionData: initialResultData },
      ],
    },
  },
  applicationDriveMotorType: [] as ApplicationDriveMotorType[],
  supplyLoadDropdownOptions: supplyLoadDropdownAt50HzData,
};

export const initialTabValue = {
  activeComponent: 'workspace',
  efficiencyTab: false,
  configureDriveTab: false,
  activeTab: 'selection',
  applicationActiveTabs: [],
  fromActiveComponent: '',
  selectionTabs: [
    {
      stepValue: 1,
      stepLabel: '',
    },
  ],
  workspaceSelectionTabs: [],
  selectionDetails: {
    industryId: '',
    industryName: '',
    locale: 'en-US',
    countryCode: '',
    applicationId: '',
    applicationName: '',
    motorId: '',
    productTypeId: 1,
    dutyTypeId: 0,
    powerUnitTypeId: 1,
    power: 0,
    voltage: 0,
    limit: 4,
  },
  selectionResults: {
    driveMotivators: undefined,
    motorMotivators: undefined,
    allMotorsData: [],
    allDrivesData: [],
    currentMotor: undefined,
    currentDrive: undefined,
    currentTab: undefined,
  },
  dimensionDetails: initialDimensionState,
};

export const initialState: TabStates = {
  sidebarOpen: true,
  isProjectTemp: true,
  reportName: '',
  reportComments: '',
  currentAppRank: 0,
  previousAppRank: 0,
  isAllApplicationsEdited: false,
  applicationDetails: [
    {
      id: 'app-' + uuidv4(),
      name: 'New Application',
      rank: 0,
      ...initialTabValue,
    } as unknown as ApplicationDetails,
  ],
  currentTransformerId: 0,
  transformerDetails: initialTransformerState,
  isProjectUpdatedOrSaved: false,
  hasAmbientConditionChanged: false,
  ambientConditionValues: {
    altitude: '1000',
    driveTemparature: '40',
    motorTemparature: '40',
    transformerTemparature: '40',
  },
  isReportModalOpen: false,
  hasAnyApplicationSized: false,
  hasTransformerSizingErrorResponse: false,
  hasTransformerReselected: false,
  hasRetryCatalogDataSelected: false,
};

export const initialValue: TabStates = {
  sidebarOpen: true,
  isProjectTemp: false,
  reportName: '',
  reportComments: '',
  currentAppRank: 0,
  previousAppRank: 0,
  isAllApplicationsEdited: false,
  applicationDetails: [
    {
      id: 'app-' + uuidv4(),
      name: 'New Application',
      rank: 0,
      ...initialTabValue,
    } as unknown as ApplicationDetails,
  ],
  currentTransformerId: 0,
  transformerDetails: initialTransformerState,
  isProjectUpdatedOrSaved: false,
  hasAmbientConditionChanged: false,
  ambientConditionValues: {
    altitude: '1000',
    driveTemparature: '40',
    motorTemparature: '40',
    transformerTemparature: '40',
  },
  isReportModalOpen: false,
  hasAnyApplicationSized: false,
  hasTransformerSizingErrorResponse: false,
  hasTransformerReselected: false,
  hasRetryCatalogDataSelected: false,
};

const workspaceSlice = createSlice({
  initialState,
  name: 'workspace slice',

  reducers: {
    resetAllApplication: (state) => {
      state.applicationDetails = [
        {
          id: 'app-' + uuidv4(),
          name: 'New Application',
          rank: 0,
          ...initialTabValue,
        } as unknown as ApplicationDetails,
      ];
    },

    setSideBarState: (state, action) => {
      state.sidebarOpen = action.payload;
    },

    setIsProjectTemp: (state, action) => {
      state.isProjectTemp = action.payload;
    },

    setActiveComponent: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].fromActiveComponent =
        state.applicationDetails[index].activeComponent;
      state.applicationDetails[index].activeComponent = action.payload;
    },

    setCurrentApplicationName: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].name = action.payload;
      state.isAllApplicationsEdited = true;
    },

    setReportName: (state, action) => {
      state.reportName = action.payload;
    },

    setReportComments: (state, action) => {
      state.reportComments = action.payload;
    },

    setMotorData: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].motor = action.payload;
    },

    setDriveData: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].drive = action.payload;
    },

    setLoadData: (state, action) => {
      const index = state.currentAppRank;

      const temp = {
        ...state.applicationDetails[index],
        driveSizeParams: {
          driveData:
            state.applicationDetails[index]?.driveSizeParams?.driveData,
          loadData: action.payload,
        },
      };

      state.applicationDetails.splice(index, 1, temp);
    },

    setDriveSizeData: (state, action) => {
      const index = state.currentAppRank;

      const temp = {
        ...state.applicationDetails[index],
        driveSizeParams: {
          loadData: state.applicationDetails[index]?.driveSizeParams?.loadData,
          driveData: action.payload,
        },
      };

      state.applicationDetails.splice(index, 1, temp);
    },

    addApplication: (state) => {
      state.applicationDetails.push({
        id: `app-${uuidv4()}`,
        rank:
          state.applicationDetails[state.applicationDetails.length - 1].rank +
          1,
        name: `New Application ${
          state.applicationDetails[state.applicationDetails.length - 1].rank + 1
        }`,
        ...initialTabValue,
      } as unknown as ApplicationDetails);

      state.currentAppRank = state.applicationDetails.length - 1;
      state.isAllApplicationsEdited = true;
    },

    addSavedApplication: (state, action) => {
      if (
        state.applicationDetails[0].id ===
          initialState.applicationDetails[0].id ||
        state.applicationDetails[0].id === action.payload.id
      ) {
        state.applicationDetails = [
          {
            id: action.payload.id,
            rank: parseInt(action.payload.rank),
            name: action.payload.name,
            ...initialTabValue,
          } as unknown as ApplicationDetails,
        ];
      } else {
        if (initialState.applicationDetails[0].rank === action.payload.rank) {
          state.applicationDetails.pop();
        }
        state.applicationDetails.push({
          id: action.payload.id,
          rank: parseInt(action.payload.rank),
          name: action.payload.name,
          ...initialTabValue,
        } as unknown as ApplicationDetails);
      }
    },

    setIsAllApplicationsEdited: (state, action) => {
      state.isAllApplicationsEdited = action.payload;
    },

    deleteApplication: (state) => {
      const index = state.currentAppRank;
      if (index === 0) {
        state.currentAppRank = 0;
        state.applicationDetails.splice(index, 1);
      } else if (index !== -1) {
        state.currentAppRank = index - 1;
        state.applicationDetails.splice(index, 1);
      }
    },

    setCurrentApplication: (state, action) => {
      // state.previousAppRank = state.currentAppRank;
      state.currentAppRank = action.payload;
    },

    setIndustryType: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].selectionDetails.industryId =
        action.payload.industryId;
      state.applicationDetails[index].selectionDetails.countryCode =
        action.payload.countryCode;
      state.applicationDetails[index].selectionDetails.industryName =
        action.payload.industryName;
    },

    setApplicationType: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].selectionDetails.applicationId =
        action.payload.applicationId;
      state.applicationDetails[index].selectionDetails.applicationName =
        action.payload.applicationName;
    },

    setRatings: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].selectionDetails.voltage =
        action.payload.voltage;
      state.applicationDetails[index].selectionDetails.power =
        action.payload.load;
      state.applicationDetails[index].selectionDetails.dutyTypeId =
        action.payload.loadType;
    },

    setPower: (state) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].selectionDetails.power = 0;
    },

    setSelectionTab: (state, action) => {
      const index = state.currentAppRank;

      const length = state.applicationDetails[index]?.selectionTabs.length;

      const temp = {
        ...state.applicationDetails[index],
        selectionTabs: [
          ...state.applicationDetails[index].selectionTabs.slice(0, length - 1),
          {
            stepValue: length,
            stepLabel: action.payload,
          },

          {
            stepValue: length + 1,
            stepLabel: '',
          },
        ],
      };
      state.applicationDetails.splice(index, 1, temp);
    },

    setEfficiencyTabStatus: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].efficiencyTab = action.payload;
    },

    setActiveTab: (state, action) => {
      const index = state.currentAppRank;
      if (index !== -1)
        state.applicationDetails[index].activeTab = action.payload;
    },

    setPDSEfficiencyDetails: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].pdsEfficiencyDetails = action.payload;
    },

    setDriveEfficiencyDetails: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].driveEfficiencyDetails = action.payload;
    },

    setEfficiencyUdpData: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].efficiencyUDPData = action.payload;
    },

    setEfficiencyUDPLossesData: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].efficiencyUDPLossesdata = action.payload;
    },

    setMotorEfficiencyDetails: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].motorEfficiencyDetails = action.payload;
    },

    setSelectionTabClick: (state, action) => {
      const index = state.currentAppRank;

      const temp = {
        ...state.applicationDetails[index],
        selectionTabs: [
          ...state.applicationDetails[index].selectionTabs.slice(
            0,
            action.payload - 1,
          ),
          {
            stepValue: action.payload,
            stepLabel: '',
          },
        ],
      };
      state.applicationDetails.splice(index, 1, temp);
    },

    selectMotor: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.selectMotorResult =
        action.payload;
    },

    selectDrive: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.selectDriveResult =
        action.payload;
    },
    selectMotorCatalog: (state, action) => {
      state.applicationDetails[
        action.payload.rank
      ].dimensionDetails.selectMotorResult = action.payload.data;
    },

    selectDriveCatalog: (state, action) => {
      state.applicationDetails[
        action.payload.rank
      ].dimensionDetails.selectDriveResult = action.payload.data;
    },

    projectAndMotorTypeSelected: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.typeIds = action.payload;
    },

    productAndTypeCodeSave: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.productTypeCode =
        action.payload;
    },

    dimensionOutputData: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.dimensionOutputData =
        action.payload;
      state.applicationDetails[index].dimensionDetails.dimensionDataLoading =
        false;
      state.applicationDetails[index].pdsEfficiencyDetails =
        initialState?.applicationDetails[0]?.pdsEfficiencyDetails;
      state.applicationDetails[index].motorEfficiencyDetails =
        initialState.applicationDetails[0]?.motorEfficiencyDetails;
      state.applicationDetails[index].driveEfficiencyDetails =
        initialState?.applicationDetails[0]?.driveEfficiencyDetails;
      state.applicationDetails[index].efficiencyUDPLossesdata =
        initialState?.applicationDetails[0]?.efficiencyUDPLossesdata;
      state.applicationDetails[index].efficiencyUDPData =
        initialState?.applicationDetails[0]?.efficiencyUDPData;
    },

    dimensionMotorInputData: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.dimensionInputData =
        action.payload;
    },

    setShowPowerCurve: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.showPowerCurve =
        action.payload;
    },

    dimensionDataLoading: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.dimensionDataLoading =
        action.payload;
      state.applicationDetails[index].dimensionDetails.dimensionOutputData =
        {} as DimensionOutput;
    },

    motorLoadDataSave: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.motorLoadData =
        action.payload;
    },

    motorParamsDataSave: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.motorParamsData = {
        ...action.payload,
      };
    },

    motorFormDataSave: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.motorFormData[
        action.payload.type as keyof MotorSelectionData
      ] = action.payload.event;
    },

    setRetrievedMotorFormData: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.motorFormData =
        action.payload;
    },

    driveFormDataSave: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.driveFormData =
        action.payload;
    },

    driveLoadDataSave: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.driveLoadData =
        action.payload;
    },

    saveDutyCycleValuesMotor: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.dutyCycleMotorValues =
        action.payload;
    },

    saveDutyCycleValuesDrive: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.dutyCycleDriveValues =
        action.payload;
    },

    saveRMSValues: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.rmsValues =
        action.payload;
    },

    setHasFormDataChanged: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.motorFormData = {
        ...state.applicationDetails[index].dimensionDetails.motorFormData,
        motorFamily:
          action.payload.family ??
          state.applicationDetails[index].dimensionDetails.motorFormData
            .motorFamily,
        motorType:
          action.payload.type ??
          state.applicationDetails[index].dimensionDetails.motorFormData
            .motorType,
      };
    },

    saveTorqueGraphCoordinates: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.torqueGraphCoordinates =
        action.payload;
    },

    recommendedMotorList: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.manualSelectionMotorData = action.payload;
    },

    recommendedDriveList: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.manualSelectionDriveData = action.payload;
    },

    saveDriveDropdown: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.driveTypeDropdownData =
        action.payload;
    },

    setDriveLoadDropdownDataSet: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.driveLoadDropdownDataSet = action.payload;
    },

    setDriveSelectionDropdownDataSet: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.driveSelectionDropdownDataSet = action.payload;
    },

    setHasDimensionDataFetched: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.hasDimensionDataFetched =
        action.payload;
    },

    saveMotorErrorResponse: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.errorOrWarningMotorResponse = action.payload;
    },

    saveDriveErrorResponse: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.errorOrWarningDriveResponse = action.payload;
    },

    resetDriveErrorResponse: (state) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.errorOrWarningDriveResponse = {
        errorDriveSelection: null,
        isError: false,
        isWarning: false,
      };
    },

    resetMotorErrorResponse: (state) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.errorOrWarningMotorResponse = {
        errorMotorSelection: null,
        isError: false,
        isWarning: false,
      };
    },

    resetDriveDropdown: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.shouldResetDriveDropdown = action.payload;
    },

    setHasMotorCatalogueDataFetched: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.hasMotorCatalogueDataFetched = action.payload;
    },

    setHasDriveCatalogueDataFetched: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.hasDriveCatalogueDataFetched = action.payload;
    },

    setHasNavigatedToWorkspace: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.hasNavigatedToWorkspace =
        action.payload;
    },

    setHasMultipleOverload: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.hasMultipleOverload =
        action.payload;
    },

    resetHasMultipleOverload: (state) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].dimensionDetails.hasMultipleOverload =
        initialDimensionState.hasMultipleOverload;
    },

    resetApplication: (state) => {
      const index = state.currentAppRank;
      state.applicationDetails[index] = {
        ...initialState.applicationDetails[0],
        id: state.applicationDetails[index].id,
        rank: state.applicationDetails[index].rank,
        name: state.applicationDetails[index].name,
      };
    },
    updateProductTypeDriveCode: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[
        index
      ].dimensionDetails.productTypeCode.driveTypeCode = action.payload;
    },
    resetMotor: (state) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].motor =
        initialState.applicationDetails[0].motor;
      state.applicationDetails[index].dimensionDetails.selectMotorResult =
        {} as Dimension;
      /* state.applicationDetails[
        index
      ]?.dimensionDetails?.dimensionOutputData?.[0]?.supplyDriveLoads?.[0]?.motorUnit = {} as MotorUnit; */
      state.applicationDetails[
        index
      ].dimensionDetails.productTypeCode.motorTypeDesignation = '';
    },

    resetDrive: (state) => {
      const index = state.currentAppRank;

      state.applicationDetails[index].drive =
        initialState.applicationDetails[0].drive;
      state.applicationDetails[index].dimensionDetails.selectDriveResult =
        {} as Dimension;

      /* state.applicationDetails[
        index
      ].dimensionDetails.dimensionOutputData?.[0]?.supplyDriveLoads?.[0]?.inverterUnit = {} as DriveUnit; */
      state.applicationDetails[
        index
      ].dimensionDetails.productTypeCode.driveTypeCode = '';
    },

    setHasMotorDutyCycleApplyClicked: (state, action) => {
      const index = state.currentAppRank;

      state.applicationDetails[
        index
      ].dimensionDetails.hasMotorDutyCycleApplyClicked = action.payload;
    },

    setTransformerInputData: (state, action) => {
      state.transformerDetails.selectedTransformerDatas = action.payload;
    },

    setFetchedTransformerResult: (state, action) => {
      state.transformerDetails.fetchedTransformerResult = action.payload;
    },

    setPreviousApplicationsDriveMotorType: (state, action) => {
      state.transformerDetails.applicationDriveMotorType = action.payload;
    },
    setProjectUpdateOrSave: (state, action) => {
      state.isProjectUpdatedOrSaved = action.payload;
    },
    setSupplyLoadDropdownOptions: (state, action) => {
      state.transformerDetails.supplyLoadDropdownOptions = action.payload;
    },
    setHasAmbientConditionChanged: (state, action) => {
      state.hasAmbientConditionChanged = action.payload;
    },
    setAmbientConditionValues: (state, action) => {
      state.ambientConditionValues = action.payload;
    },
    setIsReportModalOpen: (state, action) => {
      state.isReportModalOpen = action.payload;
    },
    setApplicationEffData: (state, action) => {
      //id,efficidetails

      state.applicationDetails[action.payload.rank].pdsEfficiencyDetails =
        action.payload.effData;
      state.applicationDetails[action.payload.rank].motorEfficiencyDetails =
        action.payload.motorLossesData;
      state.applicationDetails[action.payload.rank].driveEfficiencyDetails =
        action.payload.driveLossesData;
      state.applicationDetails[action.payload.rank].efficiencyUDPLossesdata =
        action.payload.listUdpPointsResult;
    },
    setHasAnyApplicationSized: (state, action) => {
      state.hasAnyApplicationSized = action.payload;
    },
    setHasTransformerSizingErrorResponse: (state, action) => {
      state.hasTransformerSizingErrorResponse = action.payload;
    },
    setHasTransformerReselected: (state, action) => {
      state.hasTransformerReselected = action.payload;
    },
    setHasRetryCatalogDataSelected: (state, action) => {
      state.hasRetryCatalogDataSelected = action.payload;
    },
    setDriveMotivators: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].selectionResults.driveMotivators =
        action.payload;
    },
    setMotorMotivators: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].selectionResults.motorMotivators =
        action.payload;
    },
    setAllMotorsData: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].selectionResults.allMotorsData =
        action.payload;
    },
    setAllDrivesData: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].selectionResults.allDrivesData =
        action.payload;
    },
    setCurrentMotor: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].selectionResults.currentMotor =
        action.payload;
    },
    setCurrentDrive: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].selectionResults.currentDrive =
        action.payload;
    },
    setCurrentTab: (state, action) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].selectionResults.currentTab =
        action.payload;
    },
    resetApplicationForSelection: (state) => {
      const index = state.currentAppRank;
      state.applicationDetails[index].drive =
        initialState.applicationDetails[0].drive;
      state.applicationDetails[index].dimensionDetails.selectDriveResult =
        {} as Dimension;
      state.applicationDetails[
        index
      ].dimensionDetails.productTypeCode.driveTypeCode = '';

      state.applicationDetails[index].motor =
        initialState.applicationDetails[0].motor;
      state.applicationDetails[index].dimensionDetails.selectMotorResult =
        {} as Dimension;
      state.applicationDetails[
        index
      ].dimensionDetails.productTypeCode.motorTypeDesignation = '';

      state.applicationDetails[index].dimensionDetails.dimensionInputData =
        {} as DimensionInput;

      state.applicationDetails[
        index
      ].dimensionDetails.errorOrWarningMotorResponse = {
        errorMotorSelection: null,
        isError: false,
        isWarning: false,
      };

      state.applicationDetails[
        index
      ].dimensionDetails.errorOrWarningDriveResponse = {
        errorDriveSelection: null,
        isError: false,
        isWarning: false,
      };
    },
    setApplicationandindustryName: (state, action) => {
      state.applicationDetails[
        action.payload.rank
      ].selectionDetails.industryName = action.payload.industryName;
      state.applicationDetails[
        action.payload.rank
      ].selectionDetails.applicationName = action.payload.applicationName;
    },
    setconfigureDriveTabStatus: (state, action) => {
      state.applicationDetails[state.currentAppRank].configureDriveTab =
        action.payload;
    },
    setPlusCodeOptionValues: (state, action) => {
      state.applicationDetails[state.currentAppRank].drivePlusCodeObject =
        action.payload;
    },
    setApplicationActiveTabs: (state, action) => {
      state.applicationDetails[state.currentAppRank].applicationActiveTabs =
        action.payload;
    },
    setWorkspaceSelectionTabs: (state, action) => {
      let tempArray =
        state.applicationDetails[state.currentAppRank].workspaceSelectionTabs;

      if (tempArray.length < 2) {
        state.applicationDetails[state.currentAppRank].workspaceSelectionTabs =
          [...tempArray, action.payload];
      }
    },
    setPlusCodeForSimilarDrives: (state, action) => {
      const { plusCodes, id } = action.payload;
      const applications = state.applicationDetails;
      applications.forEach((application) => {
        if (application.id === id) {
          application.drivePlusCodeObject = plusCodes;
          application.dimensionDetails.productTypeCode.driveTypeCode =
            plusCodes.longtypeCode;
        }
      });
    },
  },
});

export const {
  setApplicationandindustryName,
  setApplicationEffData,
  setIsReportModalOpen,
  addSavedApplication,
  setSideBarState,
  setIsProjectTemp,
  setActiveComponent,
  setApplicationType,
  setCurrentApplication,
  setDriveData,
  setDriveSizeData,
  setCurrentApplicationName,
  setIndustryType,
  setLoadData,
  setMotorData,
  setRatings,
  setReportComments,
  setReportName,
  setSelectionTab,
  setSelectionTabClick,
  addApplication,
  deleteApplication,
  selectDrive,
  selectMotor,
  selectDriveCatalog,
  selectMotorCatalog,
  saveDriveDropdown,
  saveDriveErrorResponse,
  saveDutyCycleValuesDrive,
  saveDutyCycleValuesMotor,
  saveMotorErrorResponse,
  saveRMSValues,
  saveTorqueGraphCoordinates,
  setDriveLoadDropdownDataSet,
  setDriveSelectionDropdownDataSet,
  setHasDimensionDataFetched,
  setHasDriveCatalogueDataFetched,
  setHasFormDataChanged,
  setHasMotorCatalogueDataFetched,
  setHasNavigatedToWorkspace,
  recommendedDriveList,
  recommendedMotorList,
  resetDriveErrorResponse,
  resetMotorErrorResponse,

  dimensionDataLoading,
  dimensionMotorInputData,
  dimensionOutputData,
  driveFormDataSave,
  driveLoadDataSave,
  motorFormDataSave,
  motorLoadDataSave,
  motorParamsDataSave,
  productAndTypeCodeSave,
  projectAndMotorTypeSelected,
  setHasMultipleOverload,
  resetHasMultipleOverload,
  setShowPowerCurve,
  resetApplication,
  resetMotor,
  resetDrive,
  setHasMotorDutyCycleApplyClicked,
  setIsAllApplicationsEdited,
  setTransformerInputData,
  setFetchedTransformerResult,
  setEfficiencyTabStatus,
  setPDSEfficiencyDetails,
  setDriveEfficiencyDetails,
  setMotorEfficiencyDetails,
  setEfficiencyUdpData,
  setEfficiencyUDPLossesData,
  setPreviousApplicationsDriveMotorType,
  setPower,
  setProjectUpdateOrSave,
  resetAllApplication,
  setSupplyLoadDropdownOptions,
  setHasAmbientConditionChanged,
  setAmbientConditionValues,
  setActiveTab,
  setHasAnyApplicationSized,
  setHasTransformerSizingErrorResponse,
  setHasTransformerReselected,
  setHasRetryCatalogDataSelected,
  setRetrievedMotorFormData,
  setDriveMotivators,
  setMotorMotivators,
  setAllMotorsData,
  setAllDrivesData,
  setCurrentMotor,
  setCurrentDrive,
  setCurrentTab,
  resetApplicationForSelection,
  updateProductTypeDriveCode,
  setconfigureDriveTabStatus,

  setPlusCodeOptionValues,
  setApplicationActiveTabs,
  resetDriveDropdown,
  setWorkspaceSelectionTabs,
  setPlusCodeForSimilarDrives,
} = workspaceSlice.actions;

export default workspaceSlice;
