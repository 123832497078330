import { AmbientConditionEditModalProps } from '../Dimensioning/models/WorkspaceTabModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import Modal from '../../../components/Modal';
import Button from '../../../components/Form/Button';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { AmbientConditionValues } from '../models/WorkspaceModels';
import { useState } from 'react';
import {
  setAmbientConditionValues,
  setHasAmbientConditionChanged,
} from '../store/WorkspaceReducer';
import NumericInput from '../../../components/NumericInput';

const AmbientConditionEditModal = ({
  modalOpen,
  onClose,
  onConfirmClick,
}: AmbientConditionEditModalProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [hasAltitudeExceededLimit, setHasAltitudeExceededLimit] =
    useState(false);
  const [hasMotorTempExceededLimit, setHasMotorTempExceededLimit] =
    useState(false);
  const [hasDriveTempExceededLimit, setHasDriveTempExceededLimit] =
    useState(false);
  const ambientConditionValues = useSelector(
    (state: RootState) => state.workspace.ambientConditionValues,
  );
  const [motorTemparature, setMotorTemparature] = useState(
    ambientConditionValues.motorTemparature,
  );
  const [driveTemparature, setDriveTemparature] = useState(
    ambientConditionValues.driveTemparature,
  );
  const [altitude, setAltitude] = useState(ambientConditionValues.altitude);

  const handleConfirmClick = () => {
    const ambientConditionValues: AmbientConditionValues = {
      altitude: altitude,
      driveTemparature: driveTemparature,
      motorTemparature: motorTemparature,
    };
    dispatch(setHasAmbientConditionChanged(true));
    dispatch(setAmbientConditionValues(ambientConditionValues));
    onConfirmClick(ambientConditionValues);
  };

  const handleAmbientDataChange = (inputValue: string, type: string) => {
    const value = parseFloat(inputValue);
    if (type === 'altitude') {
      setAltitude(inputValue);
      if (value > 0 && value <= 4000) {
        setHasAltitudeExceededLimit(false);
      } else {
        setHasAltitudeExceededLimit(true);
      }
    } else if (type === 'driveTemparature') {
      setDriveTemparature(inputValue);
      if (value >= 30 && value < 56) {
        setHasDriveTempExceededLimit(false);
      } else {
        setHasDriveTempExceededLimit(true);
      }
    } else {
      setMotorTemparature(inputValue);
      if (value >= 30 && value < 81) {
        setHasMotorTempExceededLimit(false);
      } else {
        setHasMotorTempExceededLimit(true);
      }
    }
  };

  const handleOnClose = () => {
    setAltitude(ambientConditionValues.altitude);
    setDriveTemparature(ambientConditionValues.driveTemparature);
    setMotorTemparature(ambientConditionValues.motorTemparature);
    setHasAltitudeExceededLimit(false);
    setHasDriveTempExceededLimit(false);
    setHasMotorTempExceededLimit(false);
    onClose();
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onModalClose={handleOnClose}
        showCloseButton={true}
        type="discreet"
        showHederSeparator="false"
        backdropClick={false}
        className="ambient-condition-edit-modal"
        modalTitle="Ambient settings"
      >
        <div
          id="confirmation-modal-content"
          className="ambient-condition-edit-modal-content modal-container"
          data-rf="confirmation-modal-content"
          slot="content"
        >
          <div className="ambient-condition-edit-modal-description">
            <div className="altitude-input-container">
              <NumericInput
                labelText="Altitude"
                className="altitude-input"
                size="medium"
                valid={!hasAltitudeExceededLimit}
                suffixElement={'m'}
                onInputInput={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'altitude',
                  )
                }
                onIncreaseClick={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'altitude',
                  )
                }
                onDecreaseClick={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'altitude',
                  )
                }
                interval={100}
                required={true}
                trailedIcon={false}
                inputValue={parseFloat(altitude ?? 0)}
                min={0}
                max={4000}
                optionalText={
                  'Altitude value should be between 0 & 4000 meters'
                }
                errorText={'Altitude value should be between 0 & 4000 meters'}
              />
            </div>
            <div className="motor-temparature-input-container">
              <NumericInput
                labelText={'Motor temperature'}
                className="motor-temparature-input"
                size="medium"
                valid={!hasMotorTempExceededLimit}
                suffixElement={'° c'}
                onInputInput={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'motorTemparature',
                  )
                }
                onIncreaseClick={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'motorTemparature',
                  )
                }
                onDecreaseClick={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'motorTemparature',
                  )
                }
                required={true}
                min={29}
                max={81}
                trailedIcon={false}
                inputValue={parseFloat(motorTemparature) ?? 0}
                optionalText={
                  'Motor temperature value should be between 30 & 80 ° c'
                }
                errorText="Motor temperature value should be between 30 & 80 ° c"
              />
            </div>

            <div className="drive-temparature-input-container">
              <NumericInput
                label-text={'Drive temperature'}
                className="drive-temparature-input"
                size="medium"
                valid={!hasDriveTempExceededLimit}
                suffixElement={'° c'}
                onInputInput={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'driveTemparature',
                  )
                }
                onIncreaseClick={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'driveTemparature',
                  )
                }
                onDecreaseClick={(event) =>
                  handleAmbientDataChange(
                    event.target.value.toString(),
                    'driveTemparature',
                  )
                }
                required={true}
                trailedIcon={false}
                min={29}
                max={56}
                inputValue={parseFloat(driveTemparature ?? '0')}
                optionalText={
                  'Drive temperature value should be between 30 & 55 ° c'
                }
                errorText={
                  'Drive temperature value should be between 30 & 55 ° c'
                }
              />
            </div>
          </div>
        </div>
        <div
          className="ambient-condition-edit-modal-button-container"
          slot="footer"
        >
          <div className="cancel-button-container">
            <Button
              type="tertiary"
              size="small"
              onClick={handleOnClose}
              className="cancel-button"
              data-rf="ambient-condition-edit-modal-cancel-button"
            >
              {t(I18.modal_cancel_button)}
            </Button>
          </div>
          <div className="apply-button-container">
            <Button
              type="primary-black"
              size="small"
              onClick={handleConfirmClick}
              className="confirmation-modal-select-button"
              data-rf="confirmation-modal-select-button"
              disabled={
                hasAltitudeExceededLimit ||
                hasDriveTempExceededLimit ||
                hasMotorTempExceededLimit
              }
            >
              {`Apply`}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AmbientConditionEditModal;
