import {
  GeneralProjectDetails,
  NewProjectInfo,
} from '../../models/homepageModel';
import { ActionTypes } from './actionTypes';

export const createProject = (newProjectInfo: GeneralProjectDetails) => ({
  type: ActionTypes.CREATE_PROJECT,
  payload: newProjectInfo,
});
export const addProjectDetails = (newProjectInfo: NewProjectInfo) => ({
  type: ActionTypes.ADD_PROJECT_DETAILS,
  payload: newProjectInfo,
});

export const setProjectTypeSelected = (projectTypeSelected: string) => ({
  type: ActionTypes.PROJECT_TYPE_SELECTED,
  payload: projectTypeSelected,
});

export const setDeleteModalOpen = (isOpen: boolean) => ({
  type: ActionTypes.DELETE_MODAL_OPEN,
  payload: isOpen,
});

export const setDeleteLoading = (isDeleteLoading: boolean) => ({
  type: ActionTypes.DELETE_PROJECT_LOADING,
  payload: isDeleteLoading,
});

export const setDeleteProjectId = (projectId: string) => ({
  type: ActionTypes.DELETE_PROJECT_ID,
  payload: projectId,
});
