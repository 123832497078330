import React from 'react';
import CommonLoader from '../../../../components/Loader';

interface DrivePlusCodesLoaderProps {
  label: string;
}

const DrivePlusCodesLoader = ({ label }: DrivePlusCodesLoaderProps) => {
  return (
    <React.Fragment>
      <div className="loading-indicator-container">
        <CommonLoader
          size="xsmall"
          indicatorType="circular"
          progressType="indeterminate"
          type="primary-black"
          progressValue={undefined}
        
        />
        <p>{label}</p>
      </div>
    </React.Fragment>
  );
};

export default DrivePlusCodesLoader;
