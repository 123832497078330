import { Blocker, useNavigate } from 'react-router-dom';
import Modal from './Modal';
import Button from './Form/Button';
import { useTranslate } from '../i18translate/Hooks';
import { I18 } from '../languages/I18';
import { login, useSession } from '../utils/Auth';
import { useDispatch } from 'react-redux';
import {
  resetAllApplication,
  setProjectUpdateOrSave,
} from '../pages/Workspace/store/WorkspaceReducer';

const CustomNavigationModal = ({
  blocker,
  handleSelectButtonClick,
}: {
  blocker: Blocker;
  handleSelectButtonClick?: () => void;
}) => {
  const { t } = useTranslate();
  const { session } = useSession();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = () => {
    if (!session?.user) {
      login();
    } else {
      dispatch(setProjectUpdateOrSave(true));
    }
  };

  if (blocker.state === 'blocked') {
    return (
      <Modal
        open={true}
        onModalClose={() => blocker.reset?.()}
        onModalBack={() => blocker.reset?.()}
        backdropClick={false}
        type="default"
        modalTitle={t(I18.navigation_block_modal_header)}
        className="block-navigation-modal modal"
      >
        <p slot="content">{t(I18.navigation_block_modal_content)}</p>

        <div slot="footer">
          <Button
            type="discreet-black"
            size="small"
            onClick={(event) => {
              blocker.proceed();
              dispatch(resetAllApplication());
              navigate('/', { replace: true });
              event.preventDefault();
              window.history.pushState({}, '', '/');
              sessionStorage.removeItem('id');
              sessionStorage.removeItem('name');
            }}
          >
            {t(I18.leave_button)}
          </Button>
          <Button
            type="primary-black"
            size="small"
            onClick={() => {
              handleLogin();
            }}
          >
            {t(I18.save_button)}
          </Button>
        </div>
      </Modal>
    );
  }

  return null;
};

export default CustomNavigationModal;
