import Button from '../../../../components/Form/Button';
import Modal from '../../../../components/Modal';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import Icon from '../../../../components/Icon';
import { ReselectionConfirmationModalProps } from '../models/TransformerSelectionModel';

const ReselectionConfirmationModal = ({
  modalOpen,
  onClose,
  onConfirmClick,
  contentText,
}: ReselectionConfirmationModalProps) => {
  const { t } = useTranslate();

  const handleConfirmClick = () => {
    onConfirmClick();
    onClose();
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        onModalClose={onClose}
        showCloseButton={false}
        type="default"
        showHederSeparator="false"
        modalTitle="Reselect applications?"
        backdropClick={false}
        className="reselection-confirmation-modal"
      >
        <div slot="title-icon">
          <Icon slot="icon" size="small" name="select" className="drive-icon" />
        </div>
        <div
          id="confirmation-modal-content"
          className="confirmation-modal-content modal-container"
          data-rf="confirmation-modal-content"
          slot="content"
        >
          <div className="reselection-confirmation-modal-description">
            {contentText}
          </div>
        </div>
        <div
          className="reselection-confirmation-modal-button-container"
          slot="footer"
        >
          <div className="cancel-button-container">
            <Button
              type="tertiary"
              size="small"
              onClick={onClose}
              className="cancel-button"
              data-rf="confirmation-modal-cancel-button"
            >
              {t(I18.modal_cancel_button)}
            </Button>
          </div>
          <div className="select-button-container">
            <Button
              type="primary-black"
              size="small"
              onClick={handleConfirmClick}
              className="confirmation-modal-select-button"
              data-rf="confirmation-modal-select-button"
            >
              {`Reselect`}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReselectionConfirmationModal;
