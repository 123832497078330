import React from 'react'
import { useTranslate } from '../../../../i18translate/Hooks';
interface IEfficiencyCorrectionFactorDetails {
    rowIds: string | undefined | null;
    componentName?: string
}


export const EfficiencyCorrectionFactorNotes = (props: IEfficiencyCorrectionFactorDetails) => {
    const { t } = useTranslate()
    if (props.rowIds === null) {
        return null;
    }

    if (props.rowIds && props?.rowIds?.length >= 1) {
        let rowIdsArray: string[] = []
        props?.rowIds?.split('').forEach(e => {
            rowIdsArray.push(`goselect_loss_determination_details_correction_note_factor_${e.toLocaleLowerCase()}`)
        })
        return (
            <>
                {rowIdsArray.map((e: string, index: number) => (
                    <div key={index}>{t(e)}
                        {e.slice(-1) === 'c' ? <img src={t('goselect_loss_determination_details_correction_note_factor_c_url')} alt="correction-factor-note-c" className='report-image-container' /> : ""}
                    </div>
                ))
                }

            </>



        )
    }
}

const EfficiencyCorrectionFactorDetails = (props: IEfficiencyCorrectionFactorDetails) => {
    const { t } = useTranslate()
    let stringArray: string[] = []
    if (props.rowIds === null) {
        return null;
    }
    if (props.rowIds && props?.rowIds?.length >= 1) {
        props.rowIds?.split('').forEach(element => {
            stringArray.push(`goselect_loss_determination_details_correction_factor_${element.toLowerCase()}`)
        });
    }


    if (stringArray.length > 0) {
        return (
            <>
                <span>
                    {props.componentName === 'PDSEfficiency' ? t('goselect_pds_report_loss_determination_content_correction_factor_main') : t('goselect_loss_determination_details_correction_factor_main')}:
                </span><br />
                {stringArray.map((e: string, index: number) => (
                    <span key={index}>{t(e)} <br /></span>
                ))
                }
            </>


        )
    }
}

export default EfficiencyCorrectionFactorDetails