import React from 'react';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { replaceYearText } from '../../../utils/GeneralUtils';
import EfficiencyCorrectionFactorDetails, { EfficiencyCorrectionFactorNotes } from '../Efficiency/Components/EfficiencyCorrectionFactorDetails';

const LossDetermination = ({ application }: any) => {

  const { t } = useTranslate();

  return (
    <div className="report-page-loss-container">
      <div className="report-page-loss-subheader">
        {t(I18.efficiency_pds_loss_determination_header)}
      </div>
      <div className="report-page-loss-content">
        {t('efficiency_pds_loss_determination_with_constant')}
        <br />
        <br />
        <EfficiencyCorrectionFactorDetails componentName={'PDSEfficiency'} rowIds={application?.pdsEfficiencyDetails?.pdsDriveDetails?.rowIds} />
        <br />
      </div>

      <div className="report-page-loss-subheader">
        {t(I18.efficiency_cdm_loss_determination_header)}
      </div>
      <div>
        <div className="report-page-loss-content">
          <p>
            {replaceYearText(application?.pdsEfficiencyDetails?.pdsDriveDetails?.rowIds, t('efficiency_cdm_loss_determination_with_constant'))}
          </p>
          <p>{t(I18.efficiency_losses_drive_page_cdm_loss_heading)}</p>
          <ol>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_1)}
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_a)}
              <sub>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_a_sub)}
              </sub>{' '}
              ={' '}
              <strong>
                {application?.pdsEfficiencyDetails?.pdsDriveDetails?.inputFrequency
                  ? application?.pdsEfficiencyDetails?.pdsDriveDetails?.inputFrequency
                  : '-'}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_b)}
              <sub>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_b_sub)}
              </sub>
              =
              <strong>
                {application?.pdsEfficiencyDetails?.pdsDriveDetails?.inputVoltage
                  ? application?.pdsEfficiencyDetails?.pdsDriveDetails?.inputVoltage
                  : '-'}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_c)}
              <sub>
                {' '}
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_c_sub)}
              </sub>{' '}
              ={' '}
              <strong>
                {' '}
                {application?.pdsEfficiencyDetails?.pdsDriveDetails?.outputFrequency
                  ? application?.pdsEfficiencyDetails?.pdsDriveDetails?.outputFrequency
                  : '-'}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_d)}
              <sub>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_d_sub)}
              </sub>{' '}
              ={' '}
              <strong>
                {application?.pdsEfficiencyDetails?.pdsDriveDetails?.networkVoltage
                  ? application?.pdsEfficiencyDetails?.pdsDriveDetails?.networkVoltage
                  : '-'}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_e)}
              <sub>
                {t(I18.efficiency_losses_drive_page_cdm_loss_1_e_sub)}
              </sub>{' '}
              ={' '}
              <strong>
                {application?.pdsEfficiencyDetails?.pdsDriveDetails?.outputVoltage
                  ? application?.pdsEfficiencyDetails?.pdsDriveDetails?.outputVoltage
                  : '-'}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_f)}
              <strong>
                {application?.pdsEfficiencyDetails?.pdsDriveDetails?.dcLinkVoltage
                  ? application?.pdsEfficiencyDetails?.pdsDriveDetails?.dcLinkVoltage
                  : '-'}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_g)}
              <strong>
                {application?.pdsEfficiencyDetails?.pdsDriveDetails
                  ?.switchingFrequencyInverterSide
                  ? application?.pdsEfficiencyDetails?.pdsDriveDetails
                    ?.switchingFrequencyInverterSide
                  : '-'}
              </strong>
              <br />
              {t(I18.efficiency_losses_drive_page_cdm_loss_1_h)}
              <strong>
                {application?.pdsEfficiencyDetails?.pdsDriveDetails?.switchingFrequencySupplySide
                  ? application?.pdsEfficiencyDetails?.pdsDriveDetails
                    ?.switchingFrequencySupplySide
                  : '-'}
              </strong>
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_2)}
              <sub>n</sub>{' '}
              {t(I18.efficiency_losses_drive_page_cdm_loss_2_sub)}
              <sub>N</sub> * U​<sub>1,out</sub>
              <br />
            </li>
            <li>
              {' '}
              {t(I18.efficiency_losses_drive_page_cdm_loss_3)} <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_4)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_5)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_6)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_7)}
              <br />
            </li>
            <li>
              {t(I18.efficiency_losses_drive_page_cdm_loss_8)}P
              <sub>output, CDM</sub> / ( P <sub>​output, CDM</sub> + P{' '}
              <sub>​losses, CDM</sub>). P <sub>​output, CDM</sub> is
              {' ' +
                t(
                  I18.efficiency_losses_drive_page_cdm_loss_8_output,
                )}{' '}
              <sub>​losses, CDM</sub>{' '}
              {t(
                I18.efficiency_losses_drive_page_cdm_loss_8_output_end,
              )}
              <br />
            </li>
            {
              application?.pdsEfficiencyDetails?.pdsDriveDetails?.rowIds && (<li>
                <EfficiencyCorrectionFactorDetails rowIds={application?.pdsEfficiencyDetails?.pdsDriveDetails?.rowIds} />
              </li>)
            }


          </ol>
          <p>
            {t(I18.efficiency_losses_drive_page_cdm_loss_conclusion)}
          </p>
          <EfficiencyCorrectionFactorNotes rowIds={application?.pdsEfficiencyDetails?.pdsDriveDetails?.rowIds} />
        </div>
      </div>

      <div className="report-page-loss-subheader">
        {t(I18.efficiency_losses_motor_page_loss_determination_heading)}
      </div>
      <div>
        <div className="report-page-loss-content">
          {t(I18.efficiency_losses_motor_page_loss_determination)}
          <br />
          {t(I18.efficiency_losses_motor_page_loss_determination_sub)}
        </div>
      </div>

      <div className="report-page-loss-subheader">
        {t(I18.efficiency_losses_motor_page_disclaimer_heading)}
      </div>
      <div>
        <div className="report-page-loss-content">
          {t(I18.efficiency_losses_page_disclaimer)}
        </div>
      </div>
    </div>
  );
};

export default LossDetermination;
