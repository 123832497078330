import { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import Tag from '../../../../components/Tag';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Radio from '../../../../components/Form/Radio';
import Button from '../../../../components/Form/Button';
import { useTranslate } from '../../../../i18translate/Hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPlusCodeOptionValues
} from '../../store/WorkspaceReducer';
import { RootState } from '../../../../store/rootReducer';

interface ICopyPlusCodeModal {
  modalTitle: string;
  pluscodeOptions: any;
  optionSelected: (value: string) => void;
  onClose: () => void;
  modalOpen: boolean;
  copiedoptiontype?: string;

  nextButtonHandler: () => void;
}

const radioLabelObject: {
  [key: string]: { label: string; value: string; name: string };
} = {
  currentDrive: {
    label: 'Current drive',
    value: 'Current drive',
    name: 'Current drive',

  },
  similarDrives: {
    label: 'Similar drives',
    value: 'Similar drives',
    name: 'Similar drives',

  },
  allDrives: {
    label: 'All drives',
    name: 'All drives',
    value: 'All drives',

  },
  selectDrive: {
    label: 'Select drive',
    value: 'Select drive',
    name: 'Select drive',

  },
};

const CopyPlusCodeModal = ({
  modalTitle,
  pluscodeOptions,
  optionSelected,
  onClose,
  modalOpen,
  copiedoptiontype,
  nextButtonHandler
}: ICopyPlusCodeModal) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const application = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );
  const applications = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails
  );
  /*
  //to set the state with pluscode
  api need to be called 
  should check if they have selected any option and the option modal to be closed
  so we need to call the set state before caling the close of modal
  then the notification to be opened
  */

  const [copyOptionType, setCopyOptionType] = useState(radioLabelObject['currentDrive'].value);
  const [radioTypeArray, setAadioTypeArray] = useState(radioLabelObject);
  const [showAll, setShowAll] = useState(false)
  const handleCopyButton = () => {
    if (copyOptionType.length > 0) {
      optionSelected(copyOptionType);
      onClose();
    }
  };



  const plusCodeTags = () => {

    const initialOptions = 14
    const displayedOptions = showAll ? pluscodeOptions.options : pluscodeOptions?.options.slice(0, initialOptions)

    return <div className='copy-tag-container'>

      {
        displayedOptions?.map((pluscode: { plusCode: string }, index: number) => {
          return (
            <div className="copied-results-breadcrumb-container " key={index}>
              <Tag
                className="copied-results-breadcrumb"
                deleteButton={false}
                type="primary-black"
                key={pluscode?.plusCode}

              >
                <div slot="tag">{'+'}{pluscode?.plusCode}</div>
              </Tag>
            </div>
          );
        })
      }
      {pluscodeOptions?.options?.length > 15 && <Button onClick={() => setShowAll(!showAll)}>{!showAll ? `+${pluscodeOptions?.options.length - initialOptions} more` : 'show less'}</Button>}
    </div>

  };
  return (
    <Modal
      onModalClose={() => {
        if (
          copyOptionType.length === 0 &&
          application.drivePlusCodeObject?.options && application.drivePlusCodeObject?.options.length === 0
        ) {

          dispatch(setPlusCodeOptionValues([]));
          onClose()
        }
      }}
      open={modalOpen}
      backdropClick={false}
      modalTitle={modalTitle}
      showCloseButton={true}
      type="discreet"
      showHederSeparator="true"
      className="copy-and-delete-plus-codes-modal"
    >
      <div className="copy-delete-modal-container" slot="content">
        <p>{pluscodeOptions?.length || pluscodeOptions?.options?.length} pluscodes selected</p>

        {plusCodeTags()}

        <div className="copy-delete-radio-container">
          <RadioGroup
            onRadioChange={(e) => setCopyOptionType(e.target.value)}
            className="radio-group"
            size="small"
            value={copyOptionType}
          >
            {Object.keys(radioTypeArray).map((key) => {
              const { label, value, name } = radioLabelObject[key];
              return (
                <div slot="radio">
                  <Radio value={value} name={name} type="primary-black" disabled={applications?.length === 1 && value !== 'Current drive'}>
                    <span slot="label">{label}</span>
                  </Radio>
                </div>
              );
            })}
          </RadioGroup>
        </div>
      </div>
      <div className="copy-delete-plus-code-footer" slot="footer">
        <Button type="discreet-black" size="small" onClick={onClose}>
          {t('cancel_button')}
        </Button>
        {(copyOptionType !== 'Select drive') && (
          <Button type="primary-black" size="small" onClick={handleCopyButton} >
            {t('copy_button')}
          </Button>
        )}

        {(copyOptionType === 'Select drive') && (
          <Button type="primary-black" size="small" onClick={nextButtonHandler} >
            Next
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default CopyPlusCodeModal;
