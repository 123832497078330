import { CommonuxColumnSpan } from '@abb-motion-ux/commonux-web-components-react';
import ToggleSwitch from '../../../../components/Form/ToggleSwitch';
import TabGroup from '../../../../components/TabGroup';
import TabItem from '../../../../components/TabItem';
import TabContent from '../../../../components/TabContent';
import Button from '../../../../components/Form/Button';
import {
  useGetAllSelectionDataQuery,
  useGetDrivesQuery,
  useGetMotorsQuery,
} from '../../services/APISelectionService';
import { RootState } from '../../../../store/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import CommonLoader from '../../../../components/Loader';
import { Dispatch, SetStateAction, useEffect } from 'react';
import Icon from '../../../../components/Icon';
import RecommendedProducts from './RecommendedProducts';
import {
  setAllDrivesData,
  setAllMotorsData,
  setCurrentDrive,
  setCurrentMotor,
  setCurrentTab,
  setDriveData,
  setDriveMotivators,
  setMotorData,
  setMotorMotivators,
} from '../../store/WorkspaceReducer';
interface SelectionResultProps {
  setIsResultPageLoading: (arg0: boolean) => void;
}

const SelectionResult = (props: SelectionResultProps) => {
  const selectionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionDetails;
  });

  const selectionResults = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionResults;
  });

  const dispatch = useDispatch();

  const { data, isLoading: isPageLoading } = useGetAllSelectionDataQuery({
    projectId: sessionStorage.getItem('id') ?? '',
    selectionData,
  });

  const { data: MotorUpdatedData, isFetching: motorUpdateLoading } =
    useGetMotorsQuery(
      {
        projectId: sessionStorage.getItem('id') ?? '',
        selectionData: Object.assign(
          {},
          selectionData,
          selectionResults.motorMotivators &&
            selectionResults.motorMotivators.length > 0
            ? {
                motivatorIds: selectionResults.motorMotivators,
              }
            : {},
        ),
      },
      {
        skip: isPageLoading || selectionResults.motorMotivators === undefined,
      },
    );

  const { data: DriveUpdatedData, isFetching: driveUpdateLoading } =
    useGetDrivesQuery(
      {
        projectId: sessionStorage.getItem('id') ?? '',
        selectionData: Object.assign(
          {},
          selectionData,
          selectionResults.driveMotivators &&
            selectionResults.driveMotivators.length > 0
            ? {
                motivatorIds: selectionResults.driveMotivators,
                motorId: selectionResults.currentMotor?.id,
              }
            : { motorId: selectionResults.currentMotor?.id },
        ),
      },
      {
        skip:
          motorUpdateLoading || selectionResults.driveMotivators === undefined,
      },
    );

  useEffect(() => {
    if (data) {
      dispatch(setAllMotorsData(data?.motorData));
      dispatch(setAllDrivesData(data?.driveData));
    }
  }, [data]);

  useEffect(() => {
    if (!driveUpdateLoading && DriveUpdatedData) {
      dispatch(setAllDrivesData(DriveUpdatedData));
    }
  }, [DriveUpdatedData, driveUpdateLoading]);

  useEffect(() => {
    if (!motorUpdateLoading && MotorUpdatedData) {
      dispatch(setAllMotorsData(MotorUpdatedData));
    }
  }, [MotorUpdatedData, motorUpdateLoading]);

  useEffect(() => {
    dispatch(setCurrentTab('recommendMotors'));
  }, []);

  useEffect(() => {
    if (selectionResults.allMotorsData?.length > 0) {
      dispatch(setCurrentMotor(selectionResults.allMotorsData?.[0]));
    }
  }, [selectionResults.allMotorsData]);

  useEffect(() => {
    if (selectionResults.allDrivesData?.length > 0) {
      dispatch(setCurrentDrive(selectionResults.allDrivesData?.[0]));
    }
  }, [selectionResults.allDrivesData]);

  const scrollInto = (elementId: string): void => {
    const element = document.getElementById(elementId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollInto('industry-selection-header');
    if (selectionResults.currentMotor !== undefined) {
      dispatch(setMotorData(selectionResults.currentMotor));
    }
    selectionResults.currentDrive &&
      dispatch(setDriveData(selectionResults.currentDrive));
  }, [selectionResults.currentMotor, selectionResults.currentDrive]);

  useEffect(() => {
    props.setIsResultPageLoading(
      isPageLoading || motorUpdateLoading || driveUpdateLoading,
    );
  });

  const handleMotorMotivatorToggle = (
    motivatorId: string,
    linkedMotivatorId: string,
    isEnbaled: boolean,
    productType: string,
  ) => {
    if (isEnbaled !== undefined && motivatorId) {
      const isMotorMotivator = productType === 'motor';
      let currentMotorMotivators = selectionResults.motorMotivators
        ? [...selectionResults.motorMotivators]
        : isMotorMotivator
          ? []
          : undefined;
      let currentDriveMotivators = selectionResults.driveMotivators
        ? [...selectionResults.driveMotivators]
        : !isMotorMotivator
          ? []
          : undefined;

      if (isEnbaled === true) {
        if (linkedMotivatorId) {
          if (isMotorMotivator) {
            if (currentDriveMotivators) {
              currentDriveMotivators?.push(linkedMotivatorId);
            } else {
              currentDriveMotivators = [linkedMotivatorId];
            }
          } else {
            if (currentMotorMotivators) {
              currentMotorMotivators?.push(linkedMotivatorId);
            } else {
              currentMotorMotivators = [linkedMotivatorId];
            }
          }
        }
        isMotorMotivator
          ? currentMotorMotivators?.push(motivatorId)
          : currentDriveMotivators?.push(motivatorId);
      } else if (isEnbaled === false) {
        if (linkedMotivatorId) {
          if (isMotorMotivator) {
            currentDriveMotivators = currentDriveMotivators?.filter(
              (item) => item !== linkedMotivatorId,
            );
          } else {
            currentMotorMotivators = currentMotorMotivators?.filter(
              (item) => item !== linkedMotivatorId,
            );
          }
        }
        if (isMotorMotivator) {
          currentMotorMotivators = currentMotorMotivators?.filter(
            (item) => item !== motivatorId,
          );
        } else {
          currentDriveMotivators = currentDriveMotivators?.filter(
            (item) => item !== motivatorId,
          );
        }
      }
      dispatch(setMotorMotivators(currentMotorMotivators));
      dispatch(setDriveMotivators(currentDriveMotivators));
    }
  };

  const handleProductClick = (productType: string) => {
    if (productType === 'motor') {
      dispatch(setCurrentTab('recommendMotors'));
    } else if (productType === 'drive') {
      dispatch(setCurrentTab('recommendDrives'));
    }
    scrollInto('recommendMotors');
  };

  if (isPageLoading) {
    return (
      <CommonLoader
        size="xlarge"
        indicatorType={'circular'}
        progressType={'indeterminate'}
        progressValue={50}
        type={'primary-black'}
        dataTestID="selectionResultloader"
      />
    );
  }

  return (
    <div>
      <span
        id="industry-selection-header"
        className="industry-selection-header"
        data-rf="recommendations-header"
      >
        Our recommendation to you
      </span>

      <RecommendedProducts
        handleProductClick={handleProductClick}
        currentDrive={selectionResults.currentDrive}
        currentMotor={selectionResults.currentMotor}
      />

      <div className="device-recommendation-container">
        <div className="feature-selection-container">
          <div
            id="product-feature-header"
            className="product-feature-header"
            data-rf="motor-motivators-header"
          >
            Motor features
          </div>

          {/* Motors */}
          <div className="device-motivators-container">
            {data?.motorMotivatorData.map((motivator: any) => {
              return (
                <CommonuxColumnSpan
                  key={motivator.id}
                  id={motivator.id}
                  data-rf={motivator.name}
                  isContentExpanded={false}
                  showCaret={true}
                  borderBottom={true}
                  value={motivator.name}
                  size={
                    data?.motorMotivatorData.length <
                    (data?.motorMotivatorData?.length ?? 6)
                      ? 'large'
                      : 'medium'
                  }
                >
                  <div slot="label">{motivator.name}</div>
                  <div slot="icons-container">
                    <ToggleSwitch
                      data-rf={motivator.id + '-motor-motivator-switch'}
                      size="medium"
                      disabled={motorUpdateLoading || driveUpdateLoading}
                      showicon={true}
                      checked={selectionResults.motorMotivators?.includes(
                        motivator.id,
                      )}
                      onToggleChange={(event) => {
                        handleMotorMotivatorToggle(
                          motivator.id,
                          motivator.linkedMotivatorId,
                          event?.detail.value,
                          'motor',
                        );
                      }}
                    />
                  </div>
                  <div
                    slot="contentExpanded"
                    className="product-feature-description"
                  >
                    {motivator.description}
                  </div>
                </CommonuxColumnSpan>
              );
            })}
          </div>
        </div>

        {/* Drives */}
        <div className="feature-selection-container">
          <div
            className="product-feature-header"
            data-rf="drive-motivators-header"
          >
            Drive features
          </div>
          <div className="device-motivators-container">
            {data?.driveMotivatorData?.map((motivator: any) => {
              return (
                <CommonuxColumnSpan
                  key={motivator.id}
                  id={motivator.id}
                  data-rf={motivator.name}
                  isContentExpanded={false}
                  showCaret={true}
                  borderBottom={true}
                  value={motivator.name}
                  size={
                    data?.driveMotivatorData.length < 6 ? 'large' : 'medium'
                  }
                  aria-disabled={motorUpdateLoading}
                >
                  <div slot="label">{motivator.name}</div>
                  <div slot="icons-container">
                    <ToggleSwitch
                      data-rf={motivator.id + '-drive-motivator-switch'}
                      disabled={motorUpdateLoading || driveUpdateLoading}
                      checked={selectionResults.driveMotivators?.includes(
                        motivator.id,
                      )}
                      showicon={true}
                      size="medium"
                      onToggleChange={(event) => {
                        handleMotorMotivatorToggle(
                          motivator.id,
                          motivator.linkedMotivatorId,
                          event?.detail.value,
                          'drive',
                        );
                      }}
                    />
                  </div>
                  <div
                    slot="contentExpanded"
                    className="product-feature-description"
                  >
                    {motivator.description}
                  </div>
                </CommonuxColumnSpan>
              );
            })}
          </div>
        </div>
      </div>

      <div className="device-recommendation-container product-recommendations">
        <div
          className="product-feature-header"
          data-rf="product-feature-header"
        >
          Alternatives for the selected products
        </div>

        <div>
          <TabGroup
            id="recommendations"
            selectedTabId={selectionResults.currentTab}
            size="large"
          >
            <TabItem
              label={'Other suitable motors'}
              closable={false}
              tabId="recommendMotors"
              data-rf="recommendation-motor-tab"
              data-testid="recommendation-motor-tab"
              onClick={() => dispatch(setCurrentTab('recommendMotors'))}
            />
            <TabItem
              label={'Other suitable drives'}
              closable={false}
              tabId="recommendDrives"
              data-rf="recommendation-drive-tab"
              data-testid="recommendation-drive-tab"
              onClick={() => dispatch(setCurrentTab('recommendDrives'))}
            />
            <div slot="container">
              <TabContent containerId={'recommendMotors'}>
                <div
                  className="product-recommendations-container"
                  data-rf="product-recommendations-container"
                >
                  {selectionResults.allMotorsData?.map(
                    (motor: any, idx: any) => {
                      const isMotorSelected =
                        selectionResults.currentMotor === motor;
                      return (
                        <div
                          key={motor.id}
                          id={motor.id}
                          className={`product-data-container ${
                            isMotorSelected ? 'selected-product' : ''
                          }`}
                          data-rf={motor.id + '-motor-recommendation'}
                          aria-disabled={isMotorSelected}
                        >
                          {idx === 0 && (
                            <div
                              className="product-data-recommended-label"
                              data-rf="motor-recommended-label"
                            >
                              Recommended
                            </div>
                          )}
                          <div
                            className="product-data-image-container"
                            data-rf="motor-image"
                          >
                            <img
                              className="product-data-image"
                              src={motor.image?.url}
                              alt={motor.image?.fileName}
                            />
                          </div>

                          <div
                            className="product-data-header"
                            data-rf="motor-name-header"
                          >
                            {motor.name}
                          </div>

                          <div className="product-data-select-button-container">
                            <Button
                              type="primary-black"
                              size="small"
                              className="open-button"
                              data-rf="motor-select-button"
                              onBtnClick={() => {
                                dispatch(setCurrentMotor(motor));
                              }}
                              disabled={
                                isMotorSelected ||
                                motorUpdateLoading ||
                                driveUpdateLoading
                              }
                            >
                              <span>
                                {isMotorSelected ? 'Selected' : 'Select'}
                              </span>
                            </Button>
                          </div>

                          <div className="product-data-info-container">
                            <div
                              className="product-data-info"
                              data-rf="motor-main-value-proposition-label"
                            >
                              {motor.mainValueProposition}
                            </div>

                            <div
                              className="product-data-info"
                              data-rf="motor-frame-material-label"
                            >
                              {motor.frameMaterial}
                            </div>

                            <div
                              className="product-data-info"
                              data-rf="motor-efficiency-class-label"
                            >
                              {motor.efficiencyClass}
                            </div>

                            <div
                              className="product-data-info"
                              data-rf="motor-power-range-label"
                            >
                              {motor.powerRange}
                            </div>
                          </div>

                          <div className="product-data-urls-container">
                            <div>
                              <Button
                                type="discreet-black"
                                href={motor.catalogUrl}
                                target="_blank"
                                data-rf="motor-view-catalog-button"
                              >
                                <div slot="icon">
                                  <Icon name="download" size="small" />
                                </div>
                                <span>View Catalog</span>
                              </Button>
                            </div>

                            <div>
                              <Button
                                type="discreet-black"
                                href={motor.pageUrl}
                                target="_blank"
                                data-rf="motor-product-page-button"
                              >
                                <div slot="icon">
                                  <Icon
                                    name="open-in-new-window"
                                    size="small"
                                  />
                                </div>
                                <span>Open product page</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              </TabContent>
              <TabContent containerId={'recommendDrives'}>
                <div
                  className="product-recommendations-container"
                  data-rf="drive-recommendations-container"
                >
                  {selectionResults.allDrivesData?.map(
                    (drive: any, idx: any) => {
                      const isDriveSelected =
                        selectionResults.currentDrive === drive;
                      return (
                        <div
                          key={drive.id}
                          id={drive.id}
                          className={`product-data-container ${
                            isDriveSelected ? 'selected-product' : ''
                          }`}
                          data-rf={drive.id + '-drive-recommendation'}
                          aria-disabled={isDriveSelected}
                        >
                          {idx === 0 && (
                            <div
                              className="product-data-recommended-label"
                              data-rf="drive-recommended-label"
                            >
                              Recommended
                            </div>
                          )}
                          <div
                            className="product-data-image-container"
                            data-rf="drive-image"
                          >
                            <img
                              className="product-data-image"
                              src={drive.image?.url}
                              alt={drive.image?.fileName}
                            />
                          </div>

                          <div
                            className="product-data-header"
                            data-rf="drive-name-header"
                          >
                            {drive.name}
                          </div>

                          <div className="product-data-select-button-container">
                            <Button
                              type="primary-black"
                              size="small"
                              className="open-button"
                              data-rf="drive-select-button"
                              onBtnClick={() => {
                                dispatch(setCurrentDrive(drive));
                              }}
                              disabled={
                                isDriveSelected ||
                                motorUpdateLoading ||
                                driveUpdateLoading
                              }
                            >
                              <span>
                                {isDriveSelected ? 'Selected' : 'Select'}
                              </span>
                            </Button>
                          </div>

                          <div className="product-data-info-container">
                            <div
                              className="product-data-info"
                              data-rf="drive-main-value-proposition-label"
                            >
                              {drive.mainValueProposition}
                            </div>

                            <div
                              className="product-data-info"
                              data-rf="drive-frame-material-label"
                            >
                              {drive.motorControl}
                            </div>

                            <div
                              className="product-data-info"
                              data-rf="drive-efficiency-class-label"
                            >
                              {drive.mounting}
                            </div>

                            <div
                              className="product-data-info"
                              data-rf="drive-power-range-label"
                            >
                              {drive.degreeOfProtection}
                            </div>
                          </div>

                          <div className="product-data-urls-container">
                            <div>
                              <Button
                                type="discreet-black"
                                href={drive.catalog}
                                target="_blank"
                                data-rf="drive-view-catalog-button"
                              >
                                <div slot="icon">
                                  <Icon name="download" size="small" />
                                </div>
                                <span>View Catalog</span>
                              </Button>
                            </div>

                            <div>
                              <Button
                                type="discreet-black"
                                href={drive.hardwareManualUrl}
                                target="_blank"
                                data-rf="drive-product-page-button"
                              >
                                <div slot="icon">
                                  <Icon
                                    name="open-in-new-window"
                                    size="small"
                                  />
                                </div>
                                <span>Open product page</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              </TabContent>
            </div>
          </TabGroup>
        </div>
      </div>
    </div>
  );
};

export default SelectionResult;
