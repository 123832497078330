import Card from '../../components/Card';
import Icon from '../../components/Icon';

interface GenericModalProps {
  setFeedbackType: (type: number | null) => void;
}

const GenericModal = ({ setFeedbackType }: GenericModalProps) => {
  return (
    <div className="feedback-modal-text" slot="content">
      Classify your feedback
      <div className="feedback-modal-button-section">
        <Card
          type="outlined"
          size="larger-padding"
          onCardClick={() => setFeedbackType(1)}
        >
          <div slot="header">
            <Icon name="comment" size="large" />
          </div>
          <div slot="contents">
            <span>General feedback</span>
          </div>
        </Card>
        <Card
          type="outlined"
          size="larger-padding"
          onCardClick={() => setFeedbackType(2)}
        >
          <div slot="header">
            <Icon name="pin" size="large" />
          </div>
          <div slot="contents">
            <span>Report an issue</span>
          </div>
        </Card>
        <Card
          type="outlined"
          size="larger-padding"
          onCardClick={() => setFeedbackType(3)}
        >
          <div slot="header">
            <Icon name="chat" size="large" />
          </div>
          <div slot="contents">
            <span>Feature suggestion</span>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default GenericModal;
