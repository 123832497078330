import { ApexOptions } from 'apexcharts';
import Button from '../../../../components/Form/Button';
import Modal from '../../../../components/Modal';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import {
  dimensionCurrentGraphOptions,
  dimensionExpandedGraphOptions,
  dimensionTorqueGraphOptions,
} from '../../../../utils/GraphDefaultData';
import ReactApexCharts from 'react-apexcharts';
import { useEffect, useState } from 'react';
import TabGroup from '../../../../components/TabGroup';
import TabItem from '../../../../components/TabItem';
import { ResultGraphModalDriveProps } from '../models/DriveSelectionModels';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Radio from '../../../../components/Form/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { setShowPowerCurve } from '../../store/WorkspaceReducer';

const ResultGraphModalDrive = ({
  modalOpen,
  onClose,
  onExportClick,
  currentCurve,
  powerCurve,
  torqueCurve,
}: ResultGraphModalDriveProps) => {
  const { t } = useTranslate();
  const [optionsRef, setOptionsRef] = useState<ApexOptions | null>(null);
  const [selectedSubTab, setSelectedSubTab] = useState('DrivInfoTab');
  const handleExportClick = () => {
    onExportClick();
  };
  const dispatch = useDispatch();

  const showPowerCurve = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .dimensionDetails?.showPowerCurve;
  });

  useEffect(() => {
    setOptionsRef(
      showPowerCurve
        ? dimensionExpandedGraphOptions
        : dimensionTorqueGraphOptions,
    );
  }, [showPowerCurve]);

  useEffect(() => {
    setOptionsRef(
      showPowerCurve
        ? dimensionExpandedGraphOptions
        : dimensionTorqueGraphOptions,
    );
  }, [showPowerCurve]);

  const handleRadioButtonClick = (event: any) => {
    if (event.detail.value === 'power') {
      dispatch(setShowPowerCurve(true));
    } else {
      dispatch(setShowPowerCurve(false));
    }
  };

  return (
    <Modal
      open={modalOpen}
      onModalClose={onClose}
      modalTitle={'Graph'}
      showCloseButton={true}
      type="default"
      showHederSeparator="true"
      backdropClick={false}
      className="expand-result-graph-modal"
      showFooterSeparator={true}
    >
      <div
        id="expand-result-graph-modal-content"
        className="expand-result-graph-modal-content"
        slot="content"
      >
        <div className="toggle-button-container">
          <TabGroup
            selectedTabId={selectedSubTab}
            size="small"
            className="result-tabs"
          >
            <TabItem
              label={t(I18.dimensioning_tab_motor_title)}
              closable={false}
              tabId="MotorInfoTab"
              icon={'motor-16'}
              onTabClick={() => {
                setSelectedSubTab('MotorInfoTab');
              }}
            ></TabItem>
            <TabItem
              label={t(I18.dimensioning_tab_drive_title)}
              closable={false}
              tabId="DrivInfoTab"
              icon="drive-16"
              onTabClick={() => {
                setSelectedSubTab('DrivInfoTab');
              }}
            ></TabItem>
          </TabGroup>
        </div>
        {selectedSubTab === 'MotorInfoTab' && (
          <RadioGroup
            allowEmptySelection={false}
            onRadioChange={(event) => {
              handleRadioButtonClick(event);
            }}
            value={showPowerCurve ? 'power' : 'torque'}
            size="small"
          >
            <div slot="radio">
              <Radio className="selection-radio" name="torque" value="torque">
                <label slot="label">Torque Curves</label>
              </Radio>
              <Radio className="selection-radio" name="power" value="power">
                <label slot="label">Power Curves</label>
              </Radio>
            </div>

          </RadioGroup>
        )}
        {((optionsRef && currentCurve) ||
          (optionsRef && powerCurve && torqueCurve)) && (
            <div className="result-graph-container">
              {selectedSubTab === 'DrivInfoTab' ? (
                <ReactApexCharts
                  options={Object.assign({}, dimensionCurrentGraphOptions, {
                    xaxis: {
                      min: 0,
                      max: parseFloat(
                        currentCurve?.speedContLoad?.[
                          currentCurve?.speedContLoad?.length - 2
                        ][0].toString() ?? '0',
                      ),
                      tickAmount: 3,
                      title: {
                        text: 'Speed [rpm]',
                        style: {
                          fontSize: '14px',
                          fontWeight: 400,
                        },
                      },
                    },
                  })}
                  series={[
                    {
                      name: 'continuous load',
                      data: currentCurve?.speedContLoad?.slice(0, -1) ?? [],
                    },
                    {
                      name: 'continuous loadability',
                      data:
                        currentCurve?.speedContLoadability?.slice(0, -1) ?? [],
                    },
                    {
                      name: 'max. load',
                      data: currentCurve?.speedMaxLoad?.slice(0, -1) ?? [],
                    },
                    {
                      name: 'max. loadability',
                      data: currentCurve?.speedMaxLoadability?.slice(0, -1) ?? [],
                    },
                  ]}
                  type={'line'}
                  height={optionsRef?.chart?.height ?? 100}
                />
              ) : (
                <ReactApexCharts
                  options={Object.assign({}, optionsRef, {
                    xaxis: {
                      min: 0,
                      max: parseFloat(
                        powerCurve?.speedContLoad?.[
                          powerCurve?.speedContLoad?.length - 1
                        ][0].toString() ?? '0',
                      ),
                      tickAmount: 3,
                      title: {
                        text: 'Speed [rpm]',
                        style: {
                          fontSize: '14px',
                          fontWeight: 400,
                        },
                      },
                    },
                  })}
                  series={[
                    {
                      name: 'continuous load',
                      data: showPowerCurve
                        ? (powerCurve?.speedContLoad ?? [])
                        : (torqueCurve?.speedContLoad ?? []),
                    },
                    {
                      name: 'continuous loadability',
                      data: showPowerCurve
                        ? (powerCurve?.speedContLoadability ?? [])
                        : (torqueCurve?.speedContLoadability ?? []),
                    },
                    {
                      name: 'max. load',
                      data: showPowerCurve
                        ? (powerCurve?.speedMaxLoad ?? [])
                        : (torqueCurve?.speedMaxLoad ?? []),
                    },
                    {
                      name: 'max. loadability',
                      data: showPowerCurve
                        ? (powerCurve?.speedMaxLoadability ?? [])
                        : (torqueCurve?.speedMaxLoadability ?? []),
                    },
                  ]}
                  type={'line'}
                  height={optionsRef?.chart?.height ?? 100}
                />
              )}
            </div>
          )}
      </div>
      <div className="report-graph-modal-footer" slot="footer">
        <Button
          type="secondary"
          size="small"
          onClick={onClose}
          className="create-project-cancel-button"
        >
          {t(I18.motor_drive_selection_modal_cancel_button)}
        </Button>
      </div>
    </Modal>
  );
};

export default ResultGraphModalDrive;
