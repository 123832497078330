import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  PlusCodesObject,
  UpdatePlusCodeObject,
} from '../models/DrivePlusCodeModel';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_REDIS_CACHE_URL,
  credentials: 'same-origin',
  mode: 'cors',
});

const baseQuery1 = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_LV_DRIVE_VALIDATION_URL,
  credentials: 'same-origin',
  mode: 'cors',
});

export const drivepluscodeApi = createApi({
  reducerPath: 'drivepluscodeAPI',
  baseQuery,
  endpoints: (builder) => ({
    getpluscodeById: builder.query<PlusCodesObject | undefined, string>({
      query: (applicationId: string) => {
        return {
          url: `/getpluscodesfromcache?appId=${applicationId}`,
          method: 'GET',
        };
      },
    }),
    postPlusCodeDataById: builder.mutation<any, any>({
      query: (body: UpdatePlusCodeObject) => {
        return {
          url: `/getpluscodesdata?appId=${body.applicationId}`,
          method: 'POST',
          body: body.updatePlusCodes,
        };
      },
    }),
  }),
});

export const driveplsuCodeValidationAPI = createApi({
  reducerPath: 'driveplsuCodeValidationAPI',
  baseQuery: baseQuery1,
  endpoints: (builder) => ({
    validatePlsucodeByID: builder.mutation<any, any>({
      query: (body: any) => {
        console.log('body', body);
        console.log(
          'process.env.REACT_APP_CLIENT_ID',
          process.env.REACT_APP_VALIDATION_API_CLIENTID,
        );
        console.log(
          'process.env.REACT_APP_CLIENT_SECRET',
          process.env.REACT_APP_VALIDATION_API_CLIENTSECRET,
        );
        return {
          url: `validation`,
          method: 'POST',
          headers: {
            client_id: process.env.REACT_APP_VALIDATION_API_CLIENTID,
            client_secret: process.env.REACT_APP_VALIDATION_API_CLIENTSECRET,
          },
          body,
        };
      },
    }),
  }),
});

export const { useValidatePlsucodeByIDMutation } = driveplsuCodeValidationAPI;

export const { useGetpluscodeByIdQuery, usePostPlusCodeDataByIdMutation } =
  drivepluscodeApi;
