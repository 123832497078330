import { PDSAbsoluteLossRange, PDSEfficiencyRange } from '../Utils/PDSCommon';
import '../Styles/LossesChart.scss';
import _ from 'lodash';
import { UserDefinedLoadPoints } from '../../models/EfficiencyModel';
import { D3 } from '../../../../components/D3';
import { renderToString } from 'react-dom/server';
import { EfficiencyUDPDetails } from '../../models/EfficiencyModel';
import { useEffect, useState } from 'react';
import { generateKey } from '../../../../utils/GeneralUtils';

const LOAD_SHAPE_COLOR = 'rgba(181, 170, 13, 1)';

interface ICoordinate {
  index?: number;
  x: number;
  y: number;
  efficiency?: string;
  efficiencyUnit?: string;
  absoluteLoss?: string;
  absoluteLossUnit?: string;
  shape?: string;
  tooltipContent?: string;
  pointLabel?: string;
  pointColor?: string;
  pointLabelFontSize?: string;
  customShape?: any;
}

const isWithinRange = (val?: any, min?: any, max?: any) => {
  const newVal = Number(val);
  if (
    isNaN(newVal) ||
    newVal === undefined ||
    newVal === null ||
    newVal > max ||
    newVal < min
  ) {
    return false;
  } else {
    return true;
  }
};

const getTooltip = (point: UserDefinedLoadPoints, shape: string) => {
  const ChartToolColorClass = 'diamond-narrow-tooltip';
  const ChartToolContentClass = 'diamond-tool-content';
  const absoluteLoss =
    point.data.absoluteLoss && point.data.absoluteLoss !== '-' ? (
      <span
        style={
          isWithinRange(
            point.data.absoluteLoss,
            PDSAbsoluteLossRange.Min,
            PDSAbsoluteLossRange.Max
          )
            ? {}
            : { color: '#F03040' }
        }
      >
        {point.data.absoluteLoss + point.data.absoluteLossUnit}
      </span>
    ) : (
      <>-</>
    );
  const efficiency =
    point.data.efficiency && point.data.efficiency !== '-' ? (
      <span
        style={
          isWithinRange(
            point.data.efficiency,
            PDSEfficiencyRange.Min,
            PDSEfficiencyRange.Max
          )
            ? {}
            : { color: '#F03040' }
        }
      >
        {point.data.efficiency + point.data.efficiencyUnit}
      </span>
    ) : (
      <>-</>
    );
  let yAxisLbl =
    point.torqueUnit === 'kW'
      ? 'Power ' + point.userInputTorque + ' ' + point.torqueUnit
      : 'Torque ' + point.userInputTorque + ' ' + point.torqueUnit;

  return `  
  <div  className= "diamond-narrow">
    <div class= ${ChartToolColorClass}></div>
    <div class="${ChartToolContentClass}">
      <div class="content1">Speed ${point.userInputSpeed + ' ' + point.speedUnit
    }; ${yAxisLbl}</div>	
      <div class="content2">Absolute losses ${renderToString(
      absoluteLoss
    )}</div>	
      <div class="content3">Efficiency  ${renderToString(efficiency)}</div>
    </div>
</div>`;
};

const getPointLabel = (point: UserDefinedLoadPoints) => {
  const efficiency =
    point.data.efficiency && point.data.efficiency !== '-'
      ? point.data.efficiency + point.data.efficiencyUnit
      : '-';
  return efficiency;
};

// Partload shape(Diamond)
const partLoadShape = {
  draw: function (context: any, size: any) {
    context.moveTo(0, -size / 11);
    context.lineTo(size / 11, 0);
    context.lineTo(0, size / 11);
    context.lineTo(-size / 11, 0);
    context.lineTo(0, -size / 11);
    context.closePath();
  },
};

const getArrayForRange = (
  maxValue: number | undefined,
  size: number,
  dividend: number
) => {
  const step = (maxValue ?? 1) / (size - 1);
  return Array.from({ length: size }, (_, index) => index * step + dividend);
};

const formatPointsForChart = (
  points: UserDefinedLoadPoints[],
  shape: string,
  type: string,
  count: number
): ICoordinate[] => {
  return points.map((point, index) => ({
    index: count + index + 1,
    shape,
    x: parseInt(point.userInputSpeed),
    y: parseInt(point.userInputTorque),
    efficiency: point.data.efficiency,
    efficiencyUnit:
      point.data.efficiency && point.data.efficiency !== '-'
        ? point.data.efficiencyUnit
        : '',
    absoluteLoss: point.data.absoluteLoss,
    absoluteLossUnit:
      point.data.absoluteLoss && point.data.absoluteLoss !== '-'
        ? point.data.absoluteLossUnit
        : '',
    tooltipContent: getTooltip(point, shape),
    pointLabel: getPointLabel(point),
    pointLabelFontSize: type === 'small' ? '10' : '14',
    pointColor: LOAD_SHAPE_COLOR,
    customShape: partLoadShape,
  }));
};

interface IUDPLossChart {
  partloadPoints?: UserDefinedLoadPoints[];
  loading?: boolean;
  type: 'small' | 'large';
  resultsLoading?: boolean;
  height: number;
  width?: number;
  UdpData: EfficiencyUDPDetails;
  id?: string;
}

const UDPLossesChart: React.FC<IUDPLossChart> = ({
  partloadPoints,
  loading,
  type,
  resultsLoading,
  height,
  width,
  UdpData,
  id,
}) => {
  const UDPLossesShape = 'diamond-narrow';

  const formattedPartloadPoints = partloadPoints
    ? formatPointsForChart(partloadPoints, UDPLossesShape, type, 0)
    : [];

  let coordinates = formattedPartloadPoints;
  const [speedAxis, setSpeedAxis] = useState(
    UdpData.speedUnit === '%'
      ? getArrayForRange(UdpData.maxSpeedPercentage, 11, 0)
      : getArrayForRange(UdpData.maxSpeedRpm, 11, 0)
  );
  const [torqueAxis, setTorqueAxis] = useState(
    UdpData.torqueUnit === '%'
      ? getArrayForRange(UdpData.maxTorquePercentage, 5, 0)
      : getArrayForRange(UdpData.maxTorqueNm, 5, 0)
  );
  const [powerAxis, setPowerAxis] = useState(
    getArrayForRange(UdpData.maxPower, 5, 0)
  );
  const [yAxislbl, setYAxislbl] = useState(
    UdpData.torqueOrPower === 'torque'
      ? 'Torque (' + UdpData.torqueUnit + ')'
      : 'Power (kW)'
  );
  const [darkGridLinesX, setDarkGridLinesX] = useState(
    UdpData.speedUnit === '%'
      ? generateArrayWithDividends(UdpData.maxSpeedPercentage)
      : generateArrayWithDividends(UdpData.maxSpeedRpm)
  );
  const [darkGridLinesY, setDarkGridLinesY] = useState(
    UdpData.torqueOrPower === 'torque'
      ? calculatePercentageArray(UdpData.maxTorquePercentage, 75)
      : calculatePercentageArray(UdpData.maxTorqueNm, 75)
  );

  useEffect(() => {
    setSpeedAxis(
      UdpData.speedUnit === '%'
        ? getArrayForRange(UdpData.maxSpeedPercentage, 11, 0)
        : getArrayForRange(UdpData.maxSpeedRpm, 11, 0)
    );
    setTorqueAxis(
      UdpData.torqueUnit === '%'
        ? getArrayForRange(UdpData.maxTorquePercentage, 5, 0)
        : getArrayForRange(UdpData.maxTorqueNm, 5, 0)
    );
    setPowerAxis(getArrayForRange(UdpData.maxPower, 5, 0));
    setYAxislbl(
      UdpData.torqueOrPower === 'torque'
        ? 'Torque (' + UdpData.torqueUnit + ')'
        : 'Power (kW)'
    );
    setDarkGridLinesX(
      UdpData.speedUnit === '%'
        ? generateArrayWithDividends(UdpData.maxSpeedPercentage)
        : generateArrayWithDividends(UdpData.maxSpeedRpm)
    );
    let darkYLine = [75];
    if (UdpData.torqueOrPower === 'torque') {
      if (UdpData.torqueUnit === '%') {
        darkYLine = generateArrayWithDividends(UdpData.maxTorquePercentage);
      } else {
        darkYLine = generateArrayWithDividends(UdpData.maxTorqueNm);
      }
    } else if (UdpData.torqueOrPower === 'power') {
      darkYLine = generateArrayWithDividends(UdpData.maxPower);
    }
    setDarkGridLinesY(darkYLine);
  }, [UdpData]);

  function calculatePercentageArray(
    number: number | undefined,
    percentage: number
  ) {
    const result = Math.round((percentage / 100) * (number ?? 1));
    return [result];
  }

  function generateArrayWithDividends(number: number | undefined) {
    const percentages = [25, 50, 100];
    return percentages.map((percent) =>
      Math.round((percent / 100) * (number ?? 1))
    );
  }

  return (
    <div className="scatter-chart-container">
      <div className="chart-top-section">
        <div className="chart-legends">
          <div>
            <img src={'/assets/icon/userdefinedpoints.svg'} alt="" />
            <p>User-defined Points</p>
          </div>
        </div>
      </div>
      {!loading && !resultsLoading && (
        <D3
          coordinates={coordinates ? coordinates : []}
          xAxisLabel={'Speed (' + UdpData.speedUnit + ')'} // Switched xAxisLabel and yAxisLabel
          yAxisLabel={yAxislbl} // Switched xAxisLabel and yAxisLabel
          height={height}
          width={width}
          id={id ? id + 'UDP-chart' : generateKey('UDP-chart')}
          isPDS={true}
          xAxisTicks={speedAxis}
          yAxisTicks={
            UdpData.torqueOrPower === 'torque' ? torqueAxis : powerAxis
          }
          darkGridLines={{
            xAxis: [], // make it as empty array to remvoe the vertical lines
            yAxis: darkGridLinesY,
          }}
          lightGridLines={{
            xAxis: speedAxis,
            yAxis: UdpData.torqueOrPower === 'torque' ? torqueAxis : powerAxis,
          }}
        />
      )}
    </div>
  );
};

export default UDPLossesChart;
