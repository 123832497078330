import { ApexOptions } from 'apexcharts';
import Button from '../../../../components/Form/Button';
import Radio from '../../../../components/Form/Radio';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import { generateKey } from '../../../../utils/GeneralUtils';
import {
  AddDutyCycleModalMotorProps,
  LoadDataMotorSelection,
} from '../models/DutyCycleModels';
import { useEffect, useRef, useState } from 'react';
import ApexChartGraph from '../../../../components/ApexChartGraph';
import { dutyCyclegraphOptions } from '../../../../utils/GraphDefaultData';
import { calculateCoordinates } from '../Utils/DutyCycleCalculateCoordinates';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveDutyCycleValuesMotor,
  saveRMSValues,
  saveTorqueGraphCoordinates,
  setHasMotorDutyCycleApplyClicked,
} from '../../store/WorkspaceReducer';
import { RootState } from '../../../../store/rootReducer';

const DutyCycleModalMotor = ({
  modalOpen,
  onClose,
  onApplyClick,
  baseLoadData,
  hasPreviousData,
}: AddDutyCycleModalMotorProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState('Torque');
  const [torqueValues, setTorqueValues] = useState<number[]>([]);
  const [powerValues, setPowerValues] = useState<number[]>([]);
  const [loadValues, setLoadValues] = useState<number[]>([]);
  const [timeValues, setTimeValues] = useState<number[]>([]);
  const [hasGraphDataChanged, setHasGraphDataChanged] =
    useState(hasPreviousData);
  const [hasMultipleOverload, setHasMultipleOverload] = useState(false);
  const [seriesData, setSeriesData] = useState([[0, 0]]);
  const [rows, setRows] = useState<LoadDataMotorSelection[]>([]);
  const [totalRMS, setTotalRMS] = useState(0);
  const [continuousLoad, setContinuousLoad] = useState(0);
  const [RMS10, setRMS10] = useState(0);
  const [powerOverload, setPowerOverload] = useState(0);
  const previousTorqueGraph = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.torqueGraphCoordinates,
  );

  useEffect(() => {
    const overload1Torque =
      ((baseLoadData[0]?.torque ?? 0) * baseLoadData[1]?.load) / 100;
    let torqueData: number[] = [];
    baseLoadData?.forEach((i) => {
      torqueData.push(i.torque ?? 0);
    });
    setTorqueValues(torqueData);

    let powerData: number[] = [];
    baseLoadData?.forEach((i) => {
      powerData.push(i.power ?? 0);
    });
    setPowerValues(powerData);

    let loadData: number[] = [];
    baseLoadData?.forEach((i) => {
      loadData.push(i.load);
    });
    setLoadValues(loadData);

    let timeData: number[] = [];
    baseLoadData?.forEach((i) => {
      timeData.push(i.time);
    });
    setTimeValues(timeData);

    if (previousTorqueGraph.length > 0) {
      setSeriesData(previousTorqueGraph);
    } else {
      setSeriesData([
        [0, baseLoadData[0]?.torque ?? 0],
        [baseLoadData[0]?.time, baseLoadData[0]?.torque ?? 0],
        [baseLoadData[0]?.time, overload1Torque],
        [baseLoadData[0]?.time + baseLoadData[1]?.time, overload1Torque],
        [
          baseLoadData[0]?.time + baseLoadData[1]?.load,
          baseLoadData[0]?.torque ?? 0,
        ],
      ]);
    }

    let tempRows: LoadDataMotorSelection[] = [];
    baseLoadData?.forEach((i, index) => {
      tempRows.push({
        rowName:
          index === 0
            ? t(I18.motor_drive_selection_modal_table_base_load_title)
            : t(I18.motor_drive_selection_modal_table_over_load_title) +
              ' ' +
              index,
        time: i?.time,
        minSpeed: i?.minSpeed,
        maxSpeed: i?.maxSpeed,
        load: i?.load,
        torque: i?.torque,
        power: i?.power,
      });
    });
    setRows(tempRows);
  }, [baseLoadData, t]);

  const calculatePower = (
    load: string,
    speed: string,
    nBase: string,
    pBase: string,
    loadType: string,
  ) => {
    if (speed === '0') {
      speed = '1';
    }
    let power = 0;
    let tmpSpeed = parseFloat(speed);
    let tmpNBase = parseFloat(nBase);
    let tmpLoad = parseFloat(load);
    let tmppBase = parseFloat(pBase);

    switch (loadType) {
      case '0': //Constant power
      case '1':
      case '2':
        if (tmpSpeed > 0 && tmpSpeed <= tmpNBase) {
          power = ((tmppBase * tmpLoad) / 100) * (tmpSpeed / tmpNBase);
        } else if (tmpSpeed > tmpNBase) {
          power = (tmppBase * tmpLoad) / 100;
        } else {
          power = 0;
        }
        break;
      case '3':
        if (tmpSpeed > 0 && tmpSpeed <= tmpNBase) {
          power =
            ((tmppBase * tmpLoad) / 100) * Math.pow(tmpSpeed / tmpNBase, 3);
        } else {
          power = 0;
        }
        break;
    }
    return power;
  };

  const calculatePowerFromLoad = (
    dutyCycleLoad: string,
    minSpeed: string,
    maxSpeed: string,
    nBase: string,
    pBaseInput: string,
    loadType: string,
  ) => {
    let power1 = 0;
    let power2 = 0;
    let power = 0;
    power1 = calculatePower(
      dutyCycleLoad,
      minSpeed,
      nBase,
      pBaseInput,
      loadType,
    );
    power2 = calculatePower(
      dutyCycleLoad,
      maxSpeed,
      nBase,
      pBaseInput,
      loadType,
    );

    power = power1 > 0 ? Math.max(power1, power2) : Math.min(power1, power2);
    power = parseFloat(power.toFixed(1));

    return power;
  };

  useEffect(() => {
    if (rows.length > 0 && rows[0].time > 0) {
      let unitLoadRms10 = 0;
      let unitLoadPowerRMS10 = 0;
      for (let i = 0; i <= rows.length - 1; i++) {
        let sum = 0;
        let sumOfpowRMS10 = 0;
        let point = i;
        let totalTime = 0;

        do {
          let currentRow = rows[point];
          totalTime = totalTime + currentRow.time;
          if (totalTime < 600) {
            let pn = 0;
            pn = calculatePowerFromLoad(
              currentRow.load?.toString(),
              currentRow.minSpeed?.toString(),
              currentRow.maxSpeed?.toString(),
              rows?.[0]?.maxSpeed?.toString(),
              rows?.[0]?.power?.toString() ?? '0',
              '1',
            );
            sum = sum + Math.pow(currentRow.load / 100, 2) * currentRow.time;
            sumOfpowRMS10 += Math.pow(pn ?? 0, 2) * currentRow.time;
          } else {
            let pn = 0;
            pn = calculatePowerFromLoad(
              currentRow.load.toString(),
              currentRow.minSpeed.toString(),
              currentRow.maxSpeed.toString(),
              rows?.[0]?.maxSpeed?.toString(),
              rows?.[0]?.power?.toString() ?? '0',
              '1',
            );
            sum =
              sum +
              Math.pow(currentRow.load / 100, 2) *
                (currentRow.time - (totalTime - 600));
            sumOfpowRMS10 +=
              Math.pow(pn ?? 0, 2) * (currentRow.time - (totalTime - 600));
            totalTime = 600;
          }
          //If total cycle time is shorter than 600 seconds and then cycle is calculated over
          //and over until 600 seconds is full
          if (point >= rows.length - 1) {
            point = -1;
          }
          point++;
        } while (totalTime < 600);

        if (
          totalTime === 600 &&
          Math.sqrt(sum / totalTime) > unitLoadRms10 / 100
        ) {
          unitLoadRms10 = parseFloat(
            (Math.sqrt(sum / totalTime) * 100).toFixed(1),
          );
        }

        if (
          totalTime === 600 &&
          Math.sqrt(sumOfpowRMS10 / totalTime) > unitLoadPowerRMS10
        ) {
          unitLoadPowerRMS10 = parseFloat(
            Math.sqrt(sumOfpowRMS10 / totalTime).toFixed(1),
          );
        }
        setContinuousLoad(unitLoadPowerRMS10);
      }
      setRMS10(unitLoadRms10);

      /* RMS */
      let sumRMS = 0;
      let totalRMS = 0;
      let totalTimeRMS = 0;
      for (let i = 0; i <= rows.length - 1; i++) {
        sumRMS = sumRMS + Math.pow(rows[i].load, 2) * rows[i].time;
        totalTimeRMS = totalTimeRMS + rows[i].time;
      }
      totalRMS = parseFloat(Math.sqrt(sumRMS / totalTimeRMS).toFixed(1));
      setTotalRMS(totalRMS);

      /*  RMS end */

      /*  PowerOverLoad */
      let overLoadRMS = 0;
      let powerOverload = 0;
      let pn = 0;
      let powerPeak = 0;
      for (let i = 0; i <= rows.length - 1; i++) {
        pn = calculatePowerFromLoad(
          rows[i].load.toString(),
          rows[i].minSpeed.toString(),
          rows[i].maxSpeed.toString(),
          rows?.[0]?.maxSpeed?.toString(),
          rows?.[0]?.power?.toString() ?? '0',
          '1',
        );

        if (Math.abs(pn) > powerPeak) {
          powerPeak = Math.abs(pn);
        }
      }
      if (unitLoadPowerRMS10 !== 0) {
        overLoadRMS = (Math.abs(powerPeak ?? 0) / unitLoadPowerRMS10) * 100;
        powerOverload = parseFloat(overLoadRMS.toFixed(1));
      }
      setPowerOverload(powerOverload);
      /*PowerOverLoad end */

      dispatch(
        saveRMSValues({
          RMS: unitLoadRms10.toString(),
          RMS10: unitLoadRms10.toString(),
          basePower: baseLoadData[0].power?.toString() ?? '',
          highestLoad: Math.max(...loadValues).toString(),
          continuousLoad: unitLoadPowerRMS10.toString(),
          powerOverload: powerOverload.toString(),
        }),
      );
    }
  }, [rows, baseLoadData]);

  const optionsRef = useRef<ApexOptions | null>(dutyCyclegraphOptions);
  useEffect(() => {
    if (hasGraphDataChanged) {
      optionsRef.current = {
        chart: {
          height: 283,
        },
        xaxis: {
          min: 0,
          max: 1800,
          tickAmount: 9,
          title: {
            text: 'Time [s]',
            style: {
              fontSize: '$font-size-s',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          min: 0,
          max:
            selectedValue === 'Torque'
              ? Math.round(Math.max(...torqueValues)) + 50
              : Math.round(Math.max(...powerValues)) + 20,
          tickAmount: 4,
          title: {
            text: selectedValue,
            style: {
              fontSize: '$font-size-s',
              fontWeight: 400,
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
          },
        },
      };
      setHasGraphDataChanged(false);
    }
  }, [
    selectedValue,
    baseLoadData,
    timeValues,
    torqueValues,
    seriesData,
    hasGraphDataChanged,
    powerValues,
  ]);

  const handleAddOverloadClick = () => {
    //max overload row count is 10
    if (rows.length < 11) {
      const newRow: LoadDataMotorSelection = {
        rowName:
          t(I18.motor_drive_selection_modal_table_over_load_title) +
          ' ' +
          rows.length,
        time: 0,
        minSpeed: 0,
        maxSpeed: 0,
        load: 0,
        torque: 0,
        power: 0,
      };
      setRows([...rows, newRow]);
    }
  };

  const handleRowDeleteClick = (rowNumber: number) => {
    rows.splice(rowNumber, 1);
    torqueValues.splice(rowNumber, 1);
    loadValues.splice(rowNumber, 1);
    timeValues.splice(rowNumber, 1);
    powerValues.splice(rowNumber, 1);
    if (rowNumber < rows.length) {
      rows?.forEach((i, index) => {
        if (index >= rowNumber) {
          i.rowName = 'Overload ' + index.toString();
        }
      });
    }
    setHasGraphDataChanged(true);

    // row 0 & row 1 cannot be deleted. If Row 2 is being deleted, then we do not have multiple overload.
    if (rowNumber < 3) {
      setHasMultipleOverload(false);
    }
  };

  useEffect(() => {
    if (hasGraphDataChanged || hasPreviousData) {
      let newCoordinates: number[][] = [];
      let yValues: number[] = [];
      if (selectedValue === 'Torque') {
        yValues = torqueValues;
      } else {
        yValues = powerValues;
      }
      newCoordinates = calculateCoordinates(yValues, timeValues, rows);
      setSeriesData(newCoordinates);
      setHasGraphDataChanged(false);
    }
  }, [
    hasGraphDataChanged,
    powerValues,
    rows,
    selectedValue,
    timeValues,
    torqueValues,
  ]);

  const handleApplyClick = () => {
    dispatch(saveDutyCycleValuesMotor(rows));
    dispatch(saveTorqueGraphCoordinates(seriesData));
    dispatch(setHasMotorDutyCycleApplyClicked(true));
    setHasGraphDataChanged(true);
    setSelectedValue('Torque');
    onApplyClick(hasMultipleOverload);
  };

  const handleInputChange = (
    value: string,
    rowIndex: number,
    fieldName: keyof LoadDataMotorSelection,
  ) => {
    const inputValue = parseFloat(value);
    const updatedRows = [...rows];
    updatedRows[rowIndex] =
      fieldName === 'load'
        ? {
            ...updatedRows[rowIndex],
            [fieldName]: inputValue,
            torque: (inputValue * (baseLoadData[0]?.torque ?? 0)) / 100,
            power: (baseLoadData[0]?.power ?? 0 * inputValue) / 100,
          }
        : {
            ...updatedRows[rowIndex],
            [fieldName]: inputValue,
          };

    if (fieldName === 'load') {
      const torque = (inputValue * (baseLoadData[0]?.torque ?? 0)) / 100;
      const power = (inputValue * (baseLoadData[0]?.power ?? 0)) / 100;

      updatedRows[rowIndex].torque = torque;

      const torqueData = [...torqueValues];
      torqueData[rowIndex] = torque;
      setTorqueValues(torqueData);

      const powerData = [...powerValues];
      powerData[rowIndex] = power;
      setPowerValues(powerData);

      updatedRows[rowIndex].power = power;

      const loadData = [...loadValues];
      loadData[rowIndex] = inputValue;
      setLoadValues(loadData);
      setHasGraphDataChanged(true);

      if (rowIndex > 1) {
        if (inputValue > 0) {
          setHasMultipleOverload(true);
        } else {
          setHasMultipleOverload(false);
        }
      }
    }

    if (fieldName === 'time') {
      const timeData = [...timeValues];
      timeData[rowIndex] = inputValue;
      setTimeValues(timeData);
      setHasGraphDataChanged(true);
    }

    setRows(updatedRows);
  };

  const handleOnCloseClick = () => {
    setSelectedValue('Torque');
    onClose?.();
  };

  return (
    <Modal
      open={modalOpen}
      onModalClose={onClose}
      modalTitle={t(I18.motor_drive_selection_modal_add_duty_cycle_title)}
      showCloseButton={true}
      type="default"
      showHederSeparator="true"
      backdropClick={false}
      className="add-duty-cycle-modal"
    >
      <div
        slot="content"
        id="add-duty-cycle-modal-content"
        className="add-duty-cycle-modal-content"
      >
        <div className="rms-power-data-container">
          <div className="rms-data-container">
            <div className="rms-container">
              <div className="rms-data-labels">
                {t(I18.motor_drive_selection_modal_rms_label)}
              </div>
              <div className="rms-data-value">{totalRMS + '%'}</div>
            </div>
            <div className="rms10-container">
              <div className="rms-data-labels">
                {t(I18.motor_drive_selection_modal_rms10_label)}
              </div>
              <div className="rms-data-value">{RMS10 + '%'}</div>
            </div>
            <div className="highest-load-container">
              <div className="rms-data-labels highest-load-label">
                {t(I18.motor_drive_selection_modal_highest_load_label)}
              </div>
              <div className="rms-data-value">
                {Math.max(...loadValues) + ' %'}
              </div>
            </div>
          </div>
          <div className="power-details-container">
            <div className="base-power-container">
              <div className="power-details-label">
                {t(I18.motor_drive_selection_modal_base_power_label)}
              </div>
              <div className="power-data-value">
                {baseLoadData[0]?.power + ' kW'}
              </div>
            </div>
            <div className="cont-load-container">
              <div className="power-details-label">
                {t(I18.motor_drive_selection_modal_cont_load_label)}
              </div>
              <div className="power-data-value">{continuousLoad + ' kW'}</div>
            </div>
            <div className="power-overload-container">
              <div className="power-details-label">
                {t(I18.motor_drive_selection_modal_power_overload_label)}
              </div>
              <div className="power-data-value">{powerOverload + ' %'}</div>
            </div>
          </div>
        </div>
        <div className="torque-power-radiobutton-container">
          <RadioGroup
            value={selectedValue}
            onRadioChange={(event) => {
              setSelectedValue(event.target.value);
              setHasGraphDataChanged(true);
            }}
            layout="horizontal"
          >
            <div slot="radio">
              <Radio className="torque-option" value="Torque">
                <label htmlFor="" slot="label">
                  {t(I18.motor_drive_selection_modal_radiobutton_torque_label)}
                </label>
              </Radio>
              <Radio className="power-option" value="Power">
                <label htmlFor="" slot="label">
                  {t(I18.motor_drive_selection_modal_radiobutton_power_label)}
                </label>
              </Radio>
            </div>
          </RadioGroup>
        </div>
        <div className="load-points-container">
          <div className="load-points-title">
            {t(I18.motor_drive_selection_modal_load_points_title)}
          </div>
          <div className="load-points-table-container">
            <table className="load-points-table">
              <thead>
                <tr
                  className="load-points-table-header-container"
                  key={generateKey('load-points-header')}
                >
                  <td className="load-points-cell description-title">
                    {t(I18.motor_drive_selection_modal_table_description_title)}
                  </td>
                  <td className="load-points-cell time-title">
                    {t(I18.motor_drive_selection_modal_table_time_title)}
                  </td>
                  <td className="load-points-cell">
                    {t(I18.motor_drive_selection_modal_table_min_speed_title)}
                  </td>
                  <td className="load-points-cell">
                    {t(I18.motor_drive_selection_modal_table_max_speed_title)}
                  </td>
                  <td className="load-points-cell">
                    {t(I18.motor_drive_selection_modal_table_load_title)}
                  </td>
                  <td className="load-points-cell">
                    {selectedValue === 'Torque'
                      ? t(I18.motor_drive_selection_modal_table_torque_title)
                      : t(I18.motor_drive_selection_modal_table_power_title)}
                  </td>
                </tr>
              </thead>
              <tbody>
                {rows.map((i, index) => {
                  return (
                    <tr
                      className="load-points-table-row"
                      key={generateKey(i.rowName)}
                    >
                      <td className="load-points-cell description-column">
                        {i.rowName}
                      </td>
                      <td className="load-point-input-cell">
                        <div className="load-points-value-column">
                          <div className="load-points-table-input-container">
                            <input
                              className="load-points-table-input no-arrow"
                              type="number"
                              onBlur={(event) =>
                                handleInputChange(
                                  event.target.value,
                                  index,
                                  'time',
                                )
                              }
                              required={true}
                              defaultValue={i.time}
                            />
                          </div>
                          <div className="load-points-table-unit-container">
                            {'s'}
                          </div>
                        </div>
                      </td>
                      <td className="load-point-input-cell">
                        <div className="load-points-value-column">
                          <div>
                            <input
                              className="load-points-table-input no-arrow"
                              type="number"
                              onBlur={(event) =>
                                handleInputChange(
                                  event.target.value,
                                  index,
                                  'minSpeed',
                                )
                              }
                              required={true}
                              defaultValue={i.minSpeed}
                              disabled={index === 0}
                            />
                          </div>
                          <div className="load-points-table-unit-container">
                            {'rpm'}
                          </div>
                        </div>
                      </td>
                      <td className="load-point-input-cell">
                        <div className="load-points-value-column">
                          <div>
                            <input
                              className="load-points-table-input no-arrow"
                              type="number"
                              onBlur={(event) =>
                                handleInputChange(
                                  event.target.value,
                                  index,
                                  'maxSpeed',
                                )
                              }
                              required={true}
                              defaultValue={i.maxSpeed}
                              disabled={index === 0}
                            />
                          </div>
                          <div className="load-points-table-unit-container">
                            {'rpm'}
                          </div>
                        </div>
                      </td>
                      <td className="load-point-input-cell">
                        <div className="load-points-value-column">
                          <div>
                            <input
                              className="load-points-table-input no-arrow"
                              type="number"
                              onBlur={(event) =>
                                handleInputChange(
                                  event.target.value,
                                  index,
                                  'load',
                                )
                              }
                              required={true}
                              defaultValue={i.load}
                              disabled={index === 0}
                            />
                          </div>
                          <div className="load-points-table-unit-container">
                            {'%'}
                          </div>
                        </div>
                      </td>
                      <td className="load-point-input-cell">
                        <div className="load-points-value-column">
                          <div className="load-points-table-input-container">
                            <input
                              className="load-points-table-input no-arrow"
                              type="number"
                              required={true}
                              defaultValue={
                                selectedValue === 'Torque' ? i.torque : i.power
                              }
                              onBlur={(event) =>
                                handleInputChange(
                                  event.target.value,
                                  index,
                                  selectedValue === 'Torque'
                                    ? 'torque'
                                    : 'power',
                                )
                              }
                              disabled={index === 0}
                            />
                          </div>
                          <div className="load-points-table-unit-container">
                            {selectedValue === 'Torque' ? 'Nm' : 'kW'}
                          </div>
                        </div>
                      </td>
                      {index > 1 && (
                        <td className="dutycycle-delete-button-container">
                          <div
                            role="button"
                            onClick={() => handleRowDeleteClick(index)}
                            className="dutycycle-delete-button"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                handleRowDeleteClick(index);
                              }
                            }}
                          >
                            <Icon name="trash" />
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="add-overload-button-container">
            <Button
              className="add-overload-button"
              type="secondary"
              onClick={handleAddOverloadClick}
              disabled={rows.length === 11}
            >
              {<Icon slot="icon" size="small" name="plus" />}
              {t(I18.motor_drive_selection_modal_add_over_load_button)}
            </Button>
          </div>
        </div>
        <div className="load-graph-container">
          <div className="load-graph-title">
            {t(I18.motor_drive_selection_modal_load_graph_title)}
          </div>
          {optionsRef.current && (
            <div className="add-duty-cycle-graph-container">
              <ApexChartGraph
                options={optionsRef.current || {}}
                series={[
                  {
                    name: 'Torque',
                    data: seriesData,
                  },
                ]}
                type={'line'}
                height={optionsRef.current?.chart?.height ?? 100}
              />
            </div>
          )}
        </div>
      </div>
      <div className="add-duty-cycle-modal-footer" slot="footer">
        <Button
          type="secondary"
          size="small"
          onClick={handleOnCloseClick}
          className="create-project-cancel-button"
        >
          {t(I18.motor_drive_selection_modal_cancel_button)}
        </Button>
        <Button type="primary-black" size="small" onClick={handleApplyClick}>
          {t(I18.motor_drive_selection_modal_apply_button)}
        </Button>
      </div>
    </Modal>
  );
};

export default DutyCycleModalMotor;
