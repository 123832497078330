import { CommonuxToastNotification } from '@abb-motion-ux/commonux-web-components-react';
import { PropsWithChildren } from 'react';

interface NotificationProps {
  className?: string;
  id?: string;
}

function Notification(props: PropsWithChildren<NotificationProps>) {
  return (
    <CommonuxToastNotification {...props}>
      {props?.children}
    </CommonuxToastNotification>
  );
}

export default Notification;
