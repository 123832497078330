import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Icon from '../../../../components/Icon';
import SelectContainer from '../../../../components/Form/SelectContainer';
import Select from '../../../../components/Form/Select';
import SelectOption from '../../../../components/Form/SelectOption';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Form/Button';
import {
  deleteSessionStorageValue,
  generateKey,
} from '../../../../utils/GeneralUtils';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import {
  defaultDriveFormData,
  defaultMachineryDriveFormData,
  driveLoadDefaultData,
} from '../models/DriveDefaultValues';
import {
  DriveLoadData,
  DriveSelectionData,
  DriveSelectionResultData,
  DrivetypeInputParameterData,
} from '../models/DriveSelectionModels';
import DutyCycleModalDrive from './DutyCycleModalDrive';
import { LoadDataDriveSelection } from '../models/DutyCycleModels';
import DriveSelectionDropdown from './DriveSelectionDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import {
  Dimension,
  DriveSelectionFormProps,
  DropdownData,
  MotorSelectionParamResult,
} from '../models/MotorSelectionModels';
import { useGetDriveSizeParametersQuery } from '../services/APIDimensioningService';
import ManualSelectionModalDrive from './ManualSelectionModalDrive';
import {
  driveFormDataSave,
  driveLoadDataSave,
  motorLoadDataSave,
  motorParamsDataSave,
  resetApplication,
  resetDrive,
  resetDriveDropdown,
  resetDriveErrorResponse,
  resetMotor,
  resetMotorErrorResponse,
  saveDutyCycleValuesDrive,
  setActiveComponent,
  setApplicationActiveTabs,
  setDriveLoadDropdownDataSet,
  setDriveSelectionDropdownDataSet,
  setPlusCodeOptionValues,
} from '../../store/WorkspaceReducer';
import {
  CommonuxMenu,
  CommonuxMenuItem,
} from '@abb-motion-ux/commonux-web-components-react';
import InformationModal from '../../../Home/Components/InformationModal';
import { useGetDrivetypeInputsDataMutation } from '../../../Home/services/APICommonService';
import notificationManager from '../../../../utils/NotificationManager';
import { useSession } from '../../../../utils/Auth';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import { usePostPlusCodeDataByIdMutation } from '../../services/DrivePluscodeService';
import { menuOpenEventhandler } from '../../../../components/Form/Menu';

const DriveSelectionForm = ({
  viewManualSelection,
  isManualSelectionLoading,
  onManualSelectClick,
  onManualSelectionClose,
  isLoading,
}: DriveSelectionFormProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [hasLoadDataChanged, setHasLoadDataChanged] = useState(false);
  const menuRef = useRef(null);

  const [viewDutyCycle, setViewDutyCycle] = useState(false);
  const [baseLoadData, setBaseLoadData] = useState<LoadDataDriveSelection[]>([
    {
      time: 0,
      current: 0,
      maxF: 0,
      minF: 0,
      cosfi: 0,
      rowName: 'BaseLoad',
    },
  ]);
  const [hasOverloadTypeChanged, setHasOverloadTypeChanged] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isApplicationModal, setIsApplicationModal] = useState(true);

  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [isDriveTypeDataLoading, setIsDriveTypeDataLoading] = useState(false);
  const { session } = useSession();
  const [currentDriveType, setCurrentDriveType] = useState('0');
  const [updateProject] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [updatePlusCode] = usePostPlusCodeDataByIdMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );

  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );

  const selectionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionDetails;
  });

  const shouldResetDriveDropdown = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .dimensionDetails.shouldResetDriveDropdown;
  });

  const handleResetApplicationClick = () => {
    sessionStorage.setItem('app-id', workspace.id);
    if (isApplicationModal) {
      dispatch(resetApplication());

      updateProject({
        userToken: session ? session?.user.access_token : undefined,
        projectData: {
          projectId: projectDetails.projectId,
          projectName: projectDetails.projectName,
          jsonFileName: workspace.id,
          projectJson: JSON.stringify({} as Dimension),
          transformerId: selectedTransformerDatas?.trafoId,
          applicationId: workspace?.id,
          bomDetailsJson: JSON.stringify({ AppId: workspace?.id }),
        },
      })
        .then(() => {
          deleteSessionStorageValue();
          updatePlusCode({
            applicationId: workspace?.id || sessionStorage.getItem('app-id'),
            updatePlusCodes: {
              longtypeCode: '',
              options: [],
            },
          }).then(() => {
            sessionStorage.removeItem('app-id');
          });
        })
        .catch((error) => {
          notificationManager.error(
            'Unable to autosave the project due to: ' + error.toString(),
          );
        });
    } else {
      if (resultData.dimension) {
        dispatch(resetDrive());
        dispatch(resetDriveErrorResponse());

        updateProject({
          userToken: session ? session?.user.access_token : undefined,
          projectData: {
            projectId: projectDetails.projectId,
            projectName: projectDetails.projectName,
            jsonFileName: workspace.id,
            projectJson: JSON.stringify({
              dimensionSavedData: {
                dimensionInput:
                  motorResultData.dimension?.dimensionInput
                    ?.selectionInputs?.[0],
                applicationOutput: {
                  supplyDriveLoads: [
                    {
                      ...motorResultData.dimension?.dimensionResult
                        ?.supplyDriveLoads?.[0],
                      inverterUnit: null,
                    },
                  ],
                },
                exceptionType: motorResultData?.exceptionType,
                message: motorResultData?.message,
              },
              selectionSavedData: {
                industryName: workspace?.selectionDetails?.industryName,
                applicationName: workspace?.selectionDetails?.applicationName,
              },
            }),
            bomDetailsJson: JSON.stringify({
              AppId: workspace?.id,
              MotorId:
                motorResultData?.dimension?.dimensionResult
                  ?.supplyDriveLoads?.[0].motorUnit?.results?.[1]?.value,
              MotorTypeCode:
                motorResultData?.dimension?.dimensionResult
                  ?.supplyDriveLoads?.[0].motorUnit.results?.[0]?.value,
              MotorName:
                motorResultData?.dimension?.dimensionInput?.selectionInputs?.[0]
                  .motorName,
              MotorCount:
                motorResultData.dimension?.dimensionInput?.selectionInputs?.[0]
                  .motorsCount,
              DriveId: '',
              DriveTypeCode: '',
              DriveName: '',
              DriveCount: '',
            }),
          },
        })
          .then(() => {
            deleteSessionStorageValue();
            updatePlusCode({
              applicationId: workspace?.id || sessionStorage.getItem('app-id'),
              updatePlusCodes: {
                longtypeCode: '',
                options: [],
              },
            }).then(() => {
              sessionStorage.removeItem('app-id');
            });
            dispatch(setPlusCodeOptionValues({}));
            dispatch(setApplicationActiveTabs([]));
          })

          .catch((error) => {
            notificationManager.error(
              'Unable to autosave the project due to: ' + error.toString(),
            );
          });
      }
    }
  };

  const driveDropDownData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveSelectionDropdownDataSet,
  );

  const typeIds = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.typeIds,
  );

  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const driveTypeDropdownData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveTypeDropdownData.resultSet,
  );

  const driveLoadDropdownDataSet = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveLoadDropdownDataSet,
  );
  const currentAppRank = useSelector(
    (state: RootState) => state.workspace.currentAppRank,
  );

  const hasMultipleOverload = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.hasMultipleOverload,
  );

  const { data: motorAndDriveData } = useGetDriveSizeParametersQuery({
    projectType: workspace.drive
      ? parseInt(workspace.drive.driveGroup)
      : typeIds.projectType,
    motorType: typeIds.motorType,
  });

  const [driveTypeInputData] = useGetDrivetypeInputsDataMutation();
  const driveInputData = motorAndDriveData?.motorParams?.motorParams;

  const dimensionMotorInputData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .dimensionDetails?.dimensionInputData;
  });

  const appData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const motorLoadData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.motorLoadData,
  );

  const updatedLoadFields = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveLoadData,
  );

  const updatedFormFields = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveFormData,
  );

  const previousDutyCycleValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dutyCycleDriveValues,
  );

  const resultData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectDriveResult,
  );

  const motorResultData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectMotorResult,
  );

  let driveTypeParamData: DrivetypeInputParameterData = {
    projectType: typeIds?.projectType?.toString(),
  };
  const projectData = useSelector((state: RootState) => state.home.projectData);
  const countryCode =
    projectData?.destinationCountry === 'IN'
      ? '1'
      : projectData?.destinationCountry === 'CN'
        ? '2'
        : '0';

  const fetchDriveDropdownData = (
    event: string,
    fieldtype: string,
    hasDriveFormFieldsUpdated: boolean,
    newDriveFormFieldInput: DriveSelectionData,
  ) => {
    driveTypeParamData = {
      ...driveTypeParamData,
      countryVariant: countryCode,
      projectType: typeIds?.projectType?.toString(),
    };
    if (fieldtype === 'type') {
      setCurrentDriveType(event);
      driveTypeParamData = {
        ...driveTypeParamData,
        [fieldtype]: newDriveFormFieldInput.type
          ? newDriveFormFieldInput.type
          : event,
        construction: newDriveFormFieldInput?.construction,
        ipClass: newDriveFormFieldInput?.ipClass,
        cooling: newDriveFormFieldInput?.cooling,
      };
    } else if (fieldtype === 'ipClass') {
      driveTypeParamData = {
        ...driveTypeParamData,
        type:
          currentDriveType === '0'
            ? (newDriveFormFieldInput?.type ?? currentDriveType)
            : currentDriveType,
        construction: newDriveFormFieldInput?.construction,
        [fieldtype]: event,
      };
    } else if (fieldtype === 'cooling') {
      driveTypeParamData = {
        ...driveTypeParamData,
        type:
          currentDriveType === '0'
            ? (newDriveFormFieldInput?.type ?? currentDriveType)
            : currentDriveType,
        construction: newDriveFormFieldInput?.construction,
        ipClass: newDriveFormFieldInput?.ipClass,
        [fieldtype]: event,
      };
    } else if (fieldtype === 'glycolConcentration') {
      driveTypeParamData = {
        ...driveTypeParamData,
        type:
          currentDriveType === '0'
            ? (newDriveFormFieldInput?.type ?? currentDriveType)
            : currentDriveType,
        construction: newDriveFormFieldInput?.construction,
        ipClass: newDriveFormFieldInput?.ipClass,
        cooling: newDriveFormFieldInput?.cooling,
        [fieldtype]: event,
      };
    } else if (fieldtype === 'sineFilter') {
      driveTypeParamData = {
        ...driveTypeParamData,
        type:
          currentDriveType === '0'
            ? (newDriveFormFieldInput?.type ?? currentDriveType)
            : currentDriveType,
        construction: newDriveFormFieldInput?.construction,
        ipClass: newDriveFormFieldInput?.ipClass,
        cooling: newDriveFormFieldInput?.cooling,
        pulse: newDriveFormFieldInput?.pulse,
        [fieldtype]: event,
      };
    } else {
      driveTypeParamData = {
        ...driveTypeParamData,
        type:
          currentDriveType === '0'
            ? (newDriveFormFieldInput?.type ?? currentDriveType)
            : currentDriveType,
        [fieldtype]: event,
      };
    }

    if (driveTypeParamData?.type !== '') {
      setIsDriveTypeDataLoading(true);
      driveTypeInputData(driveTypeParamData).then((response) => {
        if (response.data) {
          dispatch(setDriveSelectionDropdownDataSet(response.data));
          let newDriveFormData = { ...newDriveFormFieldInput };
          newDriveFormData = {
            ...newDriveFormData,
            type:
              fieldtype === 'type' || newDriveFormFieldInput?.type
                ? newDriveFormFieldInput?.type
                : (response.data?.type
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            construction:
              fieldtype === 'construction' ||
              newDriveFormFieldInput?.construction
                ? response.data?.construction?.find(
                    (k) =>
                      k.integerValue.toString() ===
                      newDriveFormFieldInput?.construction,
                  )
                  ? newDriveFormFieldInput?.construction
                  : (response.data?.construction
                      ?.find((i) => i.isDefault === true)
                      ?.integerValue.toString() ?? undefined)
                : undefined,
            cableLength:
              fieldtype === 'cableLength' || newDriveFormFieldInput?.cableLength
                ? newDriveFormFieldInput?.cableLength
                : (response.data?.cableLength
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            cableSize:
              fieldtype === 'cableSize' || newDriveFormFieldInput?.cableSize
                ? newDriveFormFieldInput?.cableSize
                : (response.data?.cableSize
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            chokes:
              fieldtype === 'chokes' || newDriveFormFieldInput?.chokes
                ? newDriveFormFieldInput?.chokes
                : (response.data?.chokes
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            cooling:
              fieldtype === 'cooling' || newDriveFormFieldInput?.cooling
                ? newDriveFormFieldInput?.cooling
                : (response.data?.cooling
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            emcCategory:
              fieldtype === 'emcCategory' || newDriveFormFieldInput?.emcCategory
                ? newDriveFormFieldInput?.emcCategory
                : (response.data?.emcCategory
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            glycolConcentration:
              fieldtype === 'glycolConcentration' ||
              newDriveFormFieldInput?.glycolConcentration
                ? newDriveFormFieldInput?.glycolConcentration
                : (response.data?.glycolConcentration
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            gridVoltage:
              fieldtype === 'gridVoltage' || newDriveFormFieldInput?.gridVoltage
                ? newDriveFormFieldInput?.gridVoltage
                : (response.data?.gridVoltage
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            ipClass:
              fieldtype === 'ipClass' || newDriveFormFieldInput?.ipClass
                ? response.data?.ipClass?.find(
                    (k) =>
                      k.integerValue.toString() ===
                      newDriveFormFieldInput?.ipClass,
                  )
                  ? newDriveFormFieldInput?.ipClass
                  : (response.data?.ipClass
                      ?.find((i) => i.isDefault === true)
                      ?.integerValue.toString() ?? undefined)
                : undefined,
            liquidTemperature:
              fieldtype === 'liquidTemperature' ||
              newDriveFormFieldInput?.liquidTemperature
                ? newDriveFormFieldInput?.liquidTemperature
                : (response.data?.liquidTemperature
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            minSwitchFreq:
              fieldtype === 'minSwitchFreq' ||
              newDriveFormFieldInput?.minSwitchFreq
                ? response.data?.minSwitchFreq?.find(
                    (k) =>
                      k.integerValue.toString() ===
                      newDriveFormFieldInput?.minSwitchFreq,
                  )
                  ? newDriveFormFieldInput?.minSwitchFreq
                  : (response.data?.minSwitchFreq
                      ?.find((i) => i.isDefault === true)
                      ?.integerValue.toString() ?? undefined)
                : undefined,
            pulse:
              fieldtype === 'pulse' || newDriveFormFieldInput?.pulse
                ? response.data?.pulse?.find(
                    (k) =>
                      k.integerValue.toString() ===
                      newDriveFormFieldInput?.pulse,
                  )
                  ? newDriveFormFieldInput?.pulse
                  : (response.data?.pulse
                      ?.find((i) => i.isDefault === true)
                      ?.integerValue.toString() ?? undefined)
                : undefined,
            sineFilter:
              fieldtype === 'sineFilter' || newDriveFormFieldInput?.sineFilter
                ? newDriveFormFieldInput?.sineFilter
                : (response.data?.sineFilter
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
            variant:
              fieldtype === 'variant' || newDriveFormFieldInput?.variant
                ? newDriveFormFieldInput?.variant
                : (response.data?.variant
                    ?.find((i) => i.isDefault === true)
                    ?.integerValue.toString() ?? undefined),
          };

          if (
            appData.dimensionDetails?.driveFormData?.type !== '' &&
            response.data.type.length > 1 &&
            !hasDriveFormFieldsUpdated
          ) {
            response.data.type.forEach((driveType) => {
              if (appData.drive?.driveType === driveType.enumValue) {
                newDriveFormData = {
                  ...newDriveFormData,
                  type: driveType.integerValue.toString(),
                };
              }
            });
          }

          if (
            appData.dimensionDetails?.driveFormData?.construction !== '' &&
            response.data.construction.length > 1 &&
            !hasDriveFormFieldsUpdated
          ) {
            response.data.construction.forEach((driveConstruction) => {
              if (appData.drive?.mountingType === driveConstruction.enumValue) {
                newDriveFormData = {
                  ...newDriveFormData,
                  construction: driveConstruction.integerValue.toString(),
                };
              }
            });
          }

          if (newDriveFormData?.type !== undefined) {
            dispatch(driveFormDataSave(newDriveFormData));
          }
          setIsDriveTypeDataLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (Object.keys(driveDropDownData).length === 0) {
      fetchDriveDropdownData('0', 'type', false, updatedFormFields);
    }
  }, []);

  useEffect(() => {
    if (shouldResetDriveDropdown) {
      fetchDriveDropdownData('0', 'type', false, defaultDriveFormData);
      dispatch(resetDriveDropdown(false));
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let loadFormData = { ...updatedLoadFields };
      const vsdBaseCurrent =
        motorResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.inverterUnit?.miscellaneousUnit?.selectionData?.[1]?.row?.[1] ??
        motorResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.motorUnit?.miscellaneousUnit?.vSDRatings?.vSDData?.[5]?.row?.[3] ??
        '0';
      const vsdMaxCurrent =
        motorResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.inverterUnit?.miscellaneousUnit?.selectionData?.[2]?.row?.[1] ??
        motorResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.motorUnit?.miscellaneousUnit?.vSDRatings?.vSDData?.[5]?.row?.[3] ??
        '0';

      const dataCategory =
        resultData?.dimension?.dimensionInput?.selectionInputs?.[0]?.dataCategory?.toLowerCase();

      if (dimensionMotorInputData && resultData?.dimension?.dimensionResult) {
        if (
          resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
            ?.motorUnit?.miscellaneousUnit?.vSDRatings !== null
        ) {
          loadFormData = {
            loadType: motorLoadData?.loadType,
            overLoadType: motorLoadData?.overLoadType,
            iContinuous:
              hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                ? 'custom'
                : dataCategory === 'drive'
                  ? updatedLoadFields.iContinuous
                  : dataCategory === 'motor' &&
                      (!updatedLoadFields.iContinuous ||
                        updatedLoadFields.iContinuous === '0')
                    ? (vsdBaseCurrent ?? '0')
                    : dataCategory === 'all'
                      ? (vsdBaseCurrent ?? '0')
                      : updatedLoadFields.iContinuous,
            dutyCycleTime:
              hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                ? 'custom'
                : (resultData?.dimension?.dimensionInput?.selectionInputs?.[0]
                    ?.dutyCycleTime ??
                  dimensionMotorInputData?.selectionInputs?.[currentAppRank]
                    ?.dutyCycleTime),
            maxLoadTime:
              hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                ? 'custom'
                : (resultData?.dimension?.dimensionInput?.selectionInputs?.[0]
                    ?.maxLoadTime ??
                  dimensionMotorInputData?.selectionInputs?.[currentAppRank]
                    ?.maxLoadTime),
            iMax:
              hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                ? 'custom'
                : dataCategory === 'drive'
                  ? updatedLoadFields.iMax
                  : dataCategory === 'motor' &&
                      (!updatedLoadFields.iMax ||
                        updatedLoadFields.iMax === '0')
                    ? (vsdMaxCurrent ?? '0')
                    : dataCategory === 'all'
                      ? (vsdMaxCurrent ?? '0')
                      : updatedLoadFields.iMax,
            calculatedIContinuous: vsdBaseCurrent ?? '0',
            calculatedIMaximum: vsdMaxCurrent ?? '0',
            driveName:
              resultData?.dimension?.dimensionInput?.selectionInputs?.[0]
                ?.driveName ?? updatedLoadFields?.driveName,
            drivesCount:
              resultData?.dimension?.dimensionInput?.selectionInputs?.[0]
                ?.drivesCount ?? updatedLoadFields?.drivesCount,
          };
        } else {
          loadFormData = {
            loadType: motorLoadData?.loadType,
            overLoadType: motorLoadData?.overLoadType,
            iContinuous:
              hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                ? 'custom'
                : (resultData?.dimension?.dimensionInput?.selectionInputs?.[0]
                    ?.iContinuous ?? '0'),
            dutyCycleTime:
              hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                ? 'custom'
                : (resultData?.dimension?.dimensionInput?.selectionInputs?.[0]
                    ?.dutyCycleTime ??
                  dimensionMotorInputData?.selectionInputs?.[0]?.dutyCycleTime),
            maxLoadTime:
              hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                ? 'custom'
                : (resultData?.dimension?.dimensionInput?.selectionInputs?.[0]
                    ?.maxLoadTime ??
                  dimensionMotorInputData?.selectionInputs?.[0]?.maxLoadTime),
            iMax:
              hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                ? 'custom'
                : (updatedLoadFields?.iMax ?? vsdMaxCurrent ?? '0'),
            calculatedIContinuous: vsdBaseCurrent ?? '0',
            calculatedIMaximum: vsdBaseCurrent ?? '0',
          };
        }
      } else {
        loadFormData = {
          loadType: motorLoadData?.loadType ?? driveLoadDefaultData.loadType,
          overLoadType:
            motorLoadData?.overLoadType ?? driveLoadDefaultData.overLoadType,
          iContinuous:
            hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
              ? 'custom'
              : (updatedLoadFields?.iContinuous ?? vsdBaseCurrent ?? '0'),
          dutyCycleTime:
            hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
              ? 'custom'
              : (motorLoadData?.dutyCycleTime ??
                updatedLoadFields?.dutyCycleTime ??
                '0'),
          maxLoadTime:
            hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
              ? 'custom'
              : (updatedLoadFields?.maxLoadTime ?? '0'),
          iMax:
            hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
              ? 'custom'
              : (updatedLoadFields?.iMax ?? vsdMaxCurrent ?? '0'),
          calculatedIContinuous: vsdBaseCurrent ?? '0',
          calculatedIMaximum: vsdBaseCurrent ?? '0',
        };
      }
      dispatch(driveLoadDataSave(loadFormData));
    }
  }, [resultData, dimensionMotorInputData, hasMultipleOverload, isLoading]);

  useEffect(() => {
    let driveLoadDropdownData: DropdownData[] = [];
    if (driveInputData) {
      driveInputData?.forEach((inputItem) => {
        if (
          inputItem.parameterName === 'loadType' ||
          inputItem.parameterName === 'overLoadType'
        ) {
          driveLoadDropdownData = [
            ...driveLoadDropdownData,
            {
              label:
                inputItem.parameterName === 'loadType'
                  ? 'Load type'
                  : 'Overload type',
              accessor: inputItem.parameterName,
              isMultiSelect: false,
              isVisible: true,
              selectInput: inputItem.options,
            },
          ];
        }
      });
      dispatch(setDriveLoadDropdownDataSet(driveLoadDropdownData));
    }
  }, [driveInputData, driveTypeDropdownData, dispatch]);

  useLayoutEffect(() => {
    if (hasLoadDataChanged) {
      let previousBaseCurrent = previousDutyCycleValues?.[0]?.current ?? 0;
      if (previousDutyCycleValues?.length > 0 && previousBaseCurrent > 0) {
        setBaseLoadData(previousDutyCycleValues);
        dispatch(saveDutyCycleValuesDrive(previousDutyCycleValues));
      } else {
        let tableData: LoadDataDriveSelection[] = [
          {
            rowName: t(I18.motor_drive_selection_modal_table_base_load_title),
            time:
              parseFloat(updatedLoadFields?.dutyCycleTime ?? '0') -
              parseFloat(updatedLoadFields?.maxLoadTime ?? '0'),
            current: parseFloat(updatedLoadFields?.iContinuous),
            maxF: 50,
            minF: 10,
            cosfi: 0.87,
          },
          {
            rowName:
              t(I18.motor_drive_selection_modal_table_over_load_title) + ' 1',
            time: parseFloat(updatedLoadFields?.maxLoadTime ?? '0'),
            current: parseFloat(updatedLoadFields?.iMax),
            maxF: 50,
            minF: 10,
            cosfi: 0.87,
          },
        ];
        setBaseLoadData(tableData);
        dispatch(saveDutyCycleValuesDrive(tableData));
      }
      setHasLoadDataChanged(false);
    }
  }, [
    hasLoadDataChanged,
    updatedLoadFields,
    updatedFormFields,
    previousDutyCycleValues,
  ]);

  useEffect(() => {
    let driveFormDataInitial: DriveSelectionData = {};

    if (driveDropDownData?.type) {
      driveFormDataInitial = {
        ...driveFormDataInitial,
        type:
          updatedFormFields?.type ??
          driveDropDownData?.type
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        construction:
          updatedFormFields?.construction ??
          driveDropDownData?.construction
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        cableLength:
          updatedFormFields?.cableLength ??
          driveDropDownData?.cableLength
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        cableSize:
          updatedFormFields?.cableSize ??
          driveDropDownData?.cableSize
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        chokes:
          updatedFormFields?.chokes ??
          driveDropDownData?.chokes
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        cooling:
          updatedFormFields?.cooling ??
          driveDropDownData?.cooling
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        emcCategory:
          updatedFormFields?.emcCategory ??
          driveDropDownData?.emcCategory
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        glycolConcentration:
          updatedFormFields?.glycolConcentration ??
          driveDropDownData?.glycolConcentration
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        gridVoltage:
          updatedFormFields?.gridVoltage ??
          driveDropDownData?.gridVoltage
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        ipClass:
          updatedFormFields?.ipClass ??
          driveDropDownData?.ipClass
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        liquidTemperature:
          updatedFormFields?.liquidTemperature ??
          driveDropDownData?.liquidTemperature
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        minSwitchFreq:
          updatedFormFields?.minSwitchFreq ??
          driveDropDownData?.minSwitchFreq
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        pulse:
          updatedFormFields?.pulse ??
          driveDropDownData?.pulse
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        sineFilter:
          updatedFormFields?.sineFilter ??
          driveDropDownData?.sineFilter
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
        variant:
          updatedFormFields?.variant ??
          driveDropDownData?.variant
            ?.find((i) => i.isDefault === true)
            ?.integerValue.toString(),
      };
    }
    if (typeIds?.projectType.toString() === '7') {
      driveFormDataInitial = {
        ...driveFormDataInitial,
      };

      dispatch(
        driveFormDataSave(
          driveFormDataInitial ?? defaultMachineryDriveFormData,
        ),
      );
    } else {
      driveFormDataInitial = {
        ...driveFormDataInitial,
      };
      dispatch(driveFormDataSave(driveFormDataInitial ?? defaultDriveFormData));
    }
  }, [driveDropDownData]);

  const handleFormDataChange = (
    event: string,
    type: string,
    dataType: string,
  ) => {
    if (dataType === 'form') {
      let newDriveFormInput = { ...updatedFormFields };
      newDriveFormInput[type as keyof DriveSelectionData] = event;
      dispatch(driveFormDataSave(newDriveFormInput));
      fetchDriveDropdownData(event, type, true, newDriveFormInput);
      //Drives for HVAC and water project type, drive type selected ACH580 then Refrigerator/chiller loadtype should be selected
      if (type === 'type' && event === '70' && typeIds?.projectType === 6) {
        let loadfields = { ...motorLoadData };
        loadfields.loadType = '3';
        dispatch(motorLoadDataSave(loadfields));
      }
    } else {
      if (type === 'maxLoadTime' || type === 'dutyCycleTime') {
        let loadfields = { ...motorLoadData } as any;
        loadfields[type] = event;

        if (type === 'maxLoadTime') {
          if (parseInt(event) >= parseInt(loadfields.dutyCycleTime)) {
            loadfields['dutyCycleTime'] = (parseInt(event) + 1).toString();
          }
        }
        dispatch(motorLoadDataSave(loadfields));
      } else {
        let loadfields = { ...updatedLoadFields } as any;

        if (type === 'overLoadType') {
          setHasOverloadTypeChanged(true);
          setHasLoadDataChanged(true);
        }

        loadfields[type] = event;

        dispatch(driveLoadDataSave(loadfields));
      }
    }
  };

  const handleAddDutyCycleClick = () => {
    setHasLoadDataChanged(true);
    setViewDutyCycle(true);
  };

  const onAddDutyCycleModalClose = () => {
    setViewDutyCycle(false);
  };

  const onAddDutyCycleModalApply = (hasMultipleOverload: boolean) => {
    if (hasMultipleOverload) {
      let loadfields = { ...updatedLoadFields };
      loadfields['overLoadType'] = '1'; //'Multiform';
      dispatch(driveLoadDataSave(loadfields));
    }

    setHasOverloadTypeChanged(false);
    setHasLoadDataChanged(false);
    setViewDutyCycle(false);
  };

  useLayoutEffect(() => {
    if (
      updatedLoadFields?.overLoadType === '1' /* 'Multiform' */ &&
      hasOverloadTypeChanged
    ) {
      setViewDutyCycle(true);

      setHasOverloadTypeChanged(false);
      let loadfields = { ...updatedLoadFields };
      loadfields['overLoadType'] = '0';
      dispatch(driveLoadDataSave(loadfields));
    }
  }, [updatedLoadFields, viewDutyCycle, hasOverloadTypeChanged]);

  const handleBackToSelectionClick = () => {
    dispatch(resetMotor());
    dispatch(resetDrive());
    dispatch(resetDriveErrorResponse());
    dispatch(resetMotorErrorResponse());
    dispatch(motorParamsDataSave({} as MotorSelectionParamResult));

    updateProject({
      userToken: session?.user ? session?.user.access_token : undefined,
      projectData: {
        projectId: projectDetails.projectId,
        projectName: projectDetails.projectName,
        jsonFileName: workspace.id,
        projectJson: JSON.stringify({} as Dimension),
        transformerId: selectedTransformerDatas?.trafoId,
        applicationId: workspace?.id,
        bomDetailsJson: JSON.stringify({ AppId: workspace?.id }),
      },
    }).catch((error) => {
      notificationManager.error(
        'Unable to autosave the project due to: ' + error.toString(),
      );
    });

    dispatch(setActiveComponent('selection'));
  };

  return (
    <>
      <div className="motor-selection-header">
        <div className="motor-selection-header-text">
          <Icon slot="icon" size="small" name={'drive'} />
          <div className="motor-selection-header-label">
            {t(I18.drive_selection_title)}
          </div>
        </div>
        <div className={'motor-header-button-container'}>
          <div
            role="button"
            className={`motor-selection-back-button ${
              (isLoading || !!!selectionData.power) && 'disabled'
            }`}
            onClick={() => {
              !(isLoading || !!!selectionData.power) &&
                setShowSelectionModal(true);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                !(isLoading || !!!selectionData.power) &&
                  setShowSelectionModal(true);
              }
            }}
            hidden={!!!selectionData.power}
            tabIndex={0}
            data-rf="gudied-selection-back-button"
          >
            <Icon slot="icon" size="small" name={'left-arrow'} />
            <span className="motor-reset-label">Guided selection</span>
          </div>
          <div
            role="button"
            className="motor-selection-restart-button"
            onClick={() => {
              menuOpenEventhandler(menuRef.current, isMenuOpen);
              setIsMenuOpen(!isMenuOpen);
            }}
            onBlurCapture={() => {
              setIsMenuOpen(false);
              setTimeout(() => {
                menuOpenEventhandler(menuRef.current, true);
              }, 250);
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                menuOpenEventhandler(menuRef.current, isMenuOpen);
                setIsMenuOpen(!isMenuOpen);
              }
            }}
          >
            <Icon slot="icon" size="small" name={'turn-counter-clockwise'} />
            <span
              className="motor-reset-label"
              data-rf="drive-reset-label"
              onClick={() => {
                menuOpenEventhandler(menuRef.current);
                setIsMenuOpen(true);
              }}
            >
              {' '}
              Reset
            </span>

            <CommonuxMenu
              ref={menuRef}
              closeMenuOnItemClicked
              closeOnBackdropClick
              className="motor-selection-restart-menu"
              size="small"
            >
              <CommonuxMenuItem
                data-rf="drive-reset-application"
                label="Reset Application"
                size="large"
                onClick={() => {
                  setIsApplicationModal(true);
                  setShowDeleteModal(true);
                }}
              ></CommonuxMenuItem>
              <CommonuxMenuItem
                label="Reset Drive"
                data-rf="reset-drive"
                size="large"
                onClick={() => {
                  setIsApplicationModal(false);
                  setShowDeleteModal(true);
                }}
              ></CommonuxMenuItem>
            </CommonuxMenu>
          </div>
        </div>
      </div>
      <div className="form">
        <div className="subtitle-container" slot="left">
          <div className="subtitle-header">
            {t(I18.drive_selection_drive_load_title)}
          </div>
          {driveLoadDropdownDataSet.length > 0 &&
            driveLoadDropdownDataSet?.map((dropdown) => {
              return (
                <div
                  className="select-container"
                  key={generateKey(dropdown.label)}
                >
                  <div className="select-header">{dropdown.label}</div>
                  <SelectContainer
                    size="small"
                    className="select-container-area"
                    disabled={isLoading}
                    key={generateKey(dropdown.label)}
                  >
                    <Select
                      slot="main"
                      required={true}
                      value={
                        updatedLoadFields?.[
                          dropdown.accessor as keyof DriveLoadData
                        ] ??
                        driveLoadDefaultData[
                          dropdown.accessor as keyof DriveLoadData
                        ]
                      }
                      onSelectChange={(event) =>
                        handleFormDataChange(
                          event.detail.value,
                          dropdown.accessor as keyof DriveSelectionResultData,
                          'load',
                        )
                      }
                      disabled={
                        resultData?.dimension?.dimensionInput
                          ?.selectionInputs?.[0]?.dataCategory === 'All' ||
                        resultData?.dimension?.dimensionInput
                          ?.selectionInputs?.[0]?.dataCategory === 'Motor' ||
                        dropdown.accessor === 'loadType' ||
                        parseFloat(motorLoadData?.power) > 0 ||
                        isLoading ||
                        hasMultipleOverload
                      }
                    >
                      <div
                        slot="options-main"
                        key={generateKey('drive-options')}
                      >
                        {dropdown.selectInput.map((inputOptions) => {
                          return (
                            <SelectOption
                              data-value={inputOptions.value}
                              optionText={inputOptions.label}
                              value={inputOptions.value}
                              selected={
                                inputOptions.value ===
                                updatedLoadFields?.[
                                  dropdown.accessor as keyof DriveLoadData
                                ]
                              }
                            />
                          );
                        })}
                      </div>
                    </Select>
                  </SelectContainer>
                </div>
              );
            })}
          <div className="drive-load-input-container">
            <Input
              label-text={t(I18.drive_selection_i_continuous_label)}
              className="drive-load-input"
              size="medium"
              valid={
                !!updatedLoadFields?.iContinuous?.toString() &&
                updatedLoadFields?.iContinuous?.toString() !== '0'
              }
              suffixText={t(I18.current_unit_label)}
              onInputInput={(event) =>
                handleFormDataChange(event.detail.value, 'iContinuous', 'load')
              }
              required={true}
              inputType={
                hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                  ? 'text'
                  : 'number'
              }
              value={updatedLoadFields?.iContinuous?.toString()}
              disabled={isLoading}
              trailed-icon="true"
              tooltip-text="Drive current at continuous load"
            />
            <Input
              label-text={t(I18.drive_selection_caluculated_value_label)}
              className="drive-load-input"
              size="medium"
              valid={true}
              suffixText={t(I18.current_unit_label)}
              readOnly={true}
              required={true}
              inputType="number"
              value={updatedLoadFields?.calculatedIContinuous}
              disabled={true}
            />
          </div>
          <div className="drive-load-input-container">
            <Input
              label-text={t(I18.drive_selection_i_maximum_label)}
              className="drive-load-input"
              size="medium"
              valid={
                !!updatedLoadFields?.iMax?.toString() &&
                updatedLoadFields?.iMax?.toString() !== '0'
              }
              suffixText={t(I18.current_unit_label)}
              onInputInput={(event) =>
                handleFormDataChange(event.detail.value, 'iMax', 'load')
              }
              required={true}
              inputType={
                hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                  ? 'text'
                  : 'number'
              }
              value={updatedLoadFields?.iMax?.toString()}
              disabled={isLoading}
              trailed-icon="true"
              tooltip-text="Drive current at maximum load"
            />
            <Input
              label-text={t(I18.drive_selection_caluculated_value_label)}
              className="drive-load-input"
              size="medium"
              valid={true}
              suffixText={t(I18.current_unit_label)}
              readOnly={true}
              required={true}
              inputType="number"
              value={updatedLoadFields?.calculatedIMaximum}
              disabled={true}
            />
          </div>
          <div className="overload-header">
            {t(I18.drive_selection_overload_title)}
          </div>
          <div className="drives-overload-time-info-container">
            <Input
              label-text={t(I18.drive_selection_time_label)}
              className="overload-input"
              size="medium"
              valid={true}
              suffixText={t(I18.time_second_unit_label)}
              onInputChange={(event) =>
                handleFormDataChange(event.detail.value, 'maxLoadTime', 'load')
              }
              required={true}
              inputType={
                hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                  ? 'text'
                  : 'number'
              }
              value={motorLoadData?.maxLoadTime?.toString()}
              disabled={hasMultipleOverload || isLoading}
            />
            <Input
              label-text={t(I18.drive_selection_every_label)}
              className="overload-input"
              size="medium"
              valid={true}
              suffixText={t(I18.time_second_unit_label)}
              onInputChange={(event) =>
                handleFormDataChange(
                  event.detail.value,
                  'dutyCycleTime',
                  'load',
                )
              }
              required={true}
              inputType={
                hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                  ? 'text'
                  : 'number'
              }
              value={updatedLoadFields?.dutyCycleTime?.toString()}
              disabled={hasMultipleOverload || isLoading}
            />
          </div>
          <div className="add-duty-cycle-button-container">
            <Button
              type="secondary"
              className="add-duty-cycle-button"
              onClick={handleAddDutyCycleClick}
              disabled={
                updatedLoadFields?.overLoadType === '2' || //'One-time'
                isLoading
                  ? true
                  : false
              }
            >
              <Icon
                slot="icon"
                size="small"
                name="trend-2"
                disabled={
                  updatedLoadFields?.overLoadType === '2' || //'One-time'
                  isLoading
                    ? true
                    : false
                }
              />
              {t(I18.drive_selection_add_duty_cycle_button)}
            </Button>
          </div>
        </div>
        <div className="motor-specification-container">
          <div className="motor-specification-header">
            {t(I18.drive_selection_drive_specifications_title)}
          </div>
          <div className="motor-specification-input-text">
            <div className="motor_name_container">
              <Input
                label-text={t(
                  I18.motor_selection_motor_specifications_name_label,
                )}
                size="medium"
                valid={true}
                className="motor-specification-input"
                value={updatedLoadFields?.driveName}
                onInputInput={(event) => {
                  handleFormDataChange(event.detail.value, 'driveName', 'load');
                }}
                disabled={isLoading}
              />
            </div>
            <div className="drive-count-container">
              <Input
                label-text={t(
                  I18.drive_selection_drive_specifications_no_of_drives_label,
                )}
                size="medium"
                valid={true}
                required={false}
                className="motor-specification-input"
                value={updatedLoadFields?.drivesCount?.toString() ?? '1'}
                onInputInput={(event) =>
                  handleFormDataChange(
                    event.detail.value,
                    'drivesCount',
                    'load',
                  )
                }
                inputType="number"
                disabled={isLoading}
              />
            </div>
          </div>

          <DriveSelectionDropdown
            handleFormDataChange={handleFormDataChange}
            isLoading={isDriveTypeDataLoading}
            isResultLoading={isLoading}
          />
        </div>
      </div>
      <DutyCycleModalDrive
        modalOpen={viewDutyCycle}
        onClose={onAddDutyCycleModalClose}
        onApplyClick={onAddDutyCycleModalApply}
        baseLoadData={baseLoadData}
      />
      <ManualSelectionModalDrive
        modalOpen={viewManualSelection}
        onClose={onManualSelectionClose}
        isManualSelectionLoading={isManualSelectionLoading}
        onSelectClick={onManualSelectClick}
      />
      <InformationModal
        modalTitle={isApplicationModal ? 'Reset application?' : 'Reset drive?'}
        modalContentText={
          isApplicationModal
            ? 'This will remove the selected drive and motor from the application.'
            : 'This will remove the selected drive from the application.'
        }
        modalOpen={showDeleteModal}
        buttonText="Reset"
        onClick={handleResetApplicationClick}
        onClose={() => {
          setShowDeleteModal(false);
        }}
      />
      <InformationModal
        modalTitle={'Back to guided selection?'}
        modalContentText={'This will discard your motor & drive sizing.'}
        modalOpen={showSelectionModal}
        buttonText="Go back"
        onClick={handleBackToSelectionClick}
        onClose={() => {
          setShowSelectionModal(false);
        }}
        isInfo={true}
      />
    </>
  );
};

export default DriveSelectionForm;
