import '../../src/layout/styles/PrivacyBanner.scss';
import Modal from './Modal';
import Button from './Form/Button';
import Checkbox from './Form/Checkbox';
import { CommonuxCheckboxCustomEvent } from '@abb-motion-ux/commonux-web-components';
import { useTranslate } from '../i18translate/Hooks';
import { I18 } from '../languages/I18';
import useDeviceDetect from '../hooks/useDeviceDetect';
import { GetCurrentCountry } from '../utils/GeneralUtils';

interface PrivacyBannerProps {
  modalOpen?: boolean;
  onClose?: () => void;

  handleAnalyticcheckvalue: (event: CommonuxCheckboxCustomEvent<any>) => void;
  preferences: boolean;
  analytics: boolean;
  setAllTheCheckBox: () => void;
  refuseAllClick: () => void;
  acceptSelectedClick: () => void;
  processData: boolean;
  thirdParty: boolean;
}

const PrivacyBanner = (props: PrivacyBannerProps) => {
  const { t } = useTranslate();
  const { isMobile } = useDeviceDetect();
  const currentCountryId = GetCurrentCountry().id;

  return (
    <Modal
      open={props.modalOpen}
      onModalClose={props.onClose}
      modalTitle={t(`privacy_banner_title`)}
      showCloseButton={false}
      type="discreet"
      backdropClick={false}
      className="privacy-banner-container"
      data-testid="privacy-banner-container"
    >
      <div
        className="privacy-banner-body"
        data-rf="privacybanner-modal-content"
        id="privacybanner-modal-content"
        slot="content"
      >
        <p className="privacy-banner-text">
          {t(`privacy_banner_body_general_text`)}
          <a
            href="https://new.abb.com/privacy-policy"
            rel="noreferrer"
            target="_blank"
          >
            {' '}
            {t(`privacy_banner_body_general_text_link`)} .
          </a>
        </p>
        <div className="privacy-banner-analytic">
          <Checkbox
            onCheckboxChange={(event) => props.handleAnalyticcheckvalue(event)}
            type="primary-red"
            size="medium"
            id="analytics"
            className="privacy-banner-checkbox"
            data-testid="privacy-banner-checkbox-analytics"
            checked={props.analytics}
          >
            {t(`privacy_banner_analytics_title`)}
            <p slot="content">{t(`privacy_banner_analytics_text`)}</p>
          </Checkbox>
        </div>
        <div className="sepratorLine" />
        <div className="privacy-banner-preferences">
          <Checkbox
            onCheckboxChange={(event) => props.handleAnalyticcheckvalue(event)}
            checked={props.preferences}
            type="primary-red"
            size="medium"
            id="preferences"
            className="privacy-banner-checkbox"
            data-testid="privacy-banner-checkbox-preferences"
          >
            {t(`privacy_banner_preference_title`)}
            <div slot="content">
              <p>{t(`privacy_banner_perferences_body`)}</p>
            </div>
          </Checkbox>
        </div>
        {currentCountryId === 'CN' && (
          <>
            <div className="sepratorLine" />
            <div className="privacy-banner-china-concent">
              <Checkbox
                onCheckboxChange={(event) =>
                  props.handleAnalyticcheckvalue(event)
                }
                type="primary-red"
                size="medium"
                id="process-data"
                className="privacy-banner-checkbox"
                checked={props.processData}
                data-testid="privacy-banner-checkbox-process-data"
              >
                <div slot="content">
                  <p>{t(`privacy_banner_chinese_concent`)}</p>
                </div>
              </Checkbox>
            </div>
            <div className="sepratorLine" />
            <div className="privacy-banner-china-concent">
              <Checkbox
                onCheckboxChange={(event) =>
                  props.handleAnalyticcheckvalue(event)
                }
                type="primary-red"
                size="medium"
                id="third-party"
                className="privacy-banner-checkbox"
                checked={props.thirdParty}
                data-testid="privacy-banner-checkbox-third-party"
              >
                <div slot="content">
                  <p>{t(`privacy_banner_chinese_data_transfer_concent`)}</p>
                </div>
              </Checkbox>
            </div>
          </>
        )}
      </div>
      <div className="privacy-banner-button-container" slot="footer">
        {!isMobile && (
          <Button
            type="tertiary"
            size="medium"
            onClick={() => props.acceptSelectedClick()}
            data-testid="accept-selected-button"
          >
            {t(`privacy_banner_acceptselected_button_title`)}
          </Button>
        )}
        <section>
          <Button
            type="tertiary"
            size="medium"
            onClick={() => props.refuseAllClick()}
            data-testid="refuseall-selected-button"
          >
            {t(`privacy_banner_refuseall_button_title`)}
          </Button>
          {isMobile && (
            <Button
              type="tertiary"
              size="medium"
              onClick={() => props.acceptSelectedClick()}
              data-testid="accept-selected-button"
            >
              {t(`privacy_banner_acceptselected_button_title`)}
            </Button>
          )}
          <Button
            type="primary-red"
            size="medium"
            onClick={() => props.setAllTheCheckBox()}
            data-testid="acceptall-selected-button"
          >
            {t(`privacy_banner_acceptall_button_title`)}
          </Button>
        </section>
      </div>
    </Modal>
  );
};

export default PrivacyBanner;
