import React, { useRef } from 'react'
import { DataGrid, DataGridCell, DataGridHeader, DataGridRow } from '../../../../components/DataGrid';
import Checkbox from '../../../../components/Form/Checkbox';
import Button from '../../../../components/Form/Button';
import Icon from '../../../../components/Icon';
import { CommonuxMenu, CommonuxMenuItem } from '@abb-motion-ux/commonux-web-components-react';
import { menuOpenEventhandler } from '../../../../components/Form/Menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

interface IDataGridComponentPlusCodes {
    allSelectPlusCodeOptions: (e: any) => void
    showAllCheckboxValues: boolean
    plusCodeValues: any
    setSinglePlusCode: (typcode: any) => void
    updateOfSimliarDrives: (options: any) => void
    handleCheckboxValues: (pluscode: any, e: any) => void
    setList: (list: any) => void
    setShowModifiedApplications: () => void
    setShowSelectCopyDriveModal: () => void
    setShowSinglePlusCodeDeleteModal: () => void

}

const DataGridComponentPlusCodes = ({
    setList,
    setShowModifiedApplications,
    setShowSelectCopyDriveModal,
    setShowSinglePlusCodeDeleteModal,
    allSelectPlusCodeOptions,
    plusCodeValues,
    setSinglePlusCode, showAllCheckboxValues, updateOfSimliarDrives, handleCheckboxValues
}: IDataGridComponentPlusCodes) => {
    const application = useSelector((state: RootState) => state.workspace.applicationDetails[state.workspace.currentAppRank])
    const applications = useSelector((state: RootState) => state.workspace.applicationDetails)
    const individualDataCellDropDownRef = useRef<
        HTMLCommonuxMenuElement[] | null[]
    >([]);
    return (
        <DataGrid
            divider="row"
            size="medium"
            type="primary-black"
            scrollable={false}
        >
            <DataGridRow is-header>
                <DataGridHeader colWidth={32} colResizable={false}>
                    <div slot="checkbox-container">
                        <Checkbox
                            size="small"
                            type="primary-black"
                            onCheckboxChange={allSelectPlusCodeOptions}
                            id="select-all-checkboxs"
                            checked={showAllCheckboxValues}
                            disabled={
                                plusCodeValues?.options?.length === 0 ? true : false
                            }
                        />
                    </div>
                </DataGridHeader>

                <DataGridHeader
                    label="CODE"
                    colResizable={false}
                    colWidth={120}
                />
                <DataGridHeader
                    label="DESCRIPTION"
                    colResizable={false}
                    colWidth={'100%'}
                />
                {/* commented as these are needed later and discussed same with design team */}
                <DataGridHeader label="" colResizable={false} colWidth={64} />
            </DataGridRow>
            <div className="grid-body">
                {plusCodeValues && plusCodeValues?.options?.length > 0 ? (
                    plusCodeValues?.options?.map(
                        (
                            typecode: {
                                plusCode: string;
                                description: string;
                                checked: boolean;
                            },
                            index: number,
                        ) => (
                            <>
                                <DataGridRow
                                    row-color={index % 2 === 0 ? 'white' : 'grey'}
                                    key={index}
                                    data-testid="data-grid-rows"
                                    className="data-grid"
                                >
                                    <DataGridCell showTooltip={false}>
                                        <div slot="checkbox-container">
                                            <Checkbox
                                                size="small"
                                                type="primary-black"
                                                onCheckboxChange={(e) =>
                                                    handleCheckboxValues(typecode.plusCode, e)
                                                }
                                                checked={typecode.checked}
                                                id={`plusCode-value-${index}`}
                                                data-testid="plus-code-checkbox"
                                                data-rf="plus-code-checkbox"
                                                slot="checkbox-container"
                                            />
                                        </div>
                                    </DataGridCell>
                                    <DataGridCell
                                        className="drivetype-pluscode"
                                        showTooltip={false}
                                    >
                                        {typecode.plusCode}
                                    </DataGridCell>
                                    <DataGridCell
                                        noOfLinesForTruncation={0}
                                        className="drivetype-pluscode-description"
                                        showTooltip={false}
                                    >
                                        {typecode.description}
                                    </DataGridCell>
                                    {applications?.length > 1 ?
                                        <DataGridCell
                                            className="icon-menu-container"
                                            showTooltip={false}
                                        >
                                            <Button
                                                type="discreet-black"
                                                size="xsmall"
                                                onBtnClick={() => {
                                                    menuOpenEventhandler(
                                                        individualDataCellDropDownRef.current[index],
                                                    )
                                                    setSinglePlusCode(typecode)
                                                }
                                                }
                                            >
                                                <Icon
                                                    name="menu-narrow"
                                                    size="small"
                                                    theme="dark"
                                                    slot="icon"
                                                ></Icon>
                                            </Button>
                                        </DataGridCell> : <DataGridCell
                                            className="icon-menu-container"
                                            showTooltip={false}
                                        ></DataGridCell>}
                                </DataGridRow>
                                {applications?.length > 1 && <>
                                    <CommonuxMenu
                                        ref={(el) =>
                                            (individualDataCellDropDownRef.current[index] = el)
                                        }
                                        closeOnBackdropClick
                                        closeMenuOnItemClicked
                                        onMouseLeave={() =>
                                            menuOpenEventhandler(
                                                individualDataCellDropDownRef.current[index],
                                                true,
                                            )
                                        }
                                        heading="COPY TO"
                                        size="large"
                                        className="indiviudal-copy-delete-plus-code-menu"
                                    >

                                        <CommonuxMenuItem
                                            onMouseDown={() => {
                                                updateOfSimliarDrives({ plusCode: typecode.plusCode, description: typecode.description })
                                            }}

                                            label={`All ${application?.dimensionDetails?.productTypeCode?.driveTypeCode?.split('-')?.[0]} drive type`}
                                        ></CommonuxMenuItem>
                                        <CommonuxMenuItem
                                            onMouseDown={() => {
                                                let options = [{ plusCode: typecode.plusCode, description: typecode.description, checked: true }]
                                                setList(options)
                                                setShowModifiedApplications()
                                                setShowSelectCopyDriveModal()
                                            }}
                                            label="Select drives"
                                        ></CommonuxMenuItem>
                                        <div className="line-seperator" />
                                        <CommonuxMenuItem
                                            onMouseDown={() => setShowSinglePlusCodeDeleteModal()}
                                            label="Delete"
                                        ></CommonuxMenuItem>
                                    </CommonuxMenu>
                                </>}

                            </>
                        ),
                    )
                ) : (
                    <></>
                )}
            </div>
        </DataGrid>
    )
}

export default DataGridComponentPlusCodes