import { useEffect, useState } from 'react';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import ProgressBar from '../../Efficiency/Components/ProgressBar';
import { DriveInputs } from '../../models/EfficiencyModel';
import CommonLoader from '../../../../components/Loader';
import { DriveRelativeLossChart } from '../../Efficiency/Components/DriveRelativeLossChart';
import { DriveLossChart } from '../../Efficiency/Components/DriveLossesChart';
import DriveStandardPointsTable from '../../Efficiency/Components/DriveStandardPointTable';
import { replaceYearText } from '../../../../utils/GeneralUtils';

export interface ErrorType {
  error: boolean;
  type: string;
}

const DriveEfficiencyReport = ({ dimensionData, efficiencyData, id }: any) => {
  const { t } = useTranslate();

  const efficiencyPoint = efficiencyData?.standardData?.filter(
    (x: { frequency: number; current: number }) => {
      if (x.frequency == 90 && x.current == 100) {
        return x;
      }
    }
  );

  const [isLoading, setIsLoading] = useState(false);
  const driveResult = efficiencyData;

  useEffect(() => {
    let drives =
      dimensionData?.selectMotorResult?.dimension?.dimensionResult
        ?.supplyDriveLoads[0]?.inverterUnit?.results;
    let dimDrivecode;
    if (drives && drives?.length > 0) {
      for (let drive of drives) {
        if (drive.name === 'TypeDesignation') {
          dimDrivecode = drive.value;
        }
      }
    }
    if (dimensionData.dimensionDataLoading) {
      setIsLoading(true);
    } else if (
      dimDrivecode &&
      driveResult?.driveTechnicalData?.driveTypeCode !== dimDrivecode
    ) {
      setIsLoading(true);
      let inputs = {} as DriveInputs;
      if (drives && drives?.length > 0) {
        for (let drive of drives) {
          if (drive.name === 'TypeDesignation') {
            inputs.driveTypeCode = drive.value;
          }
          if (drive.name === 'FrameType') {
            inputs.driveFrame = drive.value;
          }
        }
      }
    }
  }, [dimensionData.dimensionOutputData, dimensionData.dimensionDataLoading]);

  return (
    <div>
      {isLoading && (
        <CommonLoader
          size="xlarge"
          indicatorType={'circular'}
          progressType={'indeterminate'}
          progressValue={50}
          type={'primary-black'}
        />
      )}
      {!isLoading ?
        driveResult?.driveTechnicalData ?
          driveResult?.driveTechnicalData?.driveTypeCode ? (

            <div>
              <div
                data-rf="drive-losses-drive-typecode-header"
                className="motor-losses-motor-type-designation"
              >
                {driveResult.driveTechnicalData.driveTypeCode}{' '}
                {replaceYearText(driveResult?.driveTechnicalData?.rowIds, t('efficiency_losses_drive_page_standard_with_constant'))}
              </div>
              <div className="disp-flex efficiency-info-section">
                <div className="half-flex-chart" style={{ width: '50%' }}>
                  <div
                    className="ProductInfoContainer KeyValRow"
                    data-rf="drive-losses-iesclass"
                  >
                    <div className="ProductInfoContainer KeyValKey fs-16">
                      <img src="./assets/icon/drive-32.svg" alt='driveimage' />
                      {t(I18.efficiency_losses_drive_page_ieclass_heading)}
                    </div>
                    <div className="ProductInfoContainer KeyValValue fs-16">
                      {driveResult.driveTechnicalData.ieClass}
                    </div>
                  </div>

                  <div className="hr" />

                  <div
                    className="ProductInfoContainer KeyValRow"
                    data-rf="drive-losses-relative-losses"
                  >
                    <div className="ProductInfoContainer KeyValKey fs-16">
                      {t(I18.efficiency_losses_data_relative_losses)}
                    </div>
                    <div className="ProductInfoContainer KeyValValue fs-16">
                      {efficiencyPoint &&
                        efficiencyPoint.length > 0 &&
                        efficiencyPoint[0].relativeLoss +
                        ' ' +
                        efficiencyPoint[0].relativeLossUnit}
                    </div>
                  </div>

                  <div className="hr" />

                  <div
                    className="ProductInfoContainer KeyValRow"
                    data-rf="drive-losses-absolute-losses"
                  >
                    <div className="ProductInfoContainer KeyValKey fs-16">
                      {t(I18.efficiency_losses_data_absolute_losses)}
                    </div>
                    <div className="ProductInfoContainer KeyValValue fs-16">
                      {efficiencyPoint &&
                        efficiencyPoint.length > 0 &&
                        efficiencyPoint[0].absoluteLoss +
                        ' ' +
                        efficiencyPoint[0].absoluteLossUnit}
                    </div>
                  </div>

                  <div className="hr" />

                  <div>
                    <div
                      className="drive-losses-progress-bar"
                      data-rf="drive-losses-progress-bar"
                    >
                      <ProgressBar
                        bigText={true}
                        label={'Efficiency'}
                        progress={efficiencyPoint?.[0]?.efficiency}
                      />
                    </div>
                  </div>

                  <div className="hr" />
                </div>
                <div className="half-flex-info" style={{ width: '50%' }}>
                  <div className="ProductInfoContainer Description">
                    <div>
                      <strong>
                        {t(I18.efficiency_losses_data_cdm_description_strong) +
                          ' '}
                      </strong>
                      {replaceYearText(driveResult?.driveTechnicalData?.rowIds, t('efficiency_losses_data_cdm_description_with_constant'))}

                      {t(I18.efficiency_losses_data_cdm_description_sub)}
                    </div>

                    <div>
                      <strong>
                        {t(
                          I18.efficiency_losses_drive_page_relative_loss_description_strong
                        ) + ' '}
                      </strong>
                      {t(
                        I18.efficiency_losses_drive_page_relative_loss_description
                      )}
                    </div>

                    <div>
                      <strong>
                        {t(
                          I18.efficiency_losses_drive_page_efficiency_of_cdm_description_strong
                        ) + ' '}
                      </strong>
                      {t(
                        I18.efficiency_losses_drive_page_efficiency_of_cdm_description
                      )}
                    </div>

                    <div>
                      {t(
                        I18.efficiency_losses_drive_page_efficiency_of_cdm_description_sub1
                      )}
                    </div>

                    <div>
                      {t(
                        I18.efficiency_losses_drive_page_efficiency_of_cdm_description_sub2
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <DriveRelativeLossChart
                loading={isLoading}
                results={driveResult}
              />

              <div className="operating-points-table">
                <div
                  className="efficiency-page-headers"
                  data-rf="drive-losses-table-heading"
                >
                  {replaceYearText(driveResult?.driveTechnicalData?.rowIds, t('efficiency_losses_drive_page_standard_points_table_heading_with_constant'))}
                </div>
                <DriveStandardPointsTable
                  data={driveResult.standardData}
                  losses={driveResult.standBy + ' ' + driveResult.standByUnit}
                />
              </div>

              <div className="pds-charts-container" id="pdf-section">
                <div className="losses-chart">
                  <div
                    className="efficiency-page-headers"
                    data-rf="drive-losses-chart-heading"
                  >
                    {t(I18.cdm_absolute_losses_heading)}
                  </div>
                  <DriveLossChart
                    height={224}
                    loading={isLoading}
                    type="large"
                    standardPoints={driveResult.standardData}
                    partloadPoints={[]}
                    nominalFrequency={
                      driveResult?.driveTechnicalData?.nominalFrequencyRange
                    }
                    id={id}
                  />
                </div>
              </div>
            </div>

          ) : "" : "" : ""}
    </div>
  );
};

export default DriveEfficiencyReport;
