import { useEffect, useLayoutEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import {
    DataGrid,
    DataGridCell,
    DataGridHeader,
    DataGridRow,
} from '../../../../components/DataGrid';
import Checkbox from '../../../../components/Form/Checkbox';
import Button from '../../../../components/Form/Button';
import { useTranslate } from '../../../../i18translate/Hooks';
import { ApplicationDetails } from '../../models/WorkspaceModels';
import Tag from '../../../../components/Tag';
import { getMatchingApplications } from '../../../../utils/GeneralUtils';



interface SelectDriveModalProps {
    onClose: () => void;
    modalOpen: boolean;
    selectDriveNextButtonHandler: (selectedDrives: any) => void;
    modalTitle: string;
    applications: ApplicationDetails[];
    showModifiedApplication: boolean;
    selectedPlusCodes: any
    handlingBackOption: () => void;
}

const SelectDeleteDriveModal = ({ handlingBackOption, selectedPlusCodes, showModifiedApplication, applications, onClose, modalOpen, selectDriveNextButtonHandler }: SelectDriveModalProps) => {
    const { t } = useTranslate();

    const [selectDrive, setSelectDrive] = useState<{ driveTypeCode: string; checked: boolean; driveName: string | undefined; id: string,options:any }[]>([])
    const [showAllCheckboxValues, setShowAllCheckboxValues] = useState(false);
    const [enableCopyButton, setEnableCopyButton] = useState(false);

   

    useEffect(() => {
        let modifiedDrives;
        //get the applications which have drivetypecode and options that are simiar to selected plus codes

        if (selectedPlusCodes?.length > 0) {
            const ApplicationsWithselectedApplication = getMatchingApplications(applications, selectedPlusCodes)
            if (ApplicationsWithselectedApplication && ApplicationsWithselectedApplication.length > 0) {
                modifiedDrives = ApplicationsWithselectedApplication?.filter(application => application?.dimensionDetails?.productTypeCode?.driveTypeCode)?.filter(app => app.drivePlusCodeObject?.options && app.drivePlusCodeObject.options.length > 0)?.map(application => ({
                    driveTypeCode: application?.dimensionDetails?.productTypeCode?.driveTypeCode,
                    checked: false,
                    driveName: application?.name,
                    id: application?.id,
                    options: application.drivePlusCodeObject?.options || []
                }))
            }
            setSelectDrive(modifiedDrives || [])
        }


    }, [showModifiedApplication, selectedPlusCodes])


    const handleCheckboxValues = (id: string) => {

        const selectedDrive = selectDrive.map((drive) => {
            if (drive?.id === id) {
                drive.checked = !drive.checked;
            }
            return drive;
        })
        setSelectDrive(selectedDrive)
        const checkedDrive = selectDrive.filter(drive => drive.checked)
        checkedDrive.length === selectDrive.length ? setShowAllCheckboxValues(true) : setShowAllCheckboxValues(false);

    };

    const setAllCheckBox = (e: {
        target: { checked: boolean };
    }) => {
        const { checked } = e.target;

        const selectedDrive = selectDrive.map(drive => {
            drive.checked = checked;
            return drive;
        })
        setSelectDrive(selectedDrive)
        setShowAllCheckboxValues(checked)
    };

    useEffect(() => {
        selectDrive.some(drive => drive.checked) ? setEnableCopyButton(true) : setEnableCopyButton(false)
    }, [selectDrive])


    const nextButtonhandler = () => {
        const selectedDrives = selectDrive.filter(drive => drive.checked)
        selectDriveNextButtonHandler(selectedDrives)
    }



    return (
        <Modal
            onModalClose={onClose}
            open={modalOpen}
            backdropClick={false}
            modalTitle={'Select drives'}
            showCloseButton={true}
            type="discreet"
            showHederSeparator="true"
            className="copy-and-delete-plus-codes-modal"
            showBackButton={true}

        >
            <div className="select-drive-modal-content" slot="content">
                <div className="copied-results-breadcrumb-container delete-tag-container">
                    <div>
                        {selectedPlusCodes?.map((plusCodes: { plusCode: string }, index: number) => (
                            <Tag
                                className="copied-results-breadcrumb"
                                deleteButton={false}
                                type="primary-black"
                                key={plusCodes?.plusCode}
                            >
                                <div slot="tag">{'+'}{plusCodes?.plusCode}</div>
                            </Tag>
                        ))}
                    </div>

                    <div className="delete-label">
                        Delete these plus codes
                    </div>
                </div>



                <DataGrid
                    divider="row"
                    size="medium"
                    type="primary-black"
                    scrollable={false}
                >
                    <DataGridRow is-header>
                        <DataGridHeader colWidth={32} colResizable={false}>
                            <div slot="checkbox-container">
                                <Checkbox size="small" type="primary-black" checked={showAllCheckboxValues}
                                    onCheckboxChange={(e) => { setAllCheckBox(e) }}
                                />
                            </div>
                        </DataGridHeader>
                        <DataGridHeader colResizable={false} colWidth={'100%'}>
                            <div>APPLICATION NAME</div>

                        </DataGridHeader>
                        <DataGridHeader
                            label="DRIVE TYPE"
                            colResizable={false}
                            colWidth={'100%'}
                        />
                    </DataGridRow>
                    <div className="grid-body">
                        {selectDrive.map((drive, index) => (
                            <DataGridRow

                                className="data-grid"
                                key={drive.driveTypeCode}
                            >
                                <DataGridCell showTooltip={false}>
                                    <div slot="checkbox-container">
                                        <Checkbox
                                            id={drive?.id}
                                            onCheckboxChange={() =>
                                                handleCheckboxValues(drive.id)
                                            }
                                            size="small" type="primary-black" checked={drive.checked} />
                                    </div>

                                </DataGridCell>
                                <DataGridCell showTooltip={false}>
                                    {drive.driveName}
                                </DataGridCell>
                                <DataGridCell showTooltip={false} noOfLinesForTruncation={0}>
                                    {drive.driveTypeCode}
                                </DataGridCell>
                            </DataGridRow>
                        ))}

                    </div>
                </DataGrid>
            </div>
            <div slot="footer" className="copy-delete-plus-code-footer">
                <Button type="discreet-black" size="small" onClick={() => onClose()}>
                    {t('cancel_button')}
                </Button>

                <Button type="primary-black" size="small" onClick={() => nextButtonhandler()}
                    disabled={!enableCopyButton}>
                    {t('delete_button')}
                </Button>

            </div>
        </Modal>

    );
};

export default SelectDeleteDriveModal;
