import Icon from '../../../../components/Icon';
import Checkbox from '../../../../components/Form/Checkbox';
import Button from '../../../../components/Form/Button';
import {
  DataGrid,
  DataGridRow,
  DataGridCell,
  DataGridHeader,
} from '../../../../components/DataGrid';
import {
  useGetpluscodeByIdQuery,

} from '../../services/DrivePluscodeService';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  setApplicationActiveTabs,
  setconfigureDriveTabStatus,
  setEfficiencyTabStatus,
  setPlusCodeForSimilarDrives,
  setPlusCodeOptionValues,
  updateProductTypeDriveCode,
} from '../../store/WorkspaceReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import { useSession } from '../../../../utils/Auth';
import notificationManager from '../../../../utils/NotificationManager';
import { useNavigate } from 'react-router-dom';
import { deleteSessionStorageValue, generatePayload } from '../../../../utils/GeneralUtils';
import {
  plusCodeOptions,
  PlusCodesObject
} from '../../models/DrivePlusCodeModel';
import { appInsights } from '../../../../services/AppInsight';
import CopyPlusCodesModal from './CopyPlusCodesModal';
import React from 'react';
import CopyPlusCodeDropDowns from './CopyPlusCodeDropDowns';
import { menuOpenEventhandler } from '../../../../components/Form/Menu';
import {
  CommonuxMenu,
  CommonuxMenuItem,
} from '@abb-motion-ux/commonux-web-components-react';
import DrivePlusCodesLoader from './DrivePlusCodesLoader';
import { ApplicationDetails } from '../../models/WorkspaceModels';
import DeletePlusCodeConfirmationModal from './DeletePlusCodeConfirmationModal';
import DeletePlusCodesModal from './DeletePlusCodeModal';
import SelectDriveModal from './SelectDriveModal';
import ReviewPlusCodesModal from './ReviewPlusCodesModal';
import VerifyingPlusCodeModal from './VerifyingModal';
import SelectDeleteDriveModal from './SelectDeleteDriveModal';
import DeletePlusCodeVerificationModal from './DeletePlusCodesVerficitationModal';
import DeleteReviewPlusCodesModal from './DeleteReviewPlusCodeModal';
import { workspaceTabsObject } from '../../Dimensioning/models/WorkspaceTabModel';
import DataGridComponentPlusCodes from './DataGridComponentPlusCodes';

export interface IDriveResults {
  setPLusCodeValues: (plusCodeValues: any) => void;
  plusCodeValues: any | undefined | null;
  copyModalCancelButtonHandler?: (value: boolean) => void;
}

const DriveResults = ({ setPLusCodeValues, plusCodeValues, copyModalCancelButtonHandler }: IDriveResults) => {
  const navigate = useNavigate();
  const opencopyPlsuCodeRef = useRef(null);

  const currentAppRank = useSelector(
    (state: RootState) => state.workspace.currentAppRank,
  );
  const application = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[currentAppRank],
  );
  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );
  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );

  const applications = useSelector((state: RootState) => state.workspace.applicationDetails)

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView({
        name: 'Drive configurator page',
        pageType: 'Main page',
      });
    } else {
    }
  }, []);

  const { data, isSuccess, isLoading } =
    useGetpluscodeByIdQuery(application?.id, {
      skip: window.location.search ? false : true,
    });
  const [updateProject, results] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });


  const { session } = useSession();
  const user = session?.user as unknown as { email: string; groups: string[] };

  const dispatch = useDispatch();


  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showAllCheckboxValues, setShowAllCheckboxValues] = useState(false);
  const [copyOptionType, setCopyOptionType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCopyLoader, setShowCopyLoader] = useState(false)
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const [showValidationLoader, setShowValidationLoader] = useState(false);
  const [showSinglePlusCodeDeleteModal, setShowSinglePlusCodeDeleteModal] = useState(false)
  const [singlePlusCode, setSinglePlusCode] = useState<{ plusCode: string, description: string, checked: boolean }>()
  const [showSelectCopyDriveModal, setShowSelectCopyDriveModal] =
    useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [selectedDrives, setSelectedDrives] = useState<{ driveTypeCode: string; checked: boolean; driveName: string | undefined; id: string; }[]>([])
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showModifiedApplications, setShowModifiedApplications] = useState(false)
  const [showSelectDeleteModal, setShowSelectDeleteModal] = useState(false)
  const [showDeleteVerificationModal, setShowDeleteVerificationModal] = useState(false)
  const [showDeleteReviewModal, setShowDeleteReviewModal] = useState(false)
  const [validatedDrives, setValidatedDrives] = useState<any>([])
  const [showdeleteButtonLoader, setShowDeleteButtonLoader] = useState(false)
  const [list, setList] = useState<any>(null)
  useEffect(() => {
    application?.drivePlusCodeObject?.options &&
      addCheckboxValuetoOptions(application?.drivePlusCodeObject);

  }, [
    currentAppRank,
    application.workspaceSelectionTabs,
    application?.dimensionDetails?.productTypeCode?.driveTypeCode
  ]);
  useLayoutEffect(() => {
    const observer = new MutationObserver(() => {
      const backButton = document.querySelector('.back');

      if (backButton) {
        const buttonElement = backButton.querySelector('commonux-button');
        const iconElement = backButton.querySelector('commonux-icon');

        if (buttonElement && iconElement) {
          buttonElement.setAttribute('disabled', 'true');
          iconElement.setAttribute('disabled', 'true');
        }
      }
    })
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();


  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (
        window?.location?.search?.length > 0 &&
        window.location?.search?.split('&')[1].slice(6)
      ) {
        navigate('/workspace');
        deleteSessionStorageValue();
      }
    }, 2500);
    //when user clicked and came back without having configuration data.

    //when user is notautorized or when lvdrive page failed
    if (!session?.user?.access_token) {
      deleteSessionStorageValue();
    }
  }, [results.isSuccess, session]);
  useEffect(() => {
    if (!isLoading && isSuccess && data && data.options && data.options.length > 0) {
      addCheckboxValuetoOptions(data)
    }
  }, [isLoading, isSuccess, data])

  useEffect(() => {
    if (copyOptionType.length === 0 &&
      application?.workspaceSelectionTabs?.length === 0 && data && data?.options && data?.options?.length > 0) setShowCopyLoader(true)
  }, [application?.workspaceSelectionTabs?.length, data, copyOptionType])

  const updateProjectApiCall = (plusCodes?: PlusCodesObject, app?: ApplicationDetails) => {
    const dimensionInput =
      app?.dimensionDetails?.selectDriveResult?.dimension
        ?.dimensionInput?.selectionInputs?.[0];

    const updateProjectObject = {
      projectId:
        (projectDetails.projectId || window.sessionStorage.getItem('id')) ?? '',
      projectName:
        (projectDetails.projectName || window.sessionStorage.getItem('name')) ??
        '',
      jsonFileName: app?.id ?? '', //appid, //need to add dynamic 
      projectJson: JSON.stringify({
        dimensionSavedData: {
          dimensionInput,
          applicationOutput: {
            supplyDriveLoads:
              app?.dimensionDetails?.selectDriveResult?.dimension
                ?.dimensionResult?.supplyDriveLoads,
          },
          exceptionType:
            app?.dimensionDetails?.selectDriveResult?.exceptionType,
          message: application?.dimensionDetails?.selectDriveResult?.message,
        },
        selectionSavedData: {
          industryName: app?.selectionDetails?.industryName,
          applicationName: app?.selectionDetails?.applicationName,
        },
        efficiencySavedData: {
          motorId:
            app?.pdsEfficiencyDetails?.motorTechnicalData?.motorId ??
            app?.motorEfficiencyDetails?.motorTechnicalData?.motorId ??
            '',
          driveTypeCode:
            app?.pdsEfficiencyDetails?.pdsDriveDetails?.driveTypeCode ??
            app?.driveEfficiencyDetails?.driveTechnicalData
              ?.driveTypeCode ??
            '',
          udpPoints:
            app?.efficiencyUDPLossesdata?.map(
              (item: { data: { speed: any; torque: any } }) => ({
                speed: item?.data?.speed,
                torque: item?.data?.torque,
              }),
            ) ?? [],
          driveFrame: '',
        },
        plusOptions: plusCodes
          ? plusCodes
          : app?.drivePlusCodeObject?.options &&
            app?.drivePlusCodeObject?.options?.length > 0
            ? {
              longtypeCode: app?.drivePlusCodeObject?.longtypeCode,
              options: app?.drivePlusCodeObject?.options,
            }
            : null,
      }),
      transformerId: selectedTransformerDatas?.trafoId, //add dynamic
      applicationId: app?.id  // add dynamic 
    };

    updateProject({
      userToken: session ? session?.user?.access_token : undefined,
      projectData: updateProjectObject,
    }).catch((error) => {
      notificationManager.error(
        'Unable to autosave the project due to: ' + error.toString(),
      );
    });
  };
  //to add checkbox value to the data to display to the function
  const addCheckboxValuetoOptions = (plusCodes: {
    longtypeCode?: string | undefined;
    options: any;
  }) => {

    if (plusCodes?.longtypeCode && plusCodes?.longtypeCode?.length > 0) {
      const modifiedDataValue =
        plusCodes?.options &&
        plusCodes?.options?.map(
          (plusCodeOption: {
            longtypeCode?: string | undefined;
            options: any;
          }) => ({
            ...plusCodeOption,
            checked: false,
          }),
        );

      setPLusCodeValues({
        longtypeCode: plusCodes.longtypeCode,
        options: modifiedDataValue,
      });
    }
  };

  const allSelectPlusCodeOptions = (e: {
    target: { checked: boolean; id: string };
  }) => {
    const { checked } = e.target;

    const values = plusCodeValues?.options?.map((plusCodeValue: any) => ({
      ...plusCodeValue,
      checked: checked,
    }));

    setPLusCodeValues({
      longtypeCode: plusCodeValues?.longtypeCode,
      options: values,
    });

    plusCodeValues?.options?.length > 0 && checked
      ? setShowDeleteIcon(true)
      : setShowDeleteIcon(false);
    setShowAllCheckboxValues(checked);
    setShowModifiedApplications(checked)
  };


  const handleCheckboxValues = (
    plusCode: string,
    e: { target: { checked: boolean } },
  ) => {

    plusCodeValues?.options?.forEach(
      (plusCodeValue: { plusCode: string; checked: boolean }) => {
        if (plusCodeValue.plusCode === plusCode) {
          setPLusCodeValues((prevPlusCodeValues: any) => {
            const updatedOptions = prevPlusCodeValues?.options?.map(
              (item: { plusCode: string; checked: boolean }) =>
                item.plusCode === plusCode
                  ? { ...item, checked: e.target.checked } // Update checked value for the matching item
                  : item, // Keep other items unchanged
            );

            // Check if at least one option is checked
            const isAnyOptionChecked = updatedOptions.some(
              (item: { checked: boolean }) => item.checked,
            );
            setShowDeleteIcon(isAnyOptionChecked);
            // Update an additional state for the checked status
            isAnyOptionChecked ? setShowModifiedApplications(true) : setShowModifiedApplications(false)
            //number of selected options
            updatedOptions?.filter((item: { checked: boolean }) => item.checked)
              ?.length === prevPlusCodeValues?.options?.length
              ? setShowAllCheckboxValues(true)
              : setShowAllCheckboxValues(false);
            return {
              longtypeCode: plusCodeValues.longtypeCode,
              options: updatedOptions,
            };
          });
        }
      },
    );
  };

  const updateCurrentDriveOptions = () => {
    let plusCodes;
    setModalOpen(false)
    if (data && data?.options && data?.options?.length > 0) {
      plusCodes = {
        longtypeCode: data?.longTypeCode,
        options: data?.options,
      };
      addCheckboxValuetoOptions(plusCodes);
    }
    dispatch(setPlusCodeOptionValues(plusCodes));
    dispatch(updateProductTypeDriveCode(plusCodes?.longtypeCode));
    updateProjectApiCall(plusCodes, application)
    notificationManager.success('Plus codes has been copied to drive(s).');
  }

  const updateOfSimliarDrivesFromCopyModal = () => {
    //check the typecode
    setModalOpen(false)
    //remove sometime which is not the current applicaitions

    setShowCopyLoader(true)
    const currentAppDriveFamily = application?.dimensionDetails?.productTypeCode?.driveTypeCode.split('-')?.[0]
    const plusCodes = data?.options?.map((option) => option.plusCode)
    let plusCodesString = ""
    for (let i = 0; plusCodes && i < plusCodes.length; i++) {
      plusCodesString += plusCodes[i];
      if (i < plusCodes.length - 1) {
        plusCodesString += "+";
      }
    }
    //filter the applications having drive typecode or sized
    const filteredApplications = applications.filter(application => application?.dimensionDetails?.productTypeCode?.driveTypeCode)
    filteredApplications.forEach((app) => {
      if (app?.dimensionDetails?.productTypeCode?.driveTypeCode?.split('-')?.[0] === currentAppDriveFamily) {
        //if the pluscodes already exists then 
        if (app?.drivePlusCodeObject?.options && app?.drivePlusCodeObject?.options?.length > 0) {

          const combinedTypeCode = app?.dimensionDetails?.productTypeCode?.driveTypeCode + '+' + plusCodesString
          const removeDuplicatesFromCombiendTypeCode = combinedTypeCode.split('+').filter((item, index, self) => self.indexOf(item) === index).join('+')
          const combinedOptions = [
            ...(app?.drivePlusCodeObject?.options || []),
            ...(data?.options || [])
          ];
          const uniqueOptions = combinedOptions.filter((item: any, index: number, self: any) =>
            index === self.findIndex((t: any) => (t.plusCode === item.plusCode))
          );
          const plusCodes = {
            longtypeCode: removeDuplicatesFromCombiendTypeCode,
            options: uniqueOptions,
          };
          if (application?.id === app.id) {

            dispatch(setPlusCodeForSimilarDrives({
              plusCodes: {
                longtypeCode: data?.longTypeCode,
                options: data?.options
              }, id: app?.id
            }))
            addCheckboxValuetoOptions(plusCodes);
            updateProjectApiCall({
              longtypeCode: data?.longTypeCode,
              options: data?.options ?? [],
            }, app)
          } else {
            dispatch(setPlusCodeForSimilarDrives({ plusCodes, id: app?.id }))
            updateProjectApiCall(plusCodes, app)
          }

        }

        else {
          const plusCodes = {
            longtypeCode: data?.longTypeCode,
            options: data?.options,
          };
          dispatch(setPlusCodeForSimilarDrives({ plusCodes, id: app?.id }))
          data?.options?.length && dispatch(
            setApplicationActiveTabs([
              workspaceTabsObject['configureDriveTabData'],
            ]),
          );
          updateProjectApiCall(plusCodes, app)
          if (plusCodes.longtypeCode && plusCodes.options) {
            addCheckboxValuetoOptions(plusCodes);
          }

        }
        setShowCopyLoader(false)

      }

      else {
        console.log('not match found', app?.dimensionDetails?.productTypeCode?.driveTypeCode)
      }
    })
    notificationManager.success('Plus codes have been copied to drive(s).')

  }

  const filteredTypeCodes = (longtypeCode: any, plusCodeValues: any) => {
    let typeCode = '';
    const selectedPlusCodes = plusCodeValues
      ?.filter((option: any) => option.checked)
      ?.map((option: any) => option.plusCode) || [];

    const typecodeArray = longtypeCode.split('+');

    const combinedArray = [...typecodeArray, ...selectedPlusCodes];

    const uniqueCodes: any = Array.from(new Set(combinedArray)); // Remove duplicates

    uniqueCodes.forEach((plusCode: any) => {
      if (typeCode) {
        typeCode += '+';
      }
      typeCode += plusCode;
    });

    return typeCode;
  };

  const filteredPlusCodeValues1 = (plusCodeValues: { options: any[]; }, options: any[] | undefined) => {
    if (!plusCodeValues?.options) {
      return options ? options.map(item => ({ ...item })) : [];
    }

    const selectedOptions = plusCodeValues.options.filter(option => option.checked);

    let newOptions = options ? options.map(item => ({ ...item })) : [];

    selectedOptions.forEach(e => {
      const exists = newOptions.some(item => item.plusCode === e.plusCode);
      if (!exists) {
        newOptions.push({ ...e });
      }
    });

    return newOptions.map(item => {
      const { checked, ...rest } = item;
      return rest;
    });
  };

  const updateOfSimliarDrives = (option: any) => {
    const modifiedApplicationList = applications.filter(app => app.id !== application.id)
    const currentAppDriveFamily = application?.dimensionDetails?.productTypeCode?.driveTypeCode.split('-')?.[0]

    modifiedApplicationList.forEach((app: ApplicationDetails) => {

      if (app?.dimensionDetails?.productTypeCode?.driveTypeCode?.split('-')?.[0] === currentAppDriveFamily) {
        const mergedOptions = mergeAndDedupeOptions(app?.drivePlusCodeObject?.options, option)
        const plusCodes = {
          longtypeCode: removeDuplicatesFromTypeCodecombinedTypeCode(app?.drivePlusCodeObject?.longtypeCode + '+' + option?.plusCode),
          options: mergedOptions
        }
        dispatch(setPlusCodeForSimilarDrives({ plusCodes, id: app?.id }))
        mergedOptions?.length > 0 && dispatch(
          setApplicationActiveTabs([
            workspaceTabsObject['configureDriveTabData'],
          ]),
        );
        updateProjectApiCall(plusCodes, app)

      }
    })
    notificationManager.success('Plus codes have been copied to drive(s).')
  }
  const addingsimilarCopyfromSelectedPlusCodes = () => {
    const copyOfPlusCodes = plusCodeValues?.options.map((plusCode: any) => JSON.parse(JSON.stringify(plusCode)));


    if (plusCodeValues.options && plusCodeValues.options.length > 0) {
      let filteredPlusCodeValues = copyOfPlusCodes?.filter(
        (plusCodeValue: { checked: boolean }) => plusCodeValue.checked,
      );

      let code: string = '';
      filteredPlusCodeValues.forEach((e: { plusCode: string }) => {
        code += e.plusCode + '+';
      });
      const modifiedApplicationList = applications.filter(appList => appList.id !== application.id);
      modifiedApplicationList.forEach((app: ApplicationDetails) => {
        if (app?.dimensionDetails?.productTypeCode?.driveTypeCode?.split('-')?.[0] === application?.dimensionDetails?.productTypeCode?.driveTypeCode.split('-')?.[0]) {
          const mergedOptions = filteredPlusCodeValues1({ options: copyOfPlusCodes }, app?.drivePlusCodeObject?.options)
          const plusCodes = {
            longtypeCode: filteredTypeCodes(app?.dimensionDetails?.productTypeCode?.driveTypeCode, copyOfPlusCodes),
            options: mergedOptions
          };

          dispatch(setPlusCodeForSimilarDrives({
            plusCodes, id: app?.id, applicationName: app?.name
          }));
          mergedOptions?.length > 0 && dispatch(
            setApplicationActiveTabs([
              workspaceTabsObject['configureDriveTabData'],
            ]),
          )
          updateProjectApiCall(plusCodes, app);
        }

      });
      notificationManager.success('Plus codes have been copied to drive(s).')
    }

  }
  const verficationNextButtonHanlder = (drives: any) => {
    //set the reviewModal to be true
    setSelectedDrives(drives)
    setShowValidationModal(false)
    setShowReviewModal(true)
  }

  const addPlusCodesToSelectedDrives = () => {
    setShowSelectCopyDriveModal(true)
  }
  const addPlusCodesToAllDrives = () => {
    setShowValidationModal(true)
  }

  const reviewApplyPluscodes = (selectedApplicationDrives: any) => {
    const isValidSelectedApplications = selectedApplicationDrives.filter((driveApp: any) => driveApp.isValid).filter((checkedDrives: { checked: boolean; }) => checkedDrives.checked)
    const selectedDrivesApplication = filterMatchingObjects(applications, isValidSelectedApplications)

    if (data && data?.options && data?.options?.length > 0) {
      //selectedDrives is getting empty
      selectedDrivesApplication.forEach((app: ApplicationDetails) => {
        //for present application 
        const combinedTypeCode = app?.dimensionDetails?.productTypeCode?.driveTypeCode + '+' + data?.options?.map(code => code.plusCode).join('+')
        const combinedOptions = [
          ...(app?.drivePlusCodeObject?.options || []),
          ...(data?.options || [])
        ];
        const uniquePlusCodes = getUniqueOptions(combinedOptions)
        const plusCodes = {
          longtypeCode: removeDuplicatesFromTypeCodecombinedTypeCode(combinedTypeCode),
          options: uniquePlusCodes,
        };
        if (application.id === app.id) {
          addCheckboxValuetoOptions(data)
          dispatch(setPlusCodeForSimilarDrives({
            plusCodes: {
              longtypeCode: data?.longTypeCode,
              options: data.options,
            }, id: app?.id
          }))
          updateProjectApiCall(plusCodes, app)
        }
        else {
          dispatch(setPlusCodeForSimilarDrives({ plusCodes, id: app?.id }))
          updateProjectApiCall(plusCodes, app)
        }
        setShowCopyLoader(false)

      })
      notificationManager.success('Plus codes have been copied to drive(s).')
    }
    else {
      const modifiedPlusCodeOptions = plusCodeValues?.options?.filter((option: { checked: any; }) => option.checked)
      const modifiedLongType = modifiedPlusCodeOptions?.map((item: { plusCode: string; }) => item.plusCode).join('+')
      const modifieddOptions = modifiedPlusCodeOptions

      selectedDrivesApplication.forEach((app: ApplicationDetails) => {
        if (app?.dimensionDetails?.productTypeCode?.driveTypeCode) {
          const combinedLongTypeCode = app?.dimensionDetails?.productTypeCode?.driveTypeCode + '+' + modifiedLongType
          const combinedOptions = [
            ...(modifieddOptions || []),
            ...(app?.drivePlusCodeObject?.options || [])
          ];
          const uniquePlusCodes = getUniqueOptions(combinedOptions)
          const plusCodes = {
            longtypeCode: removeDuplicatesFromTypeCodecombinedTypeCode(combinedLongTypeCode),
            options: uniquePlusCodes
          }
          dispatch(setPlusCodeForSimilarDrives({ plusCodes, id: app?.id }))
          uniquePlusCodes?.length > 0 && dispatch(
            setApplicationActiveTabs([
              workspaceTabsObject['configureDriveTabData'],
            ]),
          )
          updateProjectApiCall(plusCodes, app)
        }
      })
      notificationManager.success('Plus codes have been copied to drive(s).')
    }

    setShowReviewModal(false)
    const values = plusCodeValues?.options?.map((plusCodeValue: any) => ({
      ...plusCodeValue,
      checked: false
    }))

    setPLusCodeValues({
      longtypeCode: plusCodeValues?.longtypeCode,
      options: values,
    })

    setShowAllCheckboxValues(false)
    setShowDeleteIcon(false)
    setList([])
  }

  const updatePlusCodesAfterDeleting = ({ filteredLongTypeCode, filteredPlusCodeValues }: any) => {


    addCheckboxValuetoOptions({
      longtypeCode: filteredLongTypeCode,
      options: filteredPlusCodeValues,
    });
    dispatch(
      setPlusCodeOptionValues({
        longtypeCode: filteredLongTypeCode,
        options: filteredPlusCodeValues,
      }),
    );
    dispatch(updateProductTypeDriveCode(filteredLongTypeCode));

    setShowDeleteIcon(false);
    setShowAllCheckboxValues(false);

    updateProjectApiCall({
      longtypeCode: filteredLongTypeCode,
      options: filteredPlusCodeValues,
    }, application);

    setShowDeleteLoader(false)
    notificationManager.success('Plus codes has been deleted.');

  }
  const getTheSelectedPlusCodes = () => {
    return plusCodeValues?.options?.filter((option: { checked: any; }) => option.checked).map(
      (item: { checked: any }) => {
        delete item.checked;
        return item;
      },
    );
  }
  const handleDelete = async () => {

    let filteredPlusCodeValues = plusCodeValues?.options?.filter(
      (plusCodeValue: { checked: boolean }) => !plusCodeValue.checked,
    );

    const checkedItemPlusCodes = plusCodeValues?.options
      .filter(
        (plusCodeValue: { checked: boolean }) => plusCodeValue.checked === true,
      )
      .map((plusCodeValue: { plusCode: any }) => plusCodeValue.plusCode)
      .join(' ');
    const longTypeCodeComponents =
      plusCodeValues?.longtypeCode && plusCodeValues?.longtypeCode.split('+');
    const filteredLongTypeCode =
      longTypeCodeComponents &&
      longTypeCodeComponents
        .filter((component: any) => !checkedItemPlusCodes.includes(component))
        .join('+');

    filteredPlusCodeValues = filteredPlusCodeValues.map(
      (item: { checked: any }) => {
        delete item.checked;
        return item;
      },
    );
    if (filteredPlusCodeValues?.length !== plusCodeValues?.options?.length) {
      if (filteredLongTypeCode) {
        // if it is valid
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestbody = {
          payload: JSON.stringify(generatePayload(filteredLongTypeCode, { userEmail: user?.email, userLevel: user?.groups?.[0] })),
          isPROD: process.env.REACT_APP_IS_PROD ?? false
        }
        const requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(requestbody),
        };
        const response = await fetch(`${process.env.REACT_APP_NODE_SERVER_ROOT}/validationRequest`, requestOptions)
        setShowDeleteLoader(false)
        setShowValidationLoader(true)
        const data = await response.json();
        if (data.valid) {

          setShowValidationLoader(false)
          // setShowDeleteLoader(true)
          if (data.confirmedProductId === plusCodeValues.longtypeCode) {
            notificationManager.info("Plus codes selected can't be deleted.");

            return
          }
          setShowAllCheckboxValues(false)
          setShowDeleteIcon(false)
          data.options.map((option: any) => {
            handleCheckboxValues(option.plusCode, { target: { checked: false } })

          })
          updatePlusCodesAfterDeleting({ filteredLongTypeCode: data.confirmedProductId, filteredPlusCodeValues: data?.options })
        }
        else {
          notificationManager.error(data.error)
          setShowValidationLoader(false)


        }
      }

    }
  };





  const handleCurrentDriveDelete = () => {
    setShowDeleteLoader(true)
    //trigger validation 
    //on the response of validation
    handleDelete()

  }


  const handleSimilarDriveDelete = () => {
    setShowDeleteLoader(true)
    const getTheSelectedPlusCode =  plusCodeValues?.options?.filter(
      (plusCodeValue: { checked: boolean }) => plusCodeValue.checked,
    );
    //selected application drive family
    const checkedItemPlusCodes = getTheSelectedPlusCode
      .map((plusCodeValue: { plusCode: any }) => plusCodeValue.plusCode)
      .join(' ');

    const currentAppDriveFamily = application?.dimensionDetails?.selectDriveResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit?.results?.[0]?.value?.split('-')?.[0]

    const similarApplications = applications.filter(app => app?.dimensionDetails?.selectDriveResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit?.results?.[0]?.value?.split('-')?.[0] === currentAppDriveFamily)
    similarApplications.forEach(async (app) => {


      let modifiedLongTypeCode = app?.drivePlusCodeObject?.longtypeCode?.split('+').filter((e: any) => !checkedItemPlusCodes.includes(e)).join('+');
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestbody = {
        payload: JSON.stringify(generatePayload(modifiedLongTypeCode || '', { userEmail: user?.email, userLevel: user?.groups?.[0] })),
        isPROD: process.env.REACT_APP_IS_PROD ?? false
      }

      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestbody),
        redirect: "follow" as RequestRedirect
      };

      const response = await fetch(`${process.env.REACT_APP_NODE_SERVER_ROOT}/validationRequest`, requestOptions);
      const data = await response.json();
      setShowDeleteLoader(false)
      setShowDeleteIcon(false)
      setShowAllCheckboxValues(false)
      if (data.valid && data.error.length === 0) {
        
        if (app?.id === application?.id) {
          //get the modifiedcode and the results 
          //compare the present and 
          addCheckboxValuetoOptions({
            longtypeCode: data.confirmedProductId,
            options: data?.options
          })
          notificationManager.success('Plus codes has been deleted.');
        }
        updateProjectApiCall(
          {
            longtypeCode: data.confirmedProductId,
            options: data?.options
          },
          app
        )
        dispatch(setPlusCodeForSimilarDrives({
          plusCodes: {
            longtypeCode: data.confirmedProductId,
            options: data?.options
          }, id: app.id
        }))

      } else {
        if (app?.id === application?.id) notificationManager.error("Plus codes that failed couldn't deleted");
      }
    })

  }

  const deletePlusCodesToSelectedDrives = (drives: any) => {
    setShowDeleteLoader(true)
    setShowDeleteReviewModal(false)
    //delete the selected pluscodes from the selected drives
    const isValiedDrives = drives.filter((drive: { isValid: boolean }) => drive.isValid)
      .filter((checkedDrives: { checked: boolean; }) => checkedDrives.checked)
    const selectedDriveApplications = applications.map(app => { // use map instead of filter, to copy all objects.
      const matchingDrive = isValiedDrives.find((drive: { id: string; options: any }) => drive.id === app.id);

      if (matchingDrive) {
        // Create a copy of the application object
        const newApp = { ...app };
        if (newApp.drivePlusCodeObject) {
          newApp.drivePlusCodeObject = { ...newApp.drivePlusCodeObject }
        }
        if (newApp.drivePlusCodeObject && newApp.drivePlusCodeObject.options) {
          newApp.drivePlusCodeObject.options = matchingDrive.options;
          newApp.drivePlusCodeObject.longtypeCode = matchingDrive.typecode;
        }
        return newApp; // Return the modified copy
      }

      return app; // Return the original application object if no match is found
    }).filter(app => isValiedDrives.some((drive: { id: string; }) => drive.id === app.id));
    let plusCodes;
    selectedDriveApplications.forEach(app => {
      plusCodes = {
        longtypeCode: app?.drivePlusCodeObject?.longtypeCode,
        options: app?.drivePlusCodeObject?.options
      }

      dispatch(setPlusCodeForSimilarDrives({ plusCodes, id: app.id }))
      updateProjectApiCall(plusCodes
        , app)
      if (plusCodes && app?.id === application?.id) {
        addCheckboxValuetoOptions(plusCodes)
        notificationManager.success('Plus codes has been deleted.');
      }
    })
    setShowAllCheckboxValues(false)
    setShowDeleteIcon(false)
    setTimeout(() => {
      setShowDeleteLoader(false)
    }, 1000)
  }
  const deletePlusCodesToAllDrives = () => {
    setShowDeleteVerificationModal(true)
    const selectedDrives = applications?.filter((app) => app?.dimensionDetails?.productTypeCode?.driveTypeCode)?.map(app => ({
      driveTypeCode: app?.dimensionDetails?.productTypeCode?.driveTypeCode, checked: false, driveName: app?.name,
      id: app?.id
    }))

    setSelectedDrives(selectedDrives)
  }

  const deleteSinglePlusCodefromAllDrives = async () => {
    setShowDeleteButtonLoader(true)
    let plusCodes = plusCodeValues.options?.filter((plusCodeOption: { plusCode: string; }) =>
      plusCodeOption?.plusCode === singlePlusCode?.plusCode)

    let longTypeCode = plusCodeValues.longtypeCode
    plusCodes?.forEach((item: { plusCode: string; }) => {

      let escapedCode = item.plusCode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters

      let regex = new RegExp(`\\+${escapedCode}\\b`, "i"); // Ensure it matches whole word after '+'
      longTypeCode = longTypeCode.replace(regex, "").trim();
    });
    if (longTypeCode) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestbody = {
        payload: JSON.stringify(generatePayload(longTypeCode, { userEmail: user?.email, userLevel: user?.groups?.[0] })),
        isPROD: process.env.REACT_APP_IS_PROD ?? false
      }
      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestbody),
        redirect: "follow" as RequestRedirect
      };

      const response = await fetch(`${process.env.REACT_APP_NODE_SERVER_ROOT}/validationRequest`, requestOptions);
      const data = await response.json();
      if (data.valid) {
        setShowDeleteButtonLoader(false)
        updatePlusCodesAfterDeleting({ filteredLongTypeCode: data.confirmedProductId, filteredPlusCodeValues: data.options })
        setShowSinglePlusCodeDeleteModal(false)
      } else {
        //add notification
        notificationManager.error("Plus codes that failed couldn't deleted");
        setShowDeleteButtonLoader(false)
      }

    }

  }
  //to handle the copydropdownfunctins to redirect to which option the user has selected
  const copyOptionsSelected = (value: string) => {
    setCopyOptionType(value);

    switch (value) {
      case 'Current drive':
        !showDeleteModal ? updateCurrentDriveOptions() : handleCurrentDriveDelete()
        break;

      case 'Similar drives':
        //update all the drives with similar name

        !showDeleteModal ? updateOfSimliarDrivesFromCopyModal() : handleSimilarDriveDelete()
        break;

      case 'Select drive':
        !showDeleteModal && addPlusCodesToSelectedDrives()
        break;

      case 'All drives':
        !showDeleteModal ? addPlusCodesToAllDrives() : deletePlusCodesToAllDrives()
        break;
    }

  };
  const filterMatchingObjects = (applications: any, driveData: any) => {
    return applications.filter((app: { id: any; }) => {
      return driveData.some((drive: { id: any; }) => drive.id === app.id);
    });
  }

  const removeDuplicatesFromTypeCodecombinedTypeCode = (combinedTypeCode: string) => {
    const removeDuplicatesFromCombiendTypeCode = combinedTypeCode.split('+').filter((item, index, self) => self.indexOf(item) === index).join('+')
    return removeDuplicatesFromCombiendTypeCode ?? ''
  }
  const getUniqueOptions = (combinedOptions: any) => {
    const uniqueOptions = combinedOptions.filter((item: any, index: number, self: any) =>
      index === self.findIndex((t: any) => (t.plusCode === item.plusCode))
    );

    return uniqueOptions

  }

  const mergeAndDedupeOptions = (existingOptions: any, newOptions: any) => {
    if (!existingOptions) {
      return newOptions || []; // Return newOptions if existingOptions is nullish
    }

    if (!newOptions) {
      return existingOptions; // Return existingOptions if newOptions is nullish
    }

    const mergedOptions = [...existingOptions]; // Create a copy of existingOptions

    if (Array.isArray(newOptions)) { // Check if newOptions is an array
      newOptions.forEach(newOption => {
        const existingIndex = mergedOptions.findIndex(
          existingOption => existingOption.plusCode === newOption.plusCode
        );
        if (existingIndex === -1) {
          mergedOptions.push(newOption); // Add if not found
        }
      });
    } else { // newOptions is a single object
      const existingIndex = mergedOptions.findIndex(
        existingOption => existingOption.plusCode === newOptions.plusCode
      );
      if (existingIndex === -1) {
        mergedOptions.push(newOptions);
      }
    }

    return mergedOptions;
  }



  return (
    <>
      <div className="drive-config-results">
        {showCopyLoader && (
          <DrivePlusCodesLoader label={'Copying plus codes'} />
        )}

        {showDeleteLoader && (
          <DrivePlusCodesLoader label={'Deleting plus codes'} />
        )}

        {showValidationLoader && (
          <DrivePlusCodesLoader label={'Validating plus codes'} />
        )}

        {<div className="selection-header">
          <h5 className="selection-title">Selected options</h5>
        </div>}


        <section className="drive-result-table">
          <div className="drive-result-table-header">
            {plusCodeValues && plusCodeValues?.options?.length > 0 ? (
              <p>{plusCodeValues?.options?.length} OPTION{plusCodeValues?.options?.length > 1 ? 's' : ''} ADDED</p>
            ) : (
              <p>No Pluscodes Added</p>
            )}
            <section className="drive-result-table-icons">
              {showDeleteIcon && (
                <React.Fragment>
                  {applications?.filter((app) => app?.dimensionDetails?.productTypeCode?.driveTypeCode).length > 1 && (
                    <div className="copy-icon-container">
                      <Button
                        size="small"
                        type="discreet-black"
                        className="goselect-d-flex goselect-flex-gap-8"
                        onBtnClick={() =>
                          menuOpenEventhandler(opencopyPlsuCodeRef.current)
                        }
                      >
                        <Icon name="copy" size="small" slot="icon" />
                        <Icon name="down" size="small" slot="menu" />
                      </Button>
                    </div>
                  )}


                  <div className="delete-icon-container">
                    <Button
                      size="small"
                      type="discreet-black"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      <Icon name="trash" size="small" />
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </section>
          </div>

          <DataGridComponentPlusCodes
            allSelectPlusCodeOptions={allSelectPlusCodeOptions}
            showAllCheckboxValues={showAllCheckboxValues}
            plusCodeValues={plusCodeValues}
            setSinglePlusCode={(typecode) => setSinglePlusCode(typecode)}
            updateOfSimliarDrives={updateOfSimliarDrives}
            handleCheckboxValues={handleCheckboxValues}
            setList={(list) => setList(list)}
            setShowModifiedApplications={() => setShowModifiedApplications(true)}
            setShowSelectCopyDriveModal={() => setShowSelectCopyDriveModal(true)}
            setShowSinglePlusCodeDeleteModal={() => setShowSinglePlusCodeDeleteModal(true)}
          />
        </section>


      </div>

      <CopyPlusCodeDropDowns
        copyPlusCodeRef={opencopyPlsuCodeRef}
        menuheader="COPY TO"
        mouseLeaveHandler={async () =>
          menuOpenEventhandler(opencopyPlsuCodeRef.current, true)
        }
      >
        <CommonuxMenuItem
          onMouseDown={() => {

            addingsimilarCopyfromSelectedPlusCodes()
          }}
          label={`All ${application?.dimensionDetails?.productTypeCode?.driveTypeCode?.split('-')?.[0]} drive type`}
        ></CommonuxMenuItem>
        <CommonuxMenuItem
          onMouseDown={() => setShowSelectCopyDriveModal(true)}
          label="Select drives"
        ></CommonuxMenuItem>
      </CopyPlusCodeDropDowns>


      {showCopyLoader ? (
        <CopyPlusCodesModal
          modalTitle={'Copy plus codes'}
          pluscodeOptions={data}
          optionSelected={copyOptionsSelected}
          copiedoptiontype={copyOptionType}
          onClose={() => {
            setShowCopyLoader(false)
            setModalOpen(true)
            copyModalCancelButtonHandler?.(true)
          }
          }
          modalOpen={!modalOpen}
          nextButtonHandler={() => {
            setModalOpen(true)
            setShowSelectCopyDriveModal(true)
          }}
        />
      ) : null}

      {showSelectCopyDriveModal && (
        <SelectDriveModal
          modalOpen={showSelectCopyDriveModal}
          onClose={() => {
            setShowSelectCopyDriveModal(false)
            copyModalCancelButtonHandler?.(true)
            setShowCopyLoader(false)
          }}
          selectDriveNextButtonHandler={(drives) => {

            setShowSelectCopyDriveModal(false)
            setShowValidationModal(true)
            setSelectedDrives(drives)
          }}
          modalTitle={"Select drives"}
          applications={applications}
          showModifiedApplication={
            showModifiedApplications
          }
          backButtonHandler={() => {
            setShowSelectCopyDriveModal(false)
          }}
        />
      )}
      {showValidationModal &&
        <VerifyingPlusCodeModal
          modalOpen={showValidationModal}
          onClose={() => {
            setShowValidationModal(false);
            copyModalCancelButtonHandler?.(true);
            if (data && data.options) {
              setShowCopyLoader(false)
            }
          }}
          nextButtonhandler={verficationNextButtonHanlder}
          nextbuttondisable={false}
          selectedPlusCodes={data ? data : list ? list : plusCodeValues}
          selectedDrives={selectedDrives}
          copyOptionType={copyOptionType}
          userInfo={{ userEmail: user?.email, userLevel: user?.groups?.[0] }}
          backButtonHandler={() => {
            setShowSelectCopyDriveModal(true)
            setShowValidationModal(false)

          }}
        />
      }
      {showReviewModal && (
        <ReviewPlusCodesModal
          selectedDrives={selectedDrives}
          modalOpen={showReviewModal}
          onClose={() => {
            setShowReviewModal(false)
            setList([])
            copyModalCancelButtonHandler?.(true)
            if (data && data.options) {
              setShowCopyLoader(false)
            }
          }}
          selectedCopyOptionType={copyOptionType}
          ApplyButtonHanlder={(selectedApplicationDrives: any) => {
            reviewApplyPluscodes(selectedApplicationDrives)
          }}
          modalTitle={"Review"}
          backButtonHandler={() => {
            setShowReviewModal(false)
            setShowValidationModal(true)
            setList([])

          }}
        />
      )}

      {/* delete modals */}

      {
        showSinglePlusCodeDeleteModal &&
        <DeletePlusCodeConfirmationModal
          modalClose={() => setShowSinglePlusCodeDeleteModal(false)}
          modalOpen={showSinglePlusCodeDeleteModal}
          plusCodeOption={singlePlusCode?.plusCode ?? ''}
          deleteButtonHandler={deleteSinglePlusCodefromAllDrives}
          deletButtonLoader={showdeleteButtonLoader}

        />
      }
      {showDeleteModal && (
        <DeletePlusCodesModal
          modalTitle={'Delete pluscodes'}
          pluscodeOptions={plusCodeValues?.options
            ?.filter(
              (plusCodeValue: { checked: boolean }) => plusCodeValue.checked,
            )}
          optionSelected={copyOptionsSelected}
          onClose={() => {
            setShowDeleteModal(false)
          }}
          modalOpen={showDeleteModal}

          nextButtonHandler={() => {

            setShowDeleteModal(false)
            setShowSelectDeleteModal(true)
          }}
        />
      )}

      {showSelectDeleteModal && (
        <SelectDeleteDriveModal
          modalOpen={showSelectDeleteModal}
          onClose={() => {
            setShowSelectDeleteModal(false)
          }}
          selectDriveNextButtonHandler={(drives) => {
            setShowSelectDeleteModal(false)
            setShowDeleteVerificationModal(true)
            setSelectedDrives(drives)
          }}
          modalTitle={"Select drives"}
          applications={applications}
          showModifiedApplication={
            showModifiedApplications
          }
          selectedPlusCodes={plusCodeValues?.options?.filter((option: { checked: any; }) => option.checked)}
          handlingBackOption={() => {
            setShowSelectDeleteModal(false)
          }}
        />
      )}
      {showDeleteVerificationModal &&
        <DeletePlusCodeVerificationModal
          modalOpen={showDeleteVerificationModal}
          onClose={() => setShowDeleteVerificationModal(false)}
          nextButtonhandler={(validateddDrives) => {

            setValidatedDrives(validateddDrives)
            setShowDeleteVerificationModal(false)
            setShowDeleteReviewModal(true)
          }}
          nextbuttondisable={false}
          selectedPlusCodes={plusCodeValues}
          selectedDrives={selectedDrives}
          copyOptionType={copyOptionType}
          userInfo={{ userEmail: user?.email, userLevel: user?.groups?.[0] }}
          handlingBackOption={
            () => {
              if (copyOptionType === 'All drives') {
                setShowDeleteVerificationModal(false)
                setShowSelectDeleteModal(true)
              } else {
                setShowDeleteVerificationModal(false)
              }
            }
          }
        />
      }
      {showDeleteReviewModal &&
        <DeleteReviewPlusCodesModal
          ApplyButtonHanlder={(drives) => {
            deletePlusCodesToSelectedDrives(drives);
          }}
          modalOpen={showDeleteReviewModal}
          onClose={() => {
            setShowDeleteReviewModal(false)
          }}
          selectedPlusCodes={plusCodeValues?.options?.filter((option: { checked: any; }) => option.checked)}
          selectedDrives={validatedDrives}
          modalTitle='Review'
          selectedCopyOptionType={copyOptionType}
          handlingBackOption={() => {
            setShowDeleteReviewModal(false)
            setShowDeleteVerificationModal(true)
          }}
        />
      }
    </>
  );
};

export default DriveResults;




