const handleDriveInputReqeust = () => {
  return (
    <form
      id="myForm"
      action={process.env.REACT_APP_LV_DRIVE_REQUEST_URL}
      method="post"
      style={{ display: 'none' }}
    >
      <textarea
        readOnly
        name="CONFIGURATIONREQUEST"
        id="CONFIGURATIONREQUEST"
      />
      <input type="submit" value="GUI start" />
    </form>
  );
};

export default handleDriveInputReqeust;
