import { CommonuxMenu } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { ChangeEventHandler, PropsWithChildren } from 'react';

interface CommonuxMenuProps extends JSX.CommonuxMenu {
  ref?: any;
  className?: string;
  onChange?: ChangeEventHandler<HTMLCommonuxMenuElement>;
}

export const menuOpenEventhandler = (menuEle: any, isClosed?: boolean) => {
  menuEle?.showMenu({
    triggerElement: null,
    isShow: isClosed ? 'false' : 'true',
  });
};

function MenuCommonux(props: PropsWithChildren<CommonuxMenuProps>) {
  return (
    <CommonuxMenu {...props} ref={props.ref} onChange={props.onChange}>
      {props?.children}
    </CommonuxMenu>
  );
}

export default MenuCommonux;
