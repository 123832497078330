import React from 'react';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { Link } from 'react-router-dom';

const Leagal = () => {
  const { t } = useTranslate();

  return (
    <div id="workspace-container" className="layout-container">
      <div className="vandlpagecontainer info-page-container">
        <Link to="/info/versions">Versions</Link>
        <Link to={'/info/legal'} className="selected">
          Legal
        </Link>
      </div>
      <div className="legal-container info-tabs-container">
        <div
          className="legal-title"
          dangerouslySetInnerHTML={{
            __html: `${t(I18.footer_legal_terms_of_use_title)}`,
          }}
        ></div>
        <div
          className="legal-body"
          dangerouslySetInnerHTML={{
            __html: `${t(I18.footer_legal_terms_of_use_body)}`,
          }}
        ></div>
        <div
          className="legal-body"
          dangerouslySetInnerHTML={{
            __html: `${t(I18.footer_legal_software_licenses)}`,
          }}
        ></div>
        <div
          className="legal-body"
          dangerouslySetInnerHTML={{
            __html: `${t(I18.footer_info_licenses)}`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default Leagal;
