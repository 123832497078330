import Modal from '../../../components/Modal';
import {
  ProjectType,
  ProjectTypeSelectionProps,
} from '../models/homepageModel';
import driveAndMotorIcon from '../../../../src/assets/images/pds.svg';
import plcAndHMIIcon from '../../../assets/images/AC500.svg';
import { useCreateProjectMutation } from '../services/APIMainService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

const ProjectTypeSelectionModal = ({
  modalOpen,
  onClose,
  onCreateProject,
  handleTypeBackClick,
}: ProjectTypeSelectionProps) => {
  const [, projectResult] = useCreateProjectMutation({
    fixedCacheKey: 'shared-createproject-post',
  });

  const newProjectInfo = useSelector(
    (state: RootState) => state.home.newProjectData,
  );
  return (
    <div>
      <Modal
        open={modalOpen}
        onModalClose={onClose}
        modalTitle={'Select an editor'}
        showCloseButton={projectResult.isLoading ? false : true}
        type="discreet"
        showHederSeparator="true"
        backdropClick={false}
        className="project-type-selection-modal"
        data-testid="project-type-selection-modal"
        showBackButton={projectResult.isLoading ? false : true}
        onBack={handleTypeBackClick}
      >
        <div
          slot="content"
          id="project-type-selection-modal-content"
          data-rf="project-type-selection-modal-content"
        >
          <p className="project-type-text">
            Select the tool that you would like to start working with first.
          </p>
          <div
            className="transformer-info-notification"
            hidden={
              !(
                newProjectInfo.destinationCountry === 'US' ||
                newProjectInfo.destinationCountry === 'MX'
              )
            }
          >
            <img className="transformer-info-icon" src="/infoblue.svg" />
            <div>
              Drive and Motor editor will soon be available in your country.
            </div>
          </div>
          <div className="project-type-option-container">
            <div
              role="button"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  onCreateProject?.(ProjectType.DriveMotor);
                }
              }}
              className={`drive-motor-option-container${projectResult.isLoading || newProjectInfo.destinationCountry === 'US' || newProjectInfo.destinationCountry === 'MX' ? ' disabled' : ''}`}
              onClick={() => onCreateProject?.(ProjectType.DriveMotor)}
            >
              <img src={driveAndMotorIcon} alt="drive and motor icon" />
              <div className="option-title">Drive & Motor</div>
            </div>

            <div
              role="button"
              className={`plc-hmi-option-container${projectResult.isLoading ? ' disabled' : ''}`}
              onClick={() => onCreateProject?.(ProjectType.PLCHMI)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  onCreateProject?.(ProjectType.PLCHMI);
                }
              }}
            >
              <img src={plcAndHMIIcon} alt="plchmiicon" />
              <div className="option-title">PLC & HMI</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectTypeSelectionModal;
