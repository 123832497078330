import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import ReactApexCharts from 'react-apexcharts';
import {
  dimensionCurrentGraphOptions,
  dimensionTorqueGraphOptions,
} from '../../../utils/GraphDefaultData';
import ReportEfficiency from './Efficiency';
import { generateKey, replaceYearText } from '../../../utils/GeneralUtils';
import MotorEfficiencyReport from './Efficiency/MotorEfficiencyReport';
import DriveEfficiencyReport from './Efficiency/DriveEfficiencyReport';
import { I18 } from '../../../languages/I18';
import { useTranslate } from '../../../i18translate/Hooks';
import { useEffect, useState } from 'react';
import {
  LoadTypeReportEnum,
  OverloadLoadTypeReportEnum,
} from '../Dimensioning/models/DriveMotorEnums';
import { ApplicationDetails } from '../models/WorkspaceModels';
import CommonLoader from '../../../components/Loader';
import { displayMotorCatalogueTabValues } from '../Dimensioning/models/MotorDefaultValues';
import EfficiencyCorrectionFactorDetails, { EfficiencyCorrectionFactorNotes } from '../Efficiency/Components/EfficiencyCorrectionFactorDetails';

const DimensioningData = () => {
  let i = 0;
  const { t } = useTranslate();
  const applicationDetails = useSelector(
    (state: RootState) => state.workspace.applicationDetails,
  );
  const [isLoading, setIsLoading] = useState(false);

  const convertSVGtoImg = async () => {
    const allSvgs = document.getElementsByName('svg-conatiner');
    const allImgs: any = document.getElementsByClassName(
      'report-image-container',
    );

    let w = 800, // or whatever your svg width is
      h = 600;

    if (allSvgs.length > 0) {
      allSvgs.forEach((svg, idx) => {
        let canvas = document.createElement('canvas');
        if (svg.firstElementChild?.firstChild && canvas) {
          let img = new Image();
          const serializer = new XMLSerializer();
          const svgStr = serializer.serializeToString(
            svg.firstElementChild.firstChild,
          );

          img.src = 'data:image/svg+xml;base64,' + window.btoa(svgStr);

          img.onload = function () {
            canvas.width = w;
            canvas.height = h;

            canvas.getContext('2d')?.drawImage(img, 0, 0, w, h);
            allImgs[idx].src = canvas.toDataURL();
            svg.remove();
          };
          canvas.remove();
          img.remove();
        }
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      convertSVGtoImg();
    }, 2000);
  }, []);

  return (
    <div className="segment-container">
      <div className="segment-header">3. Project dimensioning data</div>
      {applicationDetails.map((application: ApplicationDetails, id) => {
        if (
          application.dimensionDetails.productTypeCode?.driveTypeCode ||
          application.dimensionDetails.productTypeCode?.motorTypeDesignation
        ) {
          i++;
          const torqueCurve =
            application.dimensionDetails?.selectMotorResult?.dimension
              ?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
              .miscellaneousUnit?.torqueGraph;

          const currentCurves =
            application.dimensionDetails?.selectDriveResult?.dimension
              ?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
              .miscellaneousUnit?.currentGraph;

          let j = 1;

          return (
            <div
              key={`${application.id}`}
              id={`${id === 0 ? 'pdf-section' : 'pdf-break-before'}`}
            >
              <div className="segment-subheader">
                3.{i} {application.name}
              </div>
              <div id="pdf-section">
                <div
                  id={`${application.id}-load `}
                  className="segment-contentheader"
                >
                  3.{i}.{j} Load profile data
                </div>
                {/* load profile data */}
                <div className="load-profile-container">
                  <div className="load-profile-segment segment-type-container">
                    <div>
                      <div className="segment-type-sub-container">
                        <div className="segment-type-header">Load type</div>
                        <div className="segment-type-content">
                          {
                            LoadTypeReportEnum[
                            application.dimensionDetails.dimensionInputData
                              .selectionInputs?.[0]
                              .loadType as keyof typeof LoadTypeReportEnum
                            ]
                          }
                        </div>
                      </div>

                      <div className="segment-type-sub-container">
                        <div className="segment-type-header">Overload type</div>
                        <div className="segment-type-content">
                          {
                            OverloadLoadTypeReportEnum[
                            application.dimensionDetails.dimensionInputData
                              .selectionInputs?.[0]
                              .overLoadType as keyof typeof OverloadLoadTypeReportEnum
                            ]
                          }
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="segment-type-sub-container">
                        <div className="segment-type-header">Overload time</div>
                        <div className="segment-type-content">
                          {application?.dimensionDetails?.hasMultipleOverload ||
                            application?.dimensionDetails?.motorLoadData
                              ?.overLoadType === '1'
                            ? 'custom'
                            : application.dimensionDetails.dimensionInputData
                              .selectionInputs?.[0].maxLoadTime + 's'}
                        </div>
                      </div>
                      <div className="segment-type-sub-container">
                        <div className="segment-type-header">Every</div>
                        <div className="segment-type-content">
                          {application?.dimensionDetails?.hasMultipleOverload ||
                            application?.dimensionDetails?.motorLoadData
                              ?.overLoadType === '1'
                            ? 'custom'
                            : application.dimensionDetails.dimensionInputData
                              .selectionInputs?.[0].dutyCycleTime + 's'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dimension-contents-container load-profile-table">
                    <div className="segment-title">
                      <div> </div>
                      <div>Min</div>
                      <div>Base</div>
                      <div className="no-padding-left">Max</div>
                    </div>

                    <div className="segment-content">
                      <div>Speed (rpm)</div>
                      <div>
                        {
                          application.dimensionDetails.dimensionInputData
                            .selectionInputs?.[0].nMin
                        }
                      </div>
                      <div>
                        {
                          application.dimensionDetails.dimensionInputData
                            .selectionInputs?.[0].nBase
                        }
                      </div>
                      <div>
                        {
                          application.dimensionDetails.dimensionInputData
                            .selectionInputs?.[0].nMax
                        }
                      </div>
                    </div>
                    <div className="segment-content">
                      <div>Power (kW) </div>
                      <div>
                        {application.dimensionDetails.motorLoadData.powerMin ??
                          '-'}
                      </div>
                      <div>
                        {application.dimensionDetails.dimensionInputData
                          .selectionInputs?.[0].power ?? '-'}
                      </div>
                      <div>
                        {application.dimensionDetails.motorLoadData.powerMax ??
                          '-'}
                      </div>
                    </div>
                    <div className="segment-content">
                      <div>Overload (%)</div>
                      <div>-</div>
                      <div>
                        {application?.dimensionDetails?.hasMultipleOverload ||
                          application?.dimensionDetails?.motorLoadData
                            ?.overLoadType === '1'
                          ? 'custom'
                          : (application.dimensionDetails.motorLoadData
                            .olBaseInput ?? '-')}
                      </div>
                      <div>
                        {application?.dimensionDetails?.hasMultipleOverload ||
                          application?.dimensionDetails?.motorLoadData
                            ?.overLoadType === '1'
                          ? 'custom'
                          : (application.dimensionDetails.motorLoadData
                            .olMaxInput ?? '-')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* motor data */}
              {application?.dimensionDetails?.productTypeCode
                ?.motorTypeDesignation && (
                  <>
                    <div id="pdf-section">
                      <div
                        id={`${application.id}-motor`}
                        className="segment-contentheader"
                      >
                        3.{i}.{++j} Motor data
                      </div>
                      <div className="img-and-desc-container">
                        {application.dimensionDetails?.selectMotorResult.dimension
                          ?.catalogueDataMotor?.productImages &&
                          application.dimensionDetails?.selectMotorResult
                            ?.dimension?.catalogueDataMotor?.productImages
                            ?.length > 0 && (
                            <div className="img-container">
                              <img
                                src={
                                  application.dimensionDetails?.selectMotorResult
                                    ?.dimension?.catalogueDataMotor
                                    ?.productImages &&
                                    application.dimensionDetails?.selectMotorResult
                                      ?.dimension?.catalogueDataMotor?.productImages
                                      ?.length > 0
                                    ? application.dimensionDetails
                                      ?.selectMotorResult?.dimension
                                      ?.catalogueDataMotor?.productImages?.[0]
                                      ?.thumbnailUrl
                                    : ''
                                }
                                alt={
                                  application.dimensionDetails?.selectMotorResult
                                    ?.dimension?.catalogueDataMotor
                                    ?.productImages !== null
                                    ? application.dimensionDetails
                                      ?.selectMotorResult?.dimension
                                      ?.catalogueDataMotor?.productImages?.[0]
                                      ?.thumbnailUrl
                                    : ''
                                }
                              />
                            </div>
                          )}
                        <div className={'desc-container'}>
                          <div className="segment-type-container">
                            <div className="segment-type-sub-container">
                              <div className="segment-type-header font-text-transform-style">
                                Type code
                              </div>
                              <div className="segment-type-content">
                                <div className="product-label-title">
                                  {application.dimensionDetails.productTypeCode
                                    ?.motorTypeDesignation ?? '-'}
                                </div>
                              </div>
                            </div>

                            <div className="segment-type-sub-container">
                              <div className="segment-type-header font-text-transform-style">
                                Motor quantity
                              </div>
                              <div className="segment-type-content">
                                <div className="product-label-title">
                                  {application.dimensionDetails.productTypeCode
                                    ?.motorTypeDesignation
                                    ? application?.dimensionDetails
                                      ?.dimensionInputData?.selectionInputs?.[0]
                                      .motorsCount
                                    : '1'}
                                </div>
                              </div>
                            </div>
                          </div>
                          {application.dimensionDetails?.selectMotorResult
                            ?.dimension?.catalogueDataMotor?.productAttributes
                            ?.ProductName?.values?.[0]?.text && (
                              <div className="label-content">
                                <div className="label-title">
                                  {'Product Name: '}
                                </div>
                                <div>
                                  {application.dimensionDetails?.selectMotorResult
                                    ?.dimension?.catalogueDataMotor
                                    ?.productAttributes?.ProductName?.values?.[0]
                                    ?.text ?? '-'}
                                </div>
                              </div>
                            )}
                          <div id="desc-container">
                            {
                              application.dimensionDetails?.selectMotorResult
                                ?.dimension?.catalogueDataMotor?.productAttributes
                                ?.CatalogDescription?.values?.[0]?.text
                            }

                            {application.dimensionDetails?.selectMotorResult
                              ?.dimension?.catalogueDataMotor?.productAttributes
                              ?.ProductType?.values?.[0]?.text && (
                                <div className="label-content">
                                  <div className="label-title">
                                    {'Product Type: '}
                                  </div>
                                  <div>
                                    {application.dimensionDetails?.selectMotorResult
                                      ?.dimension?.catalogueDataMotor
                                      ?.productAttributes?.ProductType?.values?.[0]
                                      ?.text ?? '-'}
                                  </div>
                                </div>
                              )}

                            {application.dimensionDetails?.selectMotorResult
                              ?.dimension?.catalogueDataMotor?.productAttributes
                              ?.FrameSize?.values?.[0]?.text && (
                                <div className="label-content">
                                  <div className="label-title">
                                    {'Frame Size: '}
                                  </div>
                                  <div>
                                    {application.dimensionDetails?.selectMotorResult
                                      ?.dimension?.catalogueDataMotor
                                      ?.productAttributes?.FrameSize?.values?.[0]
                                      ?.text ?? '-'}
                                  </div>
                                </div>
                              )}

                            {application.dimensionDetails?.selectMotorResult
                              ?.dimension?.catalogueDataMotor?.productAttributes
                              ?.FrameMaterial?.values?.[0]?.text && (
                                <div className="label-content">
                                  <div className="label-title">
                                    {'Frame Material: '}
                                  </div>
                                  <div>
                                    {application.dimensionDetails?.selectMotorResult
                                      ?.dimension?.catalogueDataMotor
                                      ?.productAttributes?.FrameMaterial
                                      ?.values?.[0]?.text ?? '-'}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* motor catalouge data */}
                    {/* need to segrigate into flex */}
                    <div id="pdf-table" className="dimension-contents-container ">
                      <div className="motor--catalouge-table-flex">
                        <div className="electrical-table-data">
                          <div className="segment-title ">
                            <div className="motot-data-catalouge">
                              Motor catalog data
                            </div>
                          </div>
                          <div className="segment-content-header segment-content electrical-8">
                            <div>Electrical</div>
                          </div>
                          <div className="segment-catalog-content segment-content">
                            <div>Product code</div>
                            <div>
                              {
                                application?.dimensionDetails?.selectMotorResult
                                  ?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0]?.motorUnit.results?.[1]
                                  ?.value
                              }
                            </div>
                          </div>
                          {application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results
                            ?.slice(0, 16)
                            .map((result, idx) => {
                              let name = result.name.trim();
                              if (
                                displayMotorCatalogueTabValues(
                                  name,
                                  application?.dimensionDetails?.selectMotorResult
                                    ?.dimension?.dimensionInput
                                    ?.selectionInputs?.[0]?.motorType ?? '',
                                )
                              ) {
                                if (name === 'ID CODE') {
                                  name = 'Id code';
                                }
                                return (
                                  <div
                                    key={generateKey(idx)}
                                    className="segment-catalog-content segment-content"
                                  >
                                    <div>
                                      {name}{' '}
                                      {result.unit !== '' && `[${result.unit}]`}
                                    </div>
                                    <div>
                                      {result.value !== '' ? result.value : '-'}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                        <div className="mechincal-table-data">
                          <div className="segment-title ">
                            <div className="mechincal-data-catalouge">Motor</div>
                          </div>
                          {application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.results
                            ?.slice(16)
                            .map((result, idx) => {
                              let name = result.name.trim();
                              if (
                                displayMotorCatalogueTabValues(
                                  name,
                                  application?.dimensionDetails?.selectMotorResult
                                    ?.dimension?.dimensionInput
                                    ?.selectionInputs?.[0]?.motorType ?? '',
                                )
                              ) {
                                if (name === 'ID CODE') {
                                  name = 'Id code';
                                }
                                return (
                                  <div
                                    key={generateKey(idx)}
                                    className="segment-catalog-content segment-content"
                                  >
                                    <div>
                                      {name}{' '}
                                      {result.unit !== '' && `[${result.unit}]`}
                                    </div>
                                    <div>
                                      {result.value !== '' ? result.value : '-'}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                    {/* end of motor catlouge data */}

                    {/* wrapper of section data and vsd data */}

                    <div className="selection-vsd-container" id="pdf-section">
                      {/* vsd rating data */}
                      <div className="segment-container vsd-container">
                        <div className="segment-contentheader">VSD rating</div>
                        <div className="project-data-container">
                          <div className="project-data-content-container">
                            <div className="segment-title">
                              <div></div>
                              <div>Min speed</div>
                              <div>Base speed</div>
                            </div>
                            {application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.miscellaneousUnit?.vSDRatings?.vSDData?.map(
                              (vsd, idx) => {
                                if (idx > 0)
                                  return (
                                    <div
                                      className="segment-content"
                                      key={generateKey(idx)}
                                    >
                                      <div>{vsd.row?.[0]}</div>
                                      <div>
                                        {vsd.row?.[2] ?? '-'} {vsd.row?.[1]}
                                      </div>
                                      <div>
                                        {vsd.row?.[3] ?? '-'}{' '}
                                        {vsd.row?.[3] ? vsd.row?.[1] : null}
                                      </div>
                                    </div>
                                  );
                                else return null;
                              },
                            )}
                          </div>
                          {/* <div className="project-data-content-container">
                          <div className="segment-title">
                            <div>Base speed</div>
                          </div>
                          {application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit?.miscellaneousUnit?.vSDRatings?.vSDData?.map(
                            (vsd, idx) => {
                              if (idx > 0)
                                return (
                                  <div
                                    className="segment-content"
                                    key={generateKey(idx)}
                                  >
                                    <div>{vsd.row?.[0]}</div>
                                    <div>
                                      {vsd.row?.[3] ?? '-'}{' '}
                                      {vsd.row?.[3] ? vsd.row?.[1] : null}
                                    </div>
                                  </div>
                                );
                              else return null;
                            }
                          )}
                        </div> */}
                        </div>
                      </div>
                      {/* end of vsd rating data */}

                      {/* kind of selection data */}
                      <div
                        id="pdf-table"
                        className="dimension-contents-container segment-container selection-container selection-table-container"
                      >
                        <div className="segment-contentheader">
                          Selection data
                        </div>
                        <div className="segment-title">
                          <div></div>
                          <div>Required</div>
                          <div>Result</div>
                          <div className="no-padding-left">Margin</div>
                        </div>
                        {application?.dimensionDetails?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0].motorUnit?.miscellaneousUnit?.selectionData?.map(
                          (selection, idx) => {
                            if (selection && selection.row.length > 0) {
                              return (
                                <div
                                  key={generateKey(idx)}
                                  className={`${selection.row?.[1] === '' &&
                                    'segment-content-header'
                                    } segment-content`}
                                >
                                  <div>{selection.row?.[0]}</div>
                                  <div>{selection.row?.[1]}</div>
                                  <div>{selection.row?.[2]}</div>
                                  <div>{selection.row?.[3]}</div>
                                </div>
                              );
                            } else return null;
                          },
                        )}
                      </div>
                      {/* end of selection data container */}
                    </div>

                    {/* end of section and vsd data */}

                    {/* motor load torque graph */}
                    <div id="pdf-section">
                      <div className="segment-contentheader">
                        Graph: Load torques
                      </div>
                      {isLoading && (
                        <CommonLoader
                          size="large"
                          indicatorType={'circular'}
                          progressType={'indeterminate'}
                          progressValue={50}
                          type={'primary-black'}
                        />
                      )}
                      <div
                        id={`svg-load-container-${id}`}
                        className="report-chart-container"
                      >
                        <ReactApexCharts
                          name="svg-conatiner"
                          options={Object.assign(
                            {},
                            dimensionTorqueGraphOptions,
                            {
                              xaxis: {
                                min: 0,
                                max: parseFloat(
                                  torqueCurve?.speedContLoad?.[
                                    torqueCurve?.speedContLoad?.length - 1
                                  ]?.[0].toString() ?? '0',
                                ),
                                tickAmount: 3,
                                title: {
                                  text: 'Speed [rpm]',
                                  style: {
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    fontFamily: ' ABBVoice, sans-serif',
                                  },
                                },
                              },
                              chart: {
                                toolbar: {
                                  show: false,
                                },
                              },
                              legend: {
                                labels: {
                                  style: {
                                    fontFamily: 'ABBVoice, sans-serif',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            },
                          )}
                          series={[
                            {
                              name: 'cont load',
                              data: torqueCurve?.speedContLoad ?? [],
                            },
                            {
                              name: 'cont loadability',
                              data: torqueCurve?.speedContLoadability ?? [],
                            },
                            {
                              name: 'max. load',
                              data: torqueCurve?.speedMaxLoad ?? [],
                            },
                            {
                              name: 'max. loadability',
                              data: torqueCurve?.speedMaxLoadability ?? [],
                            },
                          ]}
                          type={'line'}
                          height={
                            dimensionTorqueGraphOptions?.chart?.height ?? 100
                          }
                        />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          id={`img-load-container-${id}`}
                          className="report-image-container"
                          alt=""
                        />
                      </div>
                    </div>
                    {/* end of motor load torque graph container */}

                    {/* End of motor vsd ratings */}
                  </>
                )}

              {/* end of motor data section */}
              {/* drive data */}
              {application?.dimensionDetails?.productTypeCode
                ?.driveTypeCode && (
                  <>
                    {/* Drive Data */}
                    <div id="pdf-section">
                      <div
                        id={`${application.id}-drive`}
                        className="segment-contentheader"
                      >
                        3.{i}.{++j} Drive data
                      </div>
                      <div className="img-and-desc-container">
                        {application.dimensionDetails?.selectDriveResult.dimension
                          ?.catalogueDataDrive?.productImages &&
                          application.dimensionDetails?.selectDriveResult
                            .dimension?.catalogueDataDrive?.productImages
                            ?.length > 0 && (
                            <div className="img-container">
                              <img
                                src={
                                  application.dimensionDetails?.selectDriveResult
                                    .dimension?.catalogueDataDrive
                                    ?.productImages &&
                                    application.dimensionDetails?.selectDriveResult
                                      .dimension?.catalogueDataDrive?.productImages
                                      ?.length > 0
                                    ? application.dimensionDetails
                                      ?.selectDriveResult.dimension
                                      ?.catalogueDataDrive?.productImages?.[0]
                                      ?.thumbnailUrl
                                    : ''
                                }
                                alt={
                                  application.dimensionDetails?.selectDriveResult
                                    .dimension?.catalogueDataDrive
                                    ?.productImages !== null
                                    ? application.dimensionDetails
                                      ?.selectDriveResult.dimension
                                      ?.catalogueDataDrive?.productImages?.[0]
                                      ?.thumbnailUrl
                                    : ''
                                }
                              />
                            </div>
                          )}
                        <div className={'desc-container'}>
                          <div className="segment-type-container">
                            <div className="segment-type-sub-container">
                              <div className="segment-type-header">Type code</div>
                              <div className="segment-type-content">
                                <div className="product-label-title">
                                  {application?.dimensionDetails?.productTypeCode
                                    ?.driveTypeCode ?? '-'}
                                </div>
                              </div>
                            </div>

                            <div className="segment-type-sub-container">
                              <div className="segment-type-header">
                                Inverter quantity
                              </div>
                              <div className="segment-type-content">
                                <div className="product-label-title">
                                  {application.dimensionDetails.driveLoadData
                                    .drivesCount ?? '1'}
                                </div>
                              </div>
                            </div>
                          </div>
                          {application.dimensionDetails?.selectDriveResult
                            .dimension?.catalogueDataDrive?.productAttributes
                            ?.ProductName?.values?.[0]?.text && (
                              <div className="label-content">
                                <div className="label-title">
                                  {'Product Name: '}
                                </div>
                                <div>
                                  {application.dimensionDetails?.selectDriveResult
                                    .dimension?.catalogueDataDrive
                                    ?.productAttributes?.ProductName?.values?.[0]
                                    ?.text ?? '-'}
                                </div>
                              </div>
                            )}
                          <div id="desc-container">
                            {
                              application.dimensionDetails?.selectDriveResult
                                .dimension?.catalogueDataDrive?.productAttributes
                                ?.CatalogDescription?.values?.[0]?.text
                            }

                            {application.dimensionDetails?.selectDriveResult
                              .dimension?.catalogueDataDrive?.productAttributes
                              ?.ProductType?.values?.[0]?.text && (
                                <div className="label-content">
                                  <div className="label-title">
                                    {'Product Type: '}
                                  </div>
                                  <div>
                                    {application.dimensionDetails?.selectDriveResult
                                      .dimension?.catalogueDataDrive
                                      ?.productAttributes?.ProductType?.values?.[0]
                                      ?.text ?? '-'}
                                  </div>
                                </div>
                              )}

                            {application.dimensionDetails?.selectDriveResult
                              .dimension?.catalogueDataDrive?.productAttributes
                              ?.FrameSize?.values?.[0]?.text && (
                                <div className="label-content">
                                  <div className="label-title">
                                    {'Frame Size: '}
                                  </div>
                                  <div>
                                    {application.dimensionDetails?.selectDriveResult
                                      .dimension?.catalogueDataDrive
                                      ?.productAttributes?.FrameSize?.values?.[0]
                                      ?.text ?? '-'}
                                  </div>
                                </div>
                              )}

                            {application.dimensionDetails?.selectDriveResult
                              .dimension?.catalogueDataDrive?.productAttributes
                              ?.FrameMaterial?.values?.[0]?.text && (
                                <div className="label-content">
                                  <div className="label-title">
                                    {'Frame Material: '}
                                  </div>
                                  <div>
                                    {application.dimensionDetails?.selectDriveResult
                                      .dimension?.catalogueDataDrive
                                      ?.productAttributes?.FrameMaterial
                                      ?.values?.[0]?.text ?? '-'}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of drive image and descriptioj data */}
                    {/* Drive catalogue data */}
                    <div
                      id="pdf-section"
                      className="dimension-contents-container"
                    >
                      <div className="motor--catalouge-table-flex drive-catalouge--container">
                        <div className="electrical-table-data ">
                          <div className="segment-title">
                            <div>Drive catalog data</div>
                          </div>
                          <div className="segment-content-header segment-content electrical-8">
                            <div>Technical data</div>
                          </div>
                          <div className="segment-catalog-content segment-content">
                            <div>
                              {
                                application.dimensionDetails?.selectDriveResult
                                  ?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0]?.inverterUnit
                                  .results?.[2].name
                              }
                            </div>
                            <div>
                              {
                                application?.dimensionDetails?.selectDriveResult
                                  ?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0]?.inverterUnit
                                  .results?.[2].value
                              }
                            </div>
                          </div>
                          {application?.dimensionDetails?.selectDriveResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0].inverterUnit?.results?.map(
                            (result, idx) => {
                              if (idx > 3 && idx < 10) {
                                return (
                                  <div
                                    key={generateKey(idx)}
                                    className="segment-catalog-content segment-content"
                                  >
                                    <div>
                                      {result.name}{' '}
                                      {result.unit !== '' && `[${result.unit}]`}
                                    </div>
                                    <div>{result.value ?? '-'}</div>
                                  </div>
                                );
                              } else return null;
                            },
                          )}
                          <div className="segment-catalog-content segment-content">
                            <div>
                              {
                                application.dimensionDetails?.selectDriveResult
                                  ?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0].inverterUnit
                                  ?.results?.[11]?.name
                              }
                            </div>
                            <div>
                              {application.dimensionDetails?.selectDriveResult
                                ?.dimension?.dimensionResult
                                ?.supplyDriveLoads?.[0].inverterUnit.results?.[11]
                                ?.value ?? '-'}
                            </div>
                          </div>
                          <div className="segment-catalog-content segment-content">
                            <div>
                              {
                                application.dimensionDetails?.selectDriveResult
                                  ?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0].inverterUnit.results?.[
                                  application.dimensionDetails?.selectDriveResult
                                    ?.dimension?.dimensionResult
                                    ?.supplyDriveLoads?.[0].inverterUnit.results
                                    .length - 1
                                ].name
                              }
                            </div>
                            <div>
                              {application.dimensionDetails?.selectDriveResult
                                ?.dimension?.dimensionResult
                                ?.supplyDriveLoads?.[0].inverterUnit.results?.[
                                application.dimensionDetails?.selectDriveResult
                                  ?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0].inverterUnit.results
                                  .length - 1
                              ].value ?? '-'}
                            </div>
                          </div>
                        </div>
                        <div className="mechincal-table-data"></div>
                      </div>
                    </div>
                    {/* End of Drive catalogue data */}
                    {/* Current graph */}
                    <div id="pdf-section">
                      <div className="segment-contentheader">Graph: Currents</div>

                      <div
                        id={`svg-current-container-${id}`}
                        className="report-chart-container"
                      >
                        <ReactApexCharts
                          name="svg-conatiner"
                          options={Object.assign(
                            {},
                            dimensionCurrentGraphOptions,
                            {
                              xaxis: {
                                min: 0,
                                max: parseFloat(
                                  currentCurves?.speedContLoad?.[
                                    currentCurves?.speedContLoad.length - 2
                                  ]?.[0].toString() ?? '0',
                                ),
                                tickAmount: 3,
                                title: {
                                  text: 'Speed [rpm]',
                                  style: {
                                    fontSize: '14px',
                                    fontWeight: 400,
                                  },
                                },
                              },
                              chart: {
                                toolbar: {
                                  show: false,
                                },
                              },
                            },
                          )}
                          series={[
                            {
                              name: 'cont. load',
                              data: currentCurves?.speedContLoad.slice(0, -1),
                              color: '#000000',
                            },
                            {
                              name: 'cont. loadability',
                              data: currentCurves?.speedContLoadability.slice(
                                0,
                                -1,
                              ),
                              color: '#32cd32',
                            },
                            {
                              name: 'max. load',
                              data: currentCurves?.speedMaxLoad.slice(0, -1),
                              color: '#0000ff',
                            },
                            {
                              name: 'max. loadability',
                              data: currentCurves?.speedMaxLoadability.slice(
                                0,
                                -1,
                              ),
                              color: '#de4040',
                            },
                          ]}
                          type={'line'}
                          height={
                            dimensionCurrentGraphOptions?.chart?.height ?? 100
                          }
                        />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          id={`img-current-container-${id}`}
                          className="report-image-container"
                          alt=""
                        />
                      </div>
                    </div>
                    {/* End of Current graph */}
                    <div
                      className="inverter--load--calc--data--container"
                      id="pdf-section"
                    >
                      {/* Drive calculations */}{' '}
                      <div className="calc-data--container">
                        <div className="segment-contentheader">Calculations</div>
                        <div className="dimension-contents-container">
                          <div className="segment-title">
                            <div></div>
                            <div>Required</div>
                            <div>Result</div>
                            <div className="no-padding-left">Margin</div>
                          </div>
                          {application?.dimensionDetails?.selectDriveResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit?.miscellaneousUnit?.selectionData?.map(
                            (selection, idx) => {
                              if (
                                selection &&
                                selection.row.length > 0 &&
                                idx !== 0
                              ) {
                                return (
                                  <div
                                    key={generateKey(idx)}
                                    className={`${(selection.row?.[0] === 'Integrator' ||
                                      selection.row?.[0] === 'Temperature') &&
                                      'segment-content-header'
                                      } segment-content`}
                                  >
                                    <div>{selection.row?.[0]}</div>
                                    <div>{selection.row?.[1]}</div>
                                    <div>{selection.row?.[2]}</div>
                                    <div>{selection.row?.[3]}</div>
                                  </div>
                                );
                              } else return null;
                            },
                          )}
                          <div className="segment-type-description">
                            Selection method:{' '}
                            {
                              application.dimensionDetails?.selectDriveResult
                                ?.dimension?.dimensionResult
                                ?.supplyDriveLoads?.[0]?.inverterUnit.results?.[3]
                                .value
                            }
                          </div>
                        </div>
                      </div>
                      {/* End of Drive calculations */}
                      {/* Inverter load */}
                      <div className="drives-container inverter-load-table">
                        <div className="segment-contentheader">Inverter load</div>
                        <div className="segment-content drive-container-header">
                          <div className="drive-container-ttile">Load type</div>
                          <div className="drive-container-ttile">
                            {
                              LoadTypeReportEnum[
                              application.dimensionDetails.dimensionInputData
                                .selectionInputs?.[0]
                                .loadType as keyof typeof LoadTypeReportEnum
                              ]
                            }
                          </div>
                        </div>
                        <div className="segment-catalog-content segment-content drive-container-header">
                          <div>Overload type</div>
                          <div>
                            {
                              OverloadLoadTypeReportEnum[
                              application.dimensionDetails.dimensionInputData
                                .selectionInputs?.[0]
                                .overLoadType as keyof typeof OverloadLoadTypeReportEnum
                              ]
                            }
                          </div>
                        </div>
                        <div className="segment-catalog-content segment-content drive-container-header">
                          <div>Icont [A]</div>
                          <div>
                            <div>
                              {
                                application.dimensionDetails?.selectDriveResult
                                  ?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0]?.inverterUnit
                                  .miscellaneousUnit.selectionData?.[1].row?.[1]
                              }
                            </div>
                            <div className="border-left">
                              {application?.dimensionDetails
                                ?.hasMultipleOverload ||
                                application?.dimensionDetails?.driveLoadData
                                  ?.overLoadType === '1'
                                ? 'custom'
                                : application.dimensionDetails.dimensionInputData
                                  .selectionInputs?.[0].dutyCycleTime + 's'}
                            </div>
                          </div>
                        </div>
                        <div className="segment-catalog-content segment-content drive-container-header">
                          <div>Imax [A]</div>
                          <div>
                            <div>
                              {
                                application.dimensionDetails?.selectDriveResult
                                  ?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0]?.inverterUnit
                                  .miscellaneousUnit.selectionData?.[2].row?.[1]
                              }
                            </div>
                            <div className="border-left">
                              {application?.dimensionDetails
                                ?.hasMultipleOverload ||
                                application?.dimensionDetails?.driveLoadData
                                  ?.overLoadType === '1'
                                ? 'custom'
                                : application.dimensionDetails.dimensionInputData
                                  .selectionInputs?.[0].maxLoadTime + 's'}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End of Inverter load */}
                    </div>
                  </>
                )}

              {/* end of drive data */}

              {/* efficiency component  */}
              {/* condition to show the efficiency
                losses component only when either of drive or motor are sized
              */}

              {application?.pdsEfficiencyDetails &&
                Object.keys(application.pdsEfficiencyDetails).length > 0 && (
                  <>
                    <ReportEfficiency
                      application={application}
                      index={i}
                      segmentIndex={++j}
                    />
                  </>
                )}
              {application?.motorEfficiencyDetails &&
                Object.keys(application?.motorEfficiencyDetails).length > 0 && (
                  <div className="report-efficiency-container" id="pdf-section">
                    <div
                      className="efficiency-report-header segment-contentheader"
                      id={`${application.id}-efficiency`}
                    >
                      3.{i}.{++j} Efficiency Report
                    </div>
                    <MotorEfficiencyReport
                      dimensionData={application?.dimensionDetails}
                      motorEfficiencyData={application?.motorEfficiencyDetails}
                      id={application?.id}
                    />
                    <div className="loss-determination-section">
                      <div className="report-page-loss-subheader">
                        <strong>
                          {t(
                            I18.efficiency_losses_motor_page_loss_determination_heading,
                          )}
                        </strong>
                      </div>

                      <div>
                        <div className="report-page-loss-content">
                          {t(
                            I18.efficiency_losses_motor_page_loss_determination,
                          )}

                          {t(
                            I18.efficiency_losses_motor_page_loss_determination_sub,
                          )}
                        </div>
                      </div>

                      <div className="report-page-loss-subheader">
                        <strong>
                          {t(
                            I18.efficiency_losses_motor_page_disclaimer_heading,
                          )}
                        </strong>
                      </div>

                      <div>
                        <div className="report-page-loss-content">
                          {t(I18.efficiency_losses_page_disclaimer)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {application?.driveEfficiencyDetails &&
                Object.keys(application?.driveEfficiencyDetails) && (
                  <div className="report-efficiency-container" id="pdf-section">
                    <div
                      className="efficiency-report-header segment-contentheader"
                      id={`${application.id}-efficiency`}
                    >
                      3.{i}.{++j} Efficiency Report
                    </div>
                    <DriveEfficiencyReport
                      efficiencyData={application?.driveEfficiencyDetails}
                      dimensionData={application?.dimensionDetails}
                      id={application?.id}
                    />

                    <div className="report-page-loss-subheader">
                      <strong>
                        {t(I18.efficiency_cdm_loss_determination_header)}
                      </strong>
                    </div>
                    <div className="report-page-loss-content">
                      <p>
                        {replaceYearText(application?.driveEfficiencyDetails?.driveTechnicalData?.rowIds, t('efficiency_losses_drive_page_cdm_loss_determination_with_constant'))}
                      </p>
                      <p>
                        {t(I18.efficiency_losses_drive_page_cdm_loss_heading)}
                      </p>
                      <ol>
                        <li>
                          {t(I18.efficiency_losses_drive_page_cdm_loss_1)}
                          <br />
                          {t(I18.efficiency_losses_drive_page_cdm_loss_1_a)}
                          <sub>
                            {t(
                              I18.efficiency_losses_drive_page_cdm_loss_1_a_sub,
                            )}
                          </sub>{' '}
                          ={' '}
                          <strong>
                            {application?.driveEfficiencyDetails
                              ?.driveTechnicalData?.inputFrequency
                              ? application?.driveEfficiencyDetails
                                ?.driveTechnicalData?.inputFrequency
                              : '-'}
                          </strong>
                          <br />
                          {t(I18.efficiency_losses_drive_page_cdm_loss_1_b)}
                          <sub>
                            {t(
                              I18.efficiency_losses_drive_page_cdm_loss_1_b_sub,
                            )}
                          </sub>
                          =
                          <strong>
                            {application?.driveEfficiencyDetails
                              ?.driveTechnicalData?.inputVoltage
                              ? application?.driveEfficiencyDetails
                                ?.driveTechnicalData?.inputVoltage
                              : '-'}
                          </strong>
                          <br />
                          {t(I18.efficiency_losses_drive_page_cdm_loss_1_c)}
                          <sub>
                            {' '}
                            {t(
                              I18.efficiency_losses_drive_page_cdm_loss_1_c_sub,
                            )}
                          </sub>{' '}
                          ={' '}
                          <strong>
                            {' '}
                            {t(
                              I18.efficiency_losses_drive_page_cdm_loss_1_c_strong,
                            )}
                          </strong>
                          <br />
                          {t(I18.efficiency_losses_drive_page_cdm_loss_1_d)}
                          <sub>
                            {t(
                              I18.efficiency_losses_drive_page_cdm_loss_1_d_sub,
                            )}
                          </sub>{' '}
                          ={' '}
                          <strong>
                            {' '}
                            {application?.driveEfficiencyDetails
                              ?.driveTechnicalData?.outputFrequency
                              ? application?.driveEfficiencyDetails
                                ?.driveTechnicalData?.outputFrequency
                              : '-'}
                          </strong>
                          <br />
                          {t(I18.efficiency_losses_drive_page_cdm_loss_1_e)}
                          <sub>
                            {t(
                              I18.efficiency_losses_drive_page_cdm_loss_1_e_sub,
                            )}
                          </sub>{' '}
                          ={' '}
                          <strong>
                            {application?.driveEfficiencyDetails
                              ?.driveTechnicalData?.outputVoltage
                              ? application?.driveEfficiencyDetails
                                ?.driveTechnicalData?.outputVoltage
                              : '-'}
                          </strong>
                          <br />
                          {t(I18.efficiency_losses_drive_page_cdm_loss_1_f)}
                          <strong>
                            {application?.driveEfficiencyDetails
                              ?.driveTechnicalData?.dcLinkVoltage
                              ? application?.driveEfficiencyDetails
                                ?.driveTechnicalData?.dcLinkVoltage +
                              ' ' +
                              application?.driveEfficiencyDetails
                                ?.driveTechnicalData?.dcLinkVoltageUnit
                              : '-'}
                          </strong>
                          <br />
                          {t(
                            I18.efficiency_losses_drive_page_cdm_loss_1_g,
                          )}{' '}
                          <strong>
                            {application?.driveEfficiencyDetails
                              ?.driveTechnicalData?.switchingFrequencySupplySide
                              ? application?.driveEfficiencyDetails
                                ?.driveTechnicalData
                                ?.switchingFrequencySupplySide +
                              ' ' +
                              application?.driveEfficiencyDetails
                                ?.driveTechnicalData
                                ?.switchingFrequencySupplySideUnit
                              : '-'}
                          </strong>
                          <br />
                          {t(I18.efficiency_losses_drive_page_cdm_loss_1_h)}
                          <strong>
                            {application?.driveEfficiencyDetails
                              ?.driveTechnicalData
                              ?.switchingFrequencyInverterSide
                              ? application?.driveEfficiencyDetails
                                ?.driveTechnicalData
                                ?.switchingFrequencyInverterSide +
                              ' ' +
                              application?.driveEfficiencyDetails
                                ?.driveTechnicalData
                                ?.switchingFrequencyInverterSideUnit
                              : '-'}
                          </strong>
                          <br />
                        </li>
                        <li>
                          {t(I18.efficiency_losses_drive_page_cdm_loss_2)}
                          <sub>n</sub>{' '}
                          {t(I18.efficiency_losses_drive_page_cdm_loss_2_sub)}
                          <sub>N</sub> * U​<sub>1,out</sub>
                          <br />
                        </li>
                        <li>
                          {' '}
                          {t(I18.efficiency_losses_drive_page_cdm_loss_3)}{' '}
                          <br />
                        </li>
                        <li>
                          {t(I18.efficiency_losses_drive_page_cdm_loss_4)}
                          <br />
                        </li>
                        <li>
                          {t(I18.efficiency_losses_drive_page_cdm_loss_5)}
                          <br />
                        </li>
                        <li>
                          {t(I18.efficiency_losses_drive_page_cdm_loss_6)}
                          <br />
                        </li>
                        <li>
                          {t(I18.efficiency_losses_drive_page_cdm_loss_7)}
                          <br />
                        </li>
                        <li>
                          {t(I18.efficiency_losses_drive_page_cdm_loss_8)}P
                          <sub>output, CDM</sub> / ( P <sub>​output, CDM</sub> +
                          P <sub>​losses, CDM</sub>). P <sub>​output, CDM</sub>{' '}
                          is
                          {' ' +
                            t(
                              I18.efficiency_losses_drive_page_cdm_loss_8_output,
                            )}{' '}
                          <sub>​losses, CDM</sub>{' '}
                          {t(
                            I18.efficiency_losses_drive_page_cdm_loss_8_output_end,
                          )}
                          <br />
                        </li>
                        {application?.driveEfficiencyDetails?.driveTechnicalData?.rowIds && <li>
                          <EfficiencyCorrectionFactorDetails rowIds={application?.driveEfficiencyDetails?.driveTechnicalData?.rowIds} />
                        </li>}

                      </ol>
                      <p>
                        {t(
                          I18.efficiency_losses_drive_page_cdm_loss_conclusion,
                        )}
                      </p>
                      <EfficiencyCorrectionFactorNotes rowIds={application?.driveEfficiencyDetails?.driveTechnicalData?.rowIds} />
                    </div>
                    <div className="report-page-loss-subheader">
                      <strong>
                        {t(I18.efficiency_losses_motor_page_disclaimer_heading)}
                      </strong>
                    </div>

                    <div>
                      <div className="report-page-loss-content">
                        {t(I18.efficiency_losses_page_disclaimer)}
                      </div>
                    </div>
                  </div>
                )}
              {/* end of efficiency component */}
            </div>
          );
        } else return null;
      })}
    </div>
  );
};

export default DimensioningData;
