import { Dispatch, SetStateAction } from 'react';

export interface HomepageModal {
  id?: string;
}

export interface CatalogReportInput {
  catalogProductDatas: CatalogProductData[];
}

export interface CatalogProductData {
  appId: string;
  langCode: string;
  countryCode: string;
  productIds: {
    motorProductId: string;
    driveProductId: string;
  };
}

export interface NewProjectInfo {
  projectName: string;
  countryName: string;
  destinationCountry: string;
  standard: string;
  unitSystem: string;
  description: string;
  projectType: string;
}

export interface GeneralProjectDetails {
  projectId: string;
  countryName?: string;
  projectType: string;
  destinationCountry?: string;
  projectName: string;
  standard?: string;
  unitSystem?: string;
  createdDate?: string;
  modifiedDate?: string;
  ambientAltitude?: string;
  ambientTempMotor?: string;
  ambientTempInverter?: string;
  description?: string;
}

export interface CurrentProjectDetails {
  id: string;
  name: string;
  createdDate: string;
  modifiedDate: string;
  projectType: number;
}

export interface Project extends CurrentProjectDetails {
  userId: string;
}

export interface ProjectData {
  projects: Project[];
}

/**
 * State types
 */

export interface ProjectsState {
  projectData: GeneralProjectDetails;
  newProjectData: NewProjectInfo;
  projectTypeSelected: string;
  deleteModalOpen: boolean;
  deleteProjectLoading: boolean;
  deleteProjectId: string;
}

export interface ProjectActionTypes {
  type: string;
  payload: any;
}

export interface Post {
  id: number;
  name: string;
}

export interface CountryDetails {
  code: string;
  name: string;
  defaultVoltage: string;
  meps: string;
}

export interface UserDetails {
  id: number;
  name: string;
  email: string;
  gender: string;
  status: string;
}

export interface ArticleImage {
  contextType: string;
  fileName: string;
  id: string;
  url: string;
}

export interface ArticleDetails {
  description: string;
  id: string;
  imageMedia: ArticleImage;
  link: string;
  title: string;
  entryTitle: string;
  type: string;
  updateDate: string;
}

export interface ProjectDetails {
  label: string;
  value: number;
  imgSrc: string;
  content: string;
}

export interface ProductImageDetails {
  url: string;
  thumbnailUrl: string;
}

export interface ProductAttributesInfo {
  attributeCode: string;
  attributeName: string;
  values: [{ text: string }];
}

export interface ProductAttributes {
  productId: ProductAttributesInfo;
  ABBType: ProductAttributesInfo;
  CatalogDescription: ProductAttributesInfo;
  CountryOfOrigin: ProductAttributesInfo;
  FrameSize?: ProductAttributesInfo;
  ProductName?: ProductAttributesInfo;
  FrameMaterial?: ProductAttributesInfo;
  MountingType?: ProductAttributesInfo;
  ModuleFunction?: ProductAttributesInfo;
  ProductType?: ProductAttributesInfo;
}

export interface CatalogueDetails {
  productAttributes: ProductAttributes;
  productImages: ProductImageDetails[];
  catalogPDFURL: string;
}

export interface CatalogReportOutput {
  appId: string;
  catalogueDTO: CatalogueDetails[];
  countryCode: string;
  langCode: string;
}

export interface PISInputData {
  productCode?: string;
  typeDesignation?: string;
  langCode: string;
  countryCode?: string | null;
}

export interface ProjectTypeSelectionProps {
  onCreateProject?: (projectType: ProjectType) => void;
  modalOpen?: boolean;
  onClose?: () => void;
  handleTypeBackClick: () => void;
}

export enum ProjectType {
  DriveMotor = 1,
  PLCHMI = 2,
}
