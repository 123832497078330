import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import {
  AddFeedbackApi,
  CatalogReportApi,
  DrivetypeInputDataApi,
  PISDataApi,
  articlesApi,
  countryApi,
  localeApi,
} from '../pages/Home/services/APICommonService';
import { projectApi } from '../pages/Home/services/APIMainService';
import { dimensioningApi } from '../pages/Workspace/Dimensioning/services/APIDimensioningService';
import { selectionApi } from '../pages/Workspace/services/APISelectionService';
import { efficiencyApi } from '../pages/Workspace/Efficiency/services/APIEfficiencyService';
import {
  drivepluscodeApi,
  driveplsuCodeValidationAPI,
} from '../pages/Workspace/services/DrivePluscodeService';

export let store: any;

export const setupStore = (preloadedState?: any) => {
  store = configureStore({
    reducer: rootReducer,
    preloadedState: preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({}).concat(
        localeApi.middleware,
        countryApi.middleware,
        articlesApi.middleware,
        projectApi.middleware,
        dimensioningApi.middleware,
        PISDataApi.middleware,
        CatalogReportApi.middleware,
        selectionApi.middleware,
        efficiencyApi.middleware,
        DrivetypeInputDataApi.middleware,
        AddFeedbackApi.middleware,
        drivepluscodeApi.middleware,
        driveplsuCodeValidationAPI.middleware,
      ),
  });

  return store;
};

export type AppStore = ReturnType<typeof setupStore>;
