import { useSelector } from 'react-redux';
import CommonLoader from '../../../../components/Loader';
import { displayMotorCatalogueTabValues } from '../models/MotorDefaultValues';
import { MotorCatalogueDataProps } from '../models/MotorSelectionModels';
import { RootState } from '../../../../store/rootReducer';

const MotorCatalogueData = ({
  catalogueTableData,
  isLoading,
}: MotorCatalogueDataProps) => {
  const allData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectMotorResult,
  );
  return (
    <div className="catalogue-table-container">
      <table className="catalogue-table">
        <tbody>
          {catalogueTableData?.map((row, idx) => {
            let name = row.name.trim();
            let value = row?.value.trim();
            if (
              displayMotorCatalogueTabValues(
                name,
                allData?.dimension?.dimensionInput?.selectionInputs?.[0]
                  ?.motorType ?? '',
              )
            ) {
              if (name === 'ID CODE') {
                name = 'Id code';
              }
              if (name.toLowerCase() === 'frequency' && value.length > 5) {
                value = value.slice(0, 5);
              }
              return (
                <tr className="catalogue-row" key={`${(row?.name, idx)}`}>
                  <td className="catalogue-col">{name}</td>
                  <td className="catalogue-value-col">
                    {isLoading ? (
                      <div className="catalogue-commonLoader">
                        <CommonLoader
                          size="xsmall"
                          indicatorType={'circular'}
                          progressType={'indeterminate'}
                          progressValue={60}
                          type={'primary-black'}
                        />
                      </div>
                    ) : value !== '' ? (
                      value + ' ' + row?.unit
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MotorCatalogueData;
