import React, { useState, useEffect } from 'react'
import Modal from '../../../../components/Modal'
import { DataGrid, DataGridRow, DataGridHeader, DataGridCell } from '../../../../components/DataGrid'
import Button from '../../../../components/Form/Button'
import { useTranslate } from '../../../../i18translate/Hooks'
import CommonLoader from '../../../../components/Loader'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/rootReducer'
import { generatePayload, simulateApiCall } from '../../../../utils/GeneralUtils'
import Icon from '../../../../components/Icon'
import Tooltip from '../../../../components/Tooltip'

interface IVerifyingPlusCodeModal {
    selectedPlusCodes: any,
    onClose: () => void,
    modalOpen: boolean,
    nextButtonhandler: (drives: any) => void,
    nextbuttondisable: boolean,
    selectedDrives: any,
    copyOptionType: string,
    userInfo: { userEmail: any, userLevel: string }
    backButtonHandler: () => void;
}

export const removeDuplicatesFromTypeCodecombinedTypeCode = (combinedTypeCode: string) => {
    const removeDuplicatesFromCombiendTypeCode = combinedTypeCode.split('+').filter((item, index, self) => self.indexOf(item) === index).join('+')
    return removeDuplicatesFromCombiendTypeCode ?? ''
}

const VerifyingPlusCodeModal = ({ copyOptionType,
    backButtonHandler, userInfo, selectedDrives, modalOpen, nextButtonhandler, nextbuttondisable, onClose, selectedPlusCodes
}: IVerifyingPlusCodeModal) => {
    const { t } = useTranslate();
    const applications = useSelector((state: RootState) => state.workspace.applicationDetails)
    const [selectDrive, setSelectDrive] = useState<{ driveTypeCode: string; checked: boolean; driveName: string | undefined; }[]>([])
    const [disableApplyButton, setDisableApplyButton] = useState(true)
    useEffect(() => {
        let modifiedDrives;
        if (selectedDrives.length > 0) {
            modifiedDrives = selectedDrives?.map((drive: any) => ({
                ...drive,
                isLoading: true
            }))
        }
        setSelectDrive(modifiedDrives || [])
        validateDrives(modifiedDrives)
    }, [selectedDrives])



    const validateDrives = async (drives: any) => {
        const updatedDrives = drives?.map((drive: any) => ({ ...drive })); // Shallow copy to avoid direct mutation
        // Create a shallow copy

        for (let i = 0; i < updatedDrives?.length; i++) {
            const list = updatedDrives[i];
            const drivetypecode = removeDuplicatesFromTypeCodecombinedTypeCode(list.driveTypeCode + '+' + combinedTypeCodeFromPlusCodes())
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const requestbody = {
                payload:JSON.stringify(generatePayload(drivetypecode, { userEmail: userInfo.userEmail, userLevel: userInfo.userLevel })),
              isPROD:  process.env.REACT_APP_IS_PROD??false
            }
            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(requestbody),
                redirect: "follow" as RequestRedirect
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_NODE_SERVER_ROOT}/validationRequest`, requestOptions);
                const data = await response.json();

                // Update the state with the result for this specific drive
                setSelectDrive(prevDrives => {
                    const newDrives = prevDrives.map((prevDrive, index) => {
                        if (index === i) {
                            return {
                                ...prevDrive,
                                isLoading: false,
                                isValid: data.valid,
                                error: data.error
                            };
                        }
                        return prevDrive;
                    });
                    return newDrives;
                });

            } catch (error) {
                setSelectDrive(prevDrives => {
                    const newDrives = prevDrives.map((prevDrive, index) => {
                        if (index === i) {
                            return {
                                ...prevDrive,
                                isLoading: false,
                                isValid: false, // Or handle the error as needed
                            };
                        }
                        return prevDrive;
                    });
                    return newDrives;
                });
            }
            i === updatedDrives?.length - 1 ? setDisableApplyButton(false) : setDisableApplyButton(true)
        }
    };

    //if copy type options is All drives then we need to verify all the drive from the project
    const combinedTypeCodeFromPlusCodes = () => {
        let combinedPlusCodes = [];

        if (selectedPlusCodes?.options?.[0]?.hasOwnProperty('checked')) {
            combinedPlusCodes = selectedPlusCodes?.options?.filter((item: { checked: any }) => item.checked).map((option: { plusCode: any }) => option.plusCode)
        } else {
            combinedPlusCodes = selectedPlusCodes?.options?.map((option: { plusCode: any }) => option.plusCode)

        }
        let plusCodesString = "";

        for (let i = 0; i < combinedPlusCodes?.length; i++) {
            plusCodesString += combinedPlusCodes[i];
            if (i < combinedPlusCodes.length - 1) {
                plusCodesString += "+";
            }
        }
        return plusCodesString
    }
    useEffect(() => {
        if (copyOptionType === 'All drives') {

            const plusCodesString = combinedTypeCodeFromPlusCodes()
            const applicationsList = applications.filter(app => app?.dimensionDetails?.productTypeCode?.driveTypeCode)
            const modifiedApplicationDriveList: { driveTypeCode: string; checked: boolean; driveName: string | undefined; isLoading: boolean; }[] = applicationsList.map(application => ({
                driveTypeCode: application?.dimensionDetails?.productTypeCode?.driveTypeCode,
                checked: false,
                driveName: application?.name,
                isLoading: true,
                id: application?.id

            }));
            setSelectDrive(modifiedApplicationDriveList);
            validateDrives(modifiedApplicationDriveList)
        }
    }, [copyOptionType])


    return (
        <Modal
            onModalClose={() => onClose?.()}
            open={modalOpen}
            backdropClick={false}
            modalTitle={'Verifying plus codes'}
            showCloseButton={true}
            type="discreet"
            showHederSeparator="true"
            className="copy-and-delete-plus-codes-modal"
            showBackButton={true}
        >
            <div className="select-drive-modal-content" slot="content">


                <DataGrid
                    divider="row"
                    size="medium"
                    type="primary-black"
                    scrollable={false}
                >
                    <DataGridRow is-header>

                        <DataGridHeader colResizable={false} colWidth={172}>
                            <div>APPLICATION NAME</div>

                        </DataGridHeader>
                        <DataGridHeader
                            label="DRIVE TYPE"
                            colResizable={false}
                            colWidth={"100%"}
                        />

                    </DataGridRow>
                    <div className="grid-body">
                        {selectDrive.map((drive: any) => (
                            <DataGridRow
                                className="data-grid"
                                key={drive.driveTypeCode}
                            >
                                <DataGridCell showTooltip={false}>
                                    <div>{drive.driveName}</div>
                                </DataGridCell>
                                <DataGridCell showTooltip={false}>
                                    <div className='review-drive-type-status'>
                                        <div className='review-drive-type-code'>
                                            {drive.driveTypeCode}
                                        </div>
                                        <div className='review-drivetype-icons'>
                                            {drive.isLoading ? <CommonLoader
                                                size="xsmall"
                                                indicatorType={'circular'}
                                                progressType={'indeterminate'}
                                                progressValue={50}
                                                type={'primary-black'}
                                            /> : drive.isValid ? <Icon name="check-mark-circle-1" size='small' theme='dark' className='is-success' /> :

                                                <div>
                                                    <Tooltip
                                                        alignment="center"
                                                        arrowPosition="center"
                                                        onTooltipHover={function noRefCheck() { }}
                                                        placement="left"
                                                        size="small"
                                                        triggerEvent="hover"
                                                    >
                                                        <Icon slot="tooltip-parent" className='not-is-success' name="information-circle-1" size="small" />
                                                        <div slot="content-section">
                                                            {drive?.error}


                                                        </div>
                                                    </Tooltip>

                                                </div>

                                            }

                                        </div>

                                    </div>
                                </DataGridCell>
                            </DataGridRow>
                        ))}
                    </div>
                </DataGrid>
            </div>
            <div slot="footer" className="copy-delete-plus-code-footer">
                <Button type="discreet-black" size="small" onClick={() => onClose?.()}>
                    {t('cancel_button')}
                </Button>

                <Button type="primary-black" size="small" onClick={() => nextButtonhandler(selectDrive)}
                    disabled={disableApplyButton}>
                    Next
                </Button>

            </div>
        </Modal>
    )
}

export default VerifyingPlusCodeModal